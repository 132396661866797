import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import { Input } from "../UI/Input/InputEdit";

const FormContatti = () => {
  const [rubrica, setRubrica] = useState([]);

  const [dipendenti, setDipendenti] = useState({
    filiale: "",
    id_dip: "",
    qrcode: "",
    nome: "",
    mansione: "",
    telefono: "",
    email: "",
  });

  useEffect(() => {
    const rubRef = collection(database, "filiali");
    const q = query(rubRef, orderBy("id_filiale", "asc"));
    onSnapshot(q, (snapshot) => {
      const sedi = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRubrica(sedi);
    });
  }, [dipendenti.filiale]);

  const handleDipendenti = (e) => {
    setDipendenti({ ...dipendenti, [e.target.name]: e.target.value });
  };
  const addToDb = (e) => {
    e.preventDefault();
    if (
      !dipendenti ||
      !dipendenti.filiale ||
      !dipendenti.id_dip ||
      !dipendenti.nome ||
      !dipendenti.mansione ||
      !dipendenti.email ||
      !dipendenti.telefono
    ) {
      toast.error("Compila tutti i campi !!!");
      return;
    }
    setDipendenti({
      filiale: "",
      id_dip: "",
      qrcode: "",
      nome: "",
      mansione: "",
      telefono: "",
      email: "",
    });

    const filialeRef = collection(database, `${dipendenti.filiale}`);

    addDoc(filialeRef, {
      id_dip: Number(dipendenti.id_dip),
      qrcode: dipendenti.qrcode,
      nome: dipendenti.nome,
      mansione: dipendenti.mansione,
      telefono: dipendenti.telefono,
      email: dipendenti.email,
    })
      .then(() => toast.success("Dipendente Aggiunto"))
      .catch((err) => toast.error("Qualcosa è andato storto", err));
  };

  return (
    <div className="w-full h-full bg-zinc-100 text-zinc-800 flex flex-col py-16 pt-5 rounded-b-xl">
      <form className="w-full flex flex-col items-center mt-1">
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-2/3 flex flex-col space-y-2">
            <div className="w-full ">
              <p className="text-left text-2xl uppercase text-bold mb-5 ">
                Aggiungi Dipendente
              </p>
            </div>
            <div className="w-full pt-1">
              <select
                required
                className="select py-1 px-3 border dark:bg-white border-assoCol focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                name="filiale"
                onChange={handleDipendenti}
              >
                <option>Seleziona</option>
                {rubrica.map((list, idx) => (
                  <option
                    key={idx}
                    value={list.filiale}
                    className="bg-assoBg selection:bg-assoCol"
                  >
                    {list.filiale}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full flex items-center space-x-2">
              <div className="w-2/12">
                <Input
                  type="text"
                  name="id_dip"
                  value={dipendenti.id_dip}
                  handler={handleDipendenti}
                  title="ID *"
                />
              </div>

              <Input
                type="text"
                name="nome"
                value={dipendenti.nome}
                handler={handleDipendenti}
                title="Nome Completo *"
                sty="md:w-full "
              />
            </div>
            <Input
              type="text"
              name="mansione"
              value={dipendenti.mansione}
              handler={handleDipendenti}
              title="Mansione *"
              sty="md:w-full "
            />
            <Input
              type="text"
              name="qrcode"
              value={dipendenti.qrcode}
              handler={handleDipendenti}
              title="Link QRCode *"
              sty="md:w-full "
            />
            <div className="w-full flex items-center space-x-2">
              <Input
                type="text"
                name="telefono"
                value={dipendenti.telefono}
                handler={handleDipendenti}
                title="Telefono *"
                sty="md:w-full "
              />
              <Input
                type="text"
                name="email"
                value={dipendenti.email}
                handler={handleDipendenti}
                title="Email *"
                sty="md:w-full "
              />
            </div>
            <div className="w-full flex ">
              {!dipendenti ||
              !dipendenti.filiale ||
              !dipendenti.id_dip ||
              !dipendenti.nome ||
              !dipendenti.mansione ||
              !dipendenti.email ||
              !dipendenti.telefono ? (
                <button className="w-full btn glass bg-assoCol hover:bg-assoCol dark:text-white font-bold">
                  compila tutti i campi
                </button>
              ) : (
                <button
                  onClick={addToDb}
                  className="w-full btn glass bg-assoCol hover:bg-assoCol dark:text-white font-bold"
                >
                  salva
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormContatti;
