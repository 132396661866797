import React from "react";
import { FaRegHandshake } from "react-icons/fa";
import { IoIosCloudDone } from "react-icons/io";
import { GiTrophyCup } from "react-icons/gi";
import { BsNewspaper } from "react-icons/bs";

function BodySkills() {
  return (
    <div className="w-full flex-col text-zinc-800 z-0 flex flex-nowrap lg:flex-row">
      <div className="border space-y-4 p-12 lg:w-1/4  flex-col flex items-center content-center bg-zinc-200">
        <div>
          <FaRegHandshake className="text-assoCol h-24 w-full p-2 border-assoCol border-4 rounded-full" />
        </div>
        <p className="font-bold text-2xl">100% affidabilità</p>
        <p className="text-center px-4">
          Personale esperto capace di garantire la perfetta riuscita di
          qualsiasi tipo di installazione
        </p>
      </div>
      <div className="border lg:w-1/4  space-y-4 p-12  flex-col flex items-center content-center bg-zinc-200">
        <div>
          {" "}
          <IoIosCloudDone className=" text-assoCol h-24 w-full p-2 border-assoCol border-4 rounded-full" />
        </div>
        <p className="font-bold text-2xl">100% Su Misura</p>
        <p className="text-center px-4">
          Disponiamo di una vasta gamma prodotti che ci consente sempre la
          soluzione ideale per le vostre esigenze
        </p>
      </div>{" "}
      <div className="border lg:w-1/4 space-y-4 p-12  flex-col flex items-center content-center bg-zinc-200">
        <div>
          {" "}
          <GiTrophyCup className=" text-assoCol h-24 w-full p-2 border-assoCol border-4 rounded-full" />
        </div>
        <p className="font-bold text-2xl">100% Professionalità</p>
        <p className="text-center px-4">
          Un Team di professionisti che sarà al tuo fianco per aiutarti a
          soddisfare la clientela, anche la più esigente.
        </p>
      </div>{" "}
      <div className="border lg:w-1/4  space-y-4 p-12  flex-col flex items-center content-center bg-zinc-200">
        <div>
          {" "}
          <BsNewspaper className="border-4 w-full text-assoCol h-24 p-2 border-assoCol  rounded-full" />
        </div>
        <p className="font-bold text-2xl">100% Garanzia</p>
        <p className="text-center px-4">
          Garantiamo supporto in tutte le fasi del tuo lavoro, dalla
          progettazione fino all'installazione.
        </p>
      </div>
    </div>
  );
}

export default BodySkills;
