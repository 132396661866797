export const menulist = [
    {
        page: "Azienda",
        link: "/azienda",
    },
    {
        page: "Soluzioni & Partner",
        link: "/soluzioni",
        list: [
            {
                title: "Videosorveglianza",
                link: "/soluzioni/videosorveglianza",
            },
            {
                title: "Antintrusione / Antifurto",
                link: "/soluzioni/antintrusione",
            },
            {
                title: "Antincendio / Rilevazione GAS / Spegnimento",
                link: "/soluzioni/antincendio",
            },
            {
                title: "Diffusione Sonora / Sistemi EVAC",
                link: "/soluzioni/diffusione",
            },
            { title: "Controllo Accessi", link: "/soluzioni/accessi" },
            {
                title: "Telefonia / Videocitofonia",
                link: "/soluzioni/telefonia",
            },
            { title: "Networking", link: "/soluzioni/networking" },
            { title: "Automazione Cancelli", link: "/soluzioni/automazione" },
            {
                title: "Accumulatori / Gruppi di Continuità",
                link: "/soluzioni/accumulatore",
            },
            { title: "Cavi", link: "/soluzioni/cavi" },
        ],
    },
    {
        page: "Novità",
        link: "/news",
    },
    {
        page: "Formazione & Eventi",
        link: "/eventi",
    },

    {
        page: "Contatti",
        link: "/contatti",
    },

    {
        page: "Download",
        link: "/download",
    },
    {
        page: "Teleassistenza",
        link: "/teleassistenzaAsso",
    },
    {
        page: "Preventivo",
        link: "/preventivo",
    },
    {
        page: "Admin",
        visibleBy: ["Admin", "Dipendente 2", "Dipendente 1", "Dipendente 3"],
        link: "gestione_sito",
        list: [
            { title: "Gestione User", link: "/admin/gestione_account" },
            { title: "Gestione Contenuti News", link: "/admin/gestione_news" },
            { title: "Gestione Eventi", link: "/admin/gestione_eventi" },
            { title: "Gestione Sito", link: "/admin/gestione_sito" },
            { title: "Gestione Brand", link: "/admin/gestione-brand" },
            {
                title: "Gestione Filiale & Dipendenti",
                link: "/admin/gestione_filiali",
            },
        ],
    },
];

export const adminList = {
    page: "Admin",
    visibleBy: ["Admin", "Dipendente 2", "Dipendente 1", "Dipendente 3"],

    list: [
        { title: "Gestione User", link: "/admin/gestione_account" },
        { title: "Gestione Contenuti News", link: "/admin/gestione_news" },
        { title: "Gestione Eventi", link: "/admin/gestione_eventi" },
        { title: "Gestione Sito", link: "/admin/gestione_sito" },
        { title: "Gestione Brand", link: "/admin/gestione-brand" },
        {
            title: "Gestione Filiale & Dipendenti",
            link: "/admin/gestione_filiali",
        },
    ],
};
