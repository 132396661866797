import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { database } from "../../Utils/firebase_config";
import FormImg from "./FormIMG";

const FormIMGfiliale = () => {
    const [rubrica, setRubrica] = useState([]);
    const [filiale, setFiliale] = useState({});
    const handleFiliale = (e) => {
        setFiliale({ [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const rubRef = collection(database, "filiali");
        const q = query(rubRef, orderBy("id_filiale", "asc"));
        onSnapshot(q, (snapshot) => {
            const sedi = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setRubrica(sedi);
        });
    }, []);

    return (
        <div className="w-full flex flex-col justify-start items-center pt-10">
            <div className="w-2/3 flex flex-col ">
                <div className="w-full flex flex-col ">
                    <p className="text-left text-2xl uppercase text-bold mb-5 ">
                        Aggiungi Immagini filiali
                    </p>
                    <div className="w-full flex flex-col lg:flex-row">
                        <div className="w-full ">
                            <select
                                required
                                className="dark:bg-white w-full select border border-assoCol focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block"
                                name="filiale"
                                onChange={handleFiliale}
                            >
                                <option>Scegli filiale</option>
                                {rubrica.map((list, idx) => (
                                    <option
                                        key={idx}
                                        value={list.filiale}
                                        className="bg-assoBg selection:bg-assoCol"
                                    >
                                        {list.filiale}
                                    </option>
                                ))}
                            </select>
                            <FormImg rubrica={rubrica} filiale={filiale} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FormIMGfiliale;
