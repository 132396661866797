import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Antintrusione from "./antintrusione/Antintrusione";
import DiffusioneSonora from "./DiffusioneSonora";
import Domotica from "./Domotica.jsx";
import Automazione from "./Automazione";
import ControlloAccessi from "./ControlloAccessi";
import Videocitofonia from "./Videocitofonia";
import Telefonia from "./Telefonia";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import FormData from "./FormData";
import { storage } from "../firebase_config";
import ButtonPDF from "../preventivoPdf/ButtonPDF";
//import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Tvcc from "./Tvcc";

function ComponiKit({ user }) {
  const [formData, setFormData] = useState({
    azienda: "",
    nome: "",
    tel: "",
    email: "",
    filiale: "",
    agente: "",
    upload: "",
  });
  useEffect(() => {
    if (
      user === null ||
      user === undefined ||
      user === "" ||
      user === "ospite"
    ) {
      setFormData({
        azienda: "",
        nome: "",
        tel: "",
        email: "",
        filiale: "",
        agente: "",
        upload: "",
      });
    } else {
      setFormData({
        azienda: user[0].ragione || "",
        nome: user[0].nome + " " + user[0].cognome || "",
        tel: user[0].cell || "",
        email: user[0].email || "",
        filiale: "",
        agente: "",
        upload: "",
      });
    }
  }, [user]);

  const handleFiliale = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [settore, setSettore] = useState({ Settore: [] });

  //handler form iniziale
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    const name = e.target.name;
    const value = e.target.value;
    const id = e.target.id;

    if (isChecked) {
      setSettore({
        Settore: [...settore.Settore, name],
      });
      setOpen({ ...open, [id]: true });
    } else {
      const index = settore.Settore.indexOf(value);
      settore.Settore.splice(index, 1);
      setSettore({
        Settore: settore.Settore,
      });
      setOpen({ ...open, [id]: false });
      if (name === "TVCC") {
        setTvcc({
          marchio: "",
          impianto: "",
          risoluzione_impianto: "",
          videoregistratore: "",
          ingressi_video: "",
          ingressi_altro: "",
          hdd: "",
          bullet_ottica_fissa: "",
          bullet_varifocal: "",
          dome_ottica_fissa: "",
          dome_varifocal: "",
          alimentatore: 0,
          balun: 0,
          junction_box: 0,
          cavo: "",
          num_cavi: "",
          attacco_palo: 0,
          continuità: "",
          connettori_coax: "",
          connettori_maschio: "",
          armadio_rack: "",
          mensole: 0,
          presiera: 0,
          guidacavi: 0,
          ventole: "",
          pannello_patch: "",
          kit: "",
          switch: "",
          switch_text: "",
        });
      }
      if (name === "Antintrusione") {
        setAntintrusione({
          tastiere: [],
          cavi: [],
          struttura: "",
          centrale: "",
          marchio: "",
          zone: "",
          dispositivi: [],
          contatti: [],
          perimetrale: [],
          scomparsa: "",
          vibrazione: "",
          tapparelle: "",
          magnetici: "",
          rivelatori_interni: [],
          rivelatori_esterni: [],
          comment: "",
          file: "",
          organi: [],
          disp_sonori: [],
          moduli: [],
          batterie: [],
          telecomando: 0,
        });
      }
      if (name === "Automazione") {
        setAutomazione({
          marchio: "",
          impianto: "",
          apertura: "",
          installazione: "",
          accessori: [],
        });
      }
      if (name === "Videocitofonia") {
        setVideoCit({
          marchio: "",
          num_post_est: "",
          bussate_diff: "",
          tipo_inst: "",
          comment: "",
          num_post_int: "",
        });
      }
      if (name === "Antincendio") {
        setAntintrusione({ contactMe: false });
      }
      if (name === "Telefonia") {
        setTelefonia({
          impianto: "",
          num_linee: "",
          num_interni: "",
          richiesta: "",
        });
      }
      if (name === "Diffusione Sonora") {
        setDiffusioneSonora({ comment: "" });
      }
      if (name === "Controllo Accessi") {
        setAccessi({ comment: "" });
      }
      if (name === "Domotica") {
        setDomotica({ contactMe: false });
      }
    }
  };

  const [tvcc, setTvcc] = useState({
    marchio: "",
    impianto: "",
    risoluzione_impianto: "",
    videoregistratore: "",
    ingressi_video: "",
    ingressi_altro: "",
    hdd: "",
    bullet_ottica_fissa: "",
    bullet_varifocal: "",
    dome_ottica_fissa: "",
    dome_varifocal: "",
    alimentatore: 0,
    balun: 0,
    junction_box: 0,
    cavo: "",
    num_cavi: "",
    attacco_palo: 0,
    continuità: "",
    connettori: "",
    connettori_maschio: "",
    armadio_rack: "",
    mensole: 0,
    presiera: 0,
    guidacavi: 0,
    ventole: "",
    pannello_patch: "",
    kit: "",
    switch: "",
    switch_text: "",
  });

  const [open, setOpen] = useState({
    TVCC: false,
    Anti: false,
    Antintrusione: false,
    Automazione: false,
    Videocitofonia: false,
    Telefonia: false,
    Diffusione: false,
    Accessi: false,
    Domotica: false,
    contatti: false,
    scomparsa: false,
    a_vista: false,
    vibrazione: false,
    tapparelle: false,
    alta_protezione: false,
    ad_asta: false,
    interni: false,
    esterni: false,
    perimetrale: false,
    Tastiere: false,
    Telecomando: false,
  });
  //handler TVCC
  const handleTVCC = (e) => {
    setTvcc({ ...tvcc, [e.target.name]: e.target.value });
  };

  //ANTINTRUSIONE
  const [antintrusione, setAntintrusione] = useState({
    tastiere: [],
    cavi: [],
    struttura: "",
    centrale: "",
    marchio: "",
    zone: "",
    dispositivi: [],
    contatti: [],
    perimetrale: [],
    scomparsa: "",
    vibrazione: "",
    tapparelle: "",
    magnetici: "",
    rivelatori_interni: [],
    rivelatori_esterni: [],
    comment: "",
    file: "",
    organi: [],
    disp_sonori: [],
    moduli: [],
    batterie: [],
    telecomando: 0,
  });

  //AUTOMAZIONE
  const [automazione, setAutomazione] = useState({
    marchio: "",
    impianto: "",
    apertura: "",
    installazione: "",
    accessori: [],
    lunghezza_cancello: "",
    lunghezza_varco: "",
    num_ante: "",
    peso_ante: "",
    dim_anta: "",
    num_anta_scor: "",
    peso_porte: "",
    tipo_porte: "",
    acc_porte_veloci: [],
  });

  const handleAutomazione = (e) => {
    const type = e.target.type;
    const name = e.target.name;
    const isChecked = e.target.checked;
    if (type === "checkbox") {
      if (isChecked) {
        setAutomazione({
          ...automazione,
          accessori: [...automazione.accessori, name],
        });
      } else {
        const index = automazione.accessori.indexOf(name);
        automazione.accessori.splice(index, 1);
        setAutomazione({
          ...automazione,
          accessori: automazione.accessori,
        });
      }
    } else {
      setAutomazione({ ...automazione, [name]: e.target.value });
    }
  };

  const [videoCit, setVideoCit] = useState({
    marchio: "",
    num_post_est: "",
    bussate_diff: "",
    tipo_inst: "",
    comment: "",
    num_post_int: "",
  });
  const handleVideo = (e) => {
    setVideoCit({ ...videoCit, [e.target.name]: e.target.value });
  };
  const [antincendio, setAntincendio] = useState({
    contactMe: false,
  });

  //antincendio handler
  const handleAntincendio = (e) => {
    setAntincendio({
      ...antincendio,
      [e.target.name]: e.target.value,
    });
  };

  //TELEFONIA
  const [telefonia, setTelefonia] = useState({
    impianto: "",
    num_linee: "",
    num_interni: "",
    richiesta: "",
  });
  const handleTel = (e) => {
    setTelefonia({
      ...telefonia,
      [e.target.name]: e.target.value,
    });
  };
  const [diffusioneSonora, setDiffusioneSonora] = useState({
    comment: "",
  });

  const handleDiffusione = (e) => {
    setDiffusioneSonora({
      ...diffusioneSonora,
      [e.target.name]: e.target.value,
    });
  };
  const [accessi, setAccessi] = useState({
    comment: "",
  });
  const handleAccessi = (e) => {
    setAccessi({
      ...accessi,
      [e.target.name]: e.target.value,
    });
  };

  const [domotica, setDomotica] = useState({
    contactMe: false,
  });

  const handleDomotica = (e) => {
    setDomotica({
      ...domotica,
      [e.target.name]: e.target.value,
    });
  };

  //handle storageBucket

  const [upload, setUpload] = useState(null);
  const [progress, setProgress] = useState(0);
  const handleUp = (e) => {
    setUpload(e.target.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (!upload) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(
      storage,
      `/preventivo/${formData.azienda}/${upload.name}`
    );

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, upload);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setProgress(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({ ...formData, upload: url });
        });
      }
    );
  };

  const formData2 = {
    infos: { ...formData },
    Settore: settore.Settore.map((list) => list),
    tvcc: { ...tvcc },
    automazione: { ...automazione },
    antintrusione: { ...antintrusione },
    videocitofonia: { ...videoCit },
    antincendio: { ...antincendio },
    telefonia: { ...telefonia },
    diffusione_sonora: { ...diffusioneSonora },
    accessi: { ...accessi },
    domotica: { ...domotica },
  };
  const form = useRef();

  const submitPreventivo = (e) => {
    e.preventDefault();
    setFormData({
      azienda: "",
      nome: "",
      tel: "",
      email: "",
      filiale: "",
      agente: "",
      upload: "",
    });
    setSettore({
      Settore: [],
    });
    setOpen({
      TVCC: false,
      Anti: false,
      Antintrusione: false,
      Automazione: false,
      Videocitofonia: false,
      Telefonia: false,
      Diffusione: false,
      Accessi: false,
      Domotica: false,
      contatti: false,
      scomparsa: false,
      a_vista: false,
      vibrazione: false,
      tapparelle: false,
      alta_protezione: false,
      ad_asta: false,
      interni: false,
      esterni: false,
      perimetrale: false,
      Tastiere: false,
      Telecomando: false,
    });
    // // FORM DATA FINALE
    if (
      formData.azienda.length === 0 ||
      formData.nome.length === 0 ||
      formData.tel.length === 0 ||
      formData.filiale.length === 0 ||
      formData.agente.length === 0
    )
      return toast.error("Compila tutti i campi");

    if (settore.Settore.includes("Controllo Accessi")) {
      emailjs
        .send("asso_gm", "preventivo_accesso", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Telefonia")) {
      emailjs
        .send("asso_gm", "preventivo_telefonia", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Videocitofonia")) {
      emailjs
        .send("asso_gm", "preventivo_videocit", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Domotica")) {
      emailjs
        .send("asso_gm", "preventivo_domotica", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Diffusione")) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_diffusione",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Antincendio")) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_antincendio",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (
      settore.Settore.includes("TVCC") &&
      formData2.tvcc.impianto === "Analogico (AHD-TVI-CVI)"
    ) {
      emailjs
        .send("asso_gm", "preventivo_tvcc_anal", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("TVCC") && formData2.tvcc.impianto === "IP") {
      emailjs
        .send("asso_gm", "preventivo_tvcc_ip", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (
      settore.Settore.includes("Automazione") &&
      formData2.automazione.apertura === "Scorrevole"
    ) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_A_scorrevole",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (
      settore.Settore.includes("Automazione") &&
      formData2.automazione.apertura === "Battente"
    ) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_A_battente",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (
      settore.Settore.includes("Automazione") &&
      formData2.automazione.apertura === "Barriere(Porte Veloci)"
    ) {
      emailjs
        .send("asso_gm", "preventivo_A_pveloci", formData2, "bKba04qrI658pumvI")
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (
      settore.Settore.includes("Automazione") &&
      formData2.automazione.apertura === "Barriere Veloci"
    ) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_A_barriereV",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          () => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
    if (settore.Settore.includes("Antintrusione")) {
      emailjs
        .send(
          "asso_gm",
          "preventivo_antintrusione",
          formData2,
          "bKba04qrI658pumvI"
        )
        .then(
          (result) => {
            toast.success("Richiesta inviata");
          },
          (error) => {
            toast.error("Qualcosa è andato storto", error.message);
          }
        );
    }
  };

  return (
    <div>
      <section className="w-full lg:w-[80vw]   mx-auto bg-opacity-95 rounded-md pt-14 p-8 lg:p-20 ">
        <h1 className="md:text-4xl text-2xl font-bold text-zinc-700 capitalize ">
          Richiedi il tuo preventivo
        </h1>
        <div className="divider rounded bg-assoCol h-1 my-10"></div>
        <form ref={form}>
          <FormData
            formData={formData}
            handleCheck={handleCheck}
            handleChange={handleChange}
            setFormData={setFormData}
            handleFiliale={handleFiliale}
          />
          {open.TVCC && <Tvcc handleChange={handleTVCC} tvcc={tvcc} />}
          {open.Antintrusione && (
            <Antintrusione
              open={open}
              setOpen={setOpen}
              antintrusione={antintrusione}
              setAntintrusione={setAntintrusione}
              handleUpload={handleUpload}
              handleUp={handleUp}
              upload={upload}
            />
          )}
          {open.Automazione && (
            <Automazione
              automazione={automazione}
              handleChange={handleAutomazione}
            />
          )}
          {open.Videocitofonia && (
            <Videocitofonia
              handleChange={handleVideo}
              videocitofonia={videoCit}
            />
          )}

          {open.Telefonia && (
            <Telefonia handleChange={handleTel} telefonia={telefonia} />
          )}

          {open.Diffusione && (
            <DiffusioneSonora
              handleChange={handleDiffusione}
              diffusioneSonora={diffusioneSonora}
            />
          )}
          {open.Accessi && (
            <ControlloAccessi handleChange={handleAccessi} accessi={accessi} />
          )}
          {(open.Domotica || open.Anti) && (
            <Domotica
              open={open}
              handleAnti={handleAntincendio}
              handleDem={handleDomotica}
              upload={upload}
              handleUpload={handleUpload}
              progress={progress}
              handleUp={handleUp}
            />
          )}

          <div className="flex justify-end mt-6 items-center">
            {formData.azienda.length === 0 ||
            formData.nome.length === 0 ||
            formData.tel.length === 0 ||
            formData.filiale.length === 0 ||
            formData.agente.length === 0 ? (
              <p className="px-4">Compila tutti i campi</p>
            ) : (
              <div className="flex space-x-2">
                <ButtonPDF preventivo={formData2} submit={submitPreventivo} />
              </div>
            )}
          </div>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
}

export default ComponiKit;
