import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../firebase_config";

const Agenti = ({ formData, handleChange }) => {
  const [agent, setAgent] = useState([]);

  useEffect(() => {
    if (formData.filiale) {
      const agentRef = collection(database, `${formData.filiale}`);
      const q = query(agentRef, orderBy("id_dip", "asc"));
      onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAgent(data);
      });
    }
  }, [formData.filiale]);
  return (
    <div>
      {formData.filiale && (
        <div className="flex flex-col flex-nowrap">
          <label
            className="text-zinc-700 font-semibold mb-4"
            htmlFor="username"
          >
            Referente Filiale {formData.filiale} *
          </label>
          <select
            name="agente"
            onChange={handleChange}
            className="select select-error w-full max-w-xs"
            value={formData.agente}
          >
            <option>scegli referente</option>
            {agent &&
              agent.map((contact, i) => (
                <option
                  key={i}
                  value={contact.email}
                  className="bg-assoBg selection:bg-assoCol"
                >
                  {contact.nome}
                </option>
              ))}
          </select>
        </div>
      )}
    </div>
  );
};

export default Agenti;
