import {
    collection,
    onSnapshot,
    query,
    orderBy,
    doc,
    updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import moment from "moment";
import { database } from "../../Utils/firebase_config";
import EditButtonAccount from "../UI/Button/EditButtonAccount";
import DelButton from "../UI/Button/DelButtonAccount";
import Spinner from "../UI/Spinner";

function Accounts() {
    const [user, setUser] = useState([]);
    const [loaded, setLoaded] = useState(true);

    //get database data
    useEffect(() => {
        const eventRef = collection(database, "user");
        const q = query(eventRef, orderBy("livello", "asc"));
        onSnapshot(q, (snapshot) => {
            const user = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setUser(user);
            setLoaded(false);
        });
    }, []);

    return (
        <div className="w-full h-full flex flex-col items-center justify-center pb-10 overflow-x-auto ">
            <div className="w-11/12 h-full overflow-x-auto shadow-2xl">
                {loaded && <Spinner />}
                <table className="table table-compact w-full ">
                    <thead>
                        <tr>
                            <th className="dark:bg-zinc-300 ">
                                Tipologia Utente
                            </th>
                            <th className="dark:bg-zinc-300 ">Nome Completo</th>
                            <th className="dark:bg-zinc-300 ">
                                Ragione Sociale
                            </th>
                            <th className="dark:bg-zinc-300 ">Partita Iva</th>
                            <th className="dark:bg-zinc-300 ">Cellulare</th>
                            <th className="dark:bg-zinc-300 ">Email</th>
                            <th className="dark:bg-zinc-300 ">Marchi</th>
                            <th className="dark:bg-zinc-300 ">Messaggio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user.map((dip, idx) => (
                            <tr key={idx}>
                                <th className="dark:bg-zinc-100 dark:border-r-2 ">
                                    <div className="w-5/6 flex flex-col ">
                                        <p>{dip.livello}</p>

                                        {dip.accepted ? (
                                            <p
                                                onClick={() => {
                                                    const ref = doc(
                                                        database,
                                                        "user",
                                                        `${dip.id}`
                                                    );
                                                    updateDoc(ref, {
                                                        accepted: false,
                                                    });
                                                }}
                                                className="font-bold text-green-700 py-2"
                                            >
                                                verificato
                                            </p>
                                        ) : (
                                            <p
                                                onClick={() => {
                                                    const ref = doc(
                                                        database,
                                                        "user",
                                                        `${dip.id}`
                                                    );
                                                    updateDoc(ref, {
                                                        accepted: true,
                                                    });
                                                }}
                                                className="font-bold text-red-700 cursor-pointer underline hover:text-red-800"
                                            >
                                                da verificare
                                            </p>
                                        )}
                                        <p className="text-xs italic">
                                            {moment(dip.createdAt).format(
                                                "DD MM YYYY"
                                            )}
                                        </p>
                                    </div>
                                    <div className="flex w-1/2 justify-between items-center mt-4">
                                        <EditButtonAccount info={dip} />
                                        <DelButton
                                            type="user"
                                            info={dip}
                                            kind="icon"
                                        />
                                    </div>
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.nome} {dip.cognome}
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.ragione}
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.pi}
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.cell}
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.email}
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    <ul>
                                        {dip.marchi
                                            ? dip.marchi.marchi.map(
                                                  (brand, i) => (
                                                      <li key={i}>{brand}</li>
                                                  )
                                              )
                                            : ""}
                                    </ul>
                                </th>
                                <th className="dark:bg-zinc-100 dark:border-zinc-800 ">
                                    {dip.messaggio ? dip.messaggio : ""}
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Accounts;
