import React from "react";

import { AiOutlineCloudUpload } from "react-icons/ai";

const Domotica = ({
    open,
    handleAnti,
    handleDem,
    upload,
    handleUp,
    handleUpload,
    progress,
}) => {
    return (
        <div className=" w-full mt-24 pb-8 bg-assoBg text-zinc-800">
            <div className="divider bg-zinc-200 h-1 "></div>
            {open.Anti && (
                <div className="mx-10">
                    <p className="text-xl text-black">
                        Richiesta Offerta Antincendio
                    </p>
                    <div className="divider bg-zinc-200 h-1 "></div>
                </div>
            )}
            <div className="mx-10">
                <p>Se hai una piantina o un computo metrico caricalo.</p>
                <section>
                    <div className="w-full h-64 flex justify-center items-center border-dashed border-assoCol border-2 my-10 rounded-xl mb-10">
                        <AiOutlineCloudUpload className="text-5xl mr-4 " />
                        <input
                            type="file"
                            onChange={handleUp}
                            accept="application/pdf, image/*"
                        />
                    </div>
                    {upload && (
                        <button
                            onClick={handleUpload}
                            className="glass bg-assoCol hover:bg-orange-600 py-1 px-2 rounded"
                        >
                            Upload <span className="ml-5">{progress}</span>
                        </button>
                    )}

                    {open.Anti && (
                        <div className="w-full">
                            <p>
                                Per le richieste antincendio occorre contattare
                                l'ufficio tecnico per avere un offerta
                                dettagliata. *
                            </p>
                            <input
                                type="radio"
                                name="contactMe"
                                value="Si"
                                onChange={handleAnti}
                            />
                            <span className="text-zinc-400 ml-4">
                                Seleziona la casella e clicca INVIA per essere
                                contattato, oppure chiama il punto vendita più
                                vicino a te
                            </span>
                        </div>
                    )}

                    {open.Domotica && (
                        <div className="w-full pb-5 space-y-4">
                            <div className="divider bg-zinc-200 h-1 "></div>
                            <p className="text-xl text-black">
                                Richiesta Offerta Domotica
                            </p>
                            <div className="divider bg-zinc-200 h-1 "></div>
                            <p>
                                Occorre contattare l'ufficio tecnico per avere
                                un offerta dettagliata. *
                            </p>
                            <input
                                type="radio"
                                name="contactMe"
                                value="Si"
                                onChange={handleDem}
                            />
                            <span className="text-zinc-400 ml-4">
                                Seleziona la casella e clicca INVIA per essere
                                contattato, oppure chiama il punto vendita più
                                vicino a te
                            </span>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
};

export default Domotica;
