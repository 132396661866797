import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

//import { AiFillEdit } from "react-icons/ai";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { Input } from "../Input/InputEdit";

const EditSedi = ({ info, type }) => {
    const [edit, setEdit] = useState({
        ...info,
    });
    const handleChange = (e) => {
        setEdit({
            ...edit,
            [e.target.name]: e.target.value,
        });
    };

    const handleEdit = async () => {
        const ref = doc(database, type, info.id);

        try {
            await updateDoc(ref, {
                filiale: edit.filiale,
                indirizzo: edit.indirizzo,
                orari: edit.orari,
                tel: edit.tel,
                map: edit.map,
            });
            toast.success("Aggiornato");
        } catch (err) {
            toast.error("Oops!Riprova");
        }
    };
    return (
        <div className="z-[999] w-1/3 px-8 h-full flex justify-end items-center">
            <label htmlFor={edit.filiale} className="modal-button underline">
                Modifica
            </label>
            <input type="checkbox" id={edit.filiale} className="modal-toggle" />
            <div className="modal w-full">
                <div className="w-full xl:w-1/2 flex flex-col justify-center items-center rounded-xl px-8 py-1 bg-zinc-100 text-black">
                    <form className="w-full flex flex-col items-center justify-center pt-10">
                        <div className="w-full flex flex-col justify-center items-center">
                            <div className="w-full flex justify-center">
                                <p className="text-2xl uppercase font-bold mb-5 ">
                                    Modifica Filiale
                                </p>
                            </div>

                            <div className="w-full flex flex-col items-center ">
                                <div className="flex space-x-8 items-center px-8">
                                    <Input
                                        type="text"
                                        name="filiale"
                                        value={edit.filiale}
                                        placeholder="Es. Roma Aurelia"
                                        handler={handleChange}
                                        title="Filiale"
                                    />

                                    <Input
                                        type="text"
                                        name="indirizzo"
                                        value={edit.indirizzo}
                                        handler={handleChange}
                                        title="Indirizzo *"
                                    />
                                </div>
                                <div className="flex justify-center space-x-8 items-center px-8">
                                    <Input
                                        type="text"
                                        name="orari"
                                        value={edit.orari}
                                        handler={handleChange}
                                        title="orari *"
                                    />

                                    <Input
                                        type="text"
                                        name="tel"
                                        value={edit.tel}
                                        handler={handleChange}
                                        title="Telefono *"
                                    />
                                </div>
                                <div className="w-full flex justify-start items-center px-10">
                                    <Input
                                        title="Link Google Map *"
                                        type="text"
                                        name="map"
                                        value={edit.map}
                                        handler={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="w-full flex items-center justify-evenly space-x-8 my-4">
                        <label
                            htmlFor={edit.filiale}
                            className="w-1/3 btn glass bg-assoCol text-assoBg uppercase font-bold rounded 
                          hover:bg-assoCol"
                            onClick={handleEdit}
                        >
                            Aggiorna
                        </label>
                        <label
                            className="w-1/3 btn glass bg-assoCol text-assoBg uppercase font-bold rounded
                          hover:bg-assoCol"
                            htmlFor={edit.filiale}
                        >
                            Chudi
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditSedi;
