import React, { useEffect, useState } from "react";
import {
    arrayRemove,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
} from "firebase/firestore";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { RiDeleteBin2Fill } from "react-icons/ri";

const DelButton = ({ info, type, title, messaggio, wdt, setLoaded }) => {
  const w = wdt || "w-4";
  const handleDelete = async () => {
    setLoaded(true);
    try {
      await deleteDoc(doc(database, type, info.id));

      toast.success("Eliminato");
      setLoaded(false);
    } catch (err) {
      toast.error("OPS! Qualcosa è andato storto, riprova");
    }
  };

  return (
    <div className="z-50 w-full h-full ">
      <label htmlFor={info.id} className="modal-button">
        <RiDeleteBin2Fill
          className={`${w} h-full cursor-pointer rounded py-2 z-10`}
        />
      </label>

      <input type="checkbox" id={info.id} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box dark:bg-zinc-100">
          <h3 className="font-bold text-lg">ATTENZIONE!!!</h3>
          <p className="py-4 text-black">
            {messaggio}
            <span className="underline font-bold uppercase">
              {title ? title : ""}
            </span>
          </p>
          <div className="flex w-full justify-between">
            <div className="modal-action">
              <label
                htmlFor={info.id}
                className="btn bg-assoCol glass hover:bg-assoCol text-white"
                onClick={handleDelete}
              >
                Elimina
              </label>
            </div>
            <div className="modal-action">
              <label
                htmlFor={info.id}
                className="btn bg-assoCol glass hover:bg-assoCol text-white"
              >
                Annulla
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DelImg = ({ img, id }) => {
  const deleteImg = async (e) => {
    e.preventDefault();
    try {
      await updateDoc(doc(database, "filiali", `${id}`), {
        imgs: arrayRemove(img),
      });
      toast.success("Cancellato!");
    } catch (err) {
      toast.error("NON cancellato");
    }
  };
  return (
    <div className="rounded-md w-40 h-36 bg-white shadow-xl m-2">
      <img
        src={img}
        alt="img"
        className="w-full h-24 object-cover rounded-t-md"
      />

      <div className="w-full flex justify-center items-center uppercase">
        <label htmlFor={img} className="modal-button mt-3">
          Elimina
        </label>

        <input type="checkbox" id={img} className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box dark:bg-zinc-100">
            <h3 className="font-bold text-lg">ATTENZIONE!!!</h3>
            <p className="py-4">Sei sicuro di voler eliminare questa foto?</p>
            <div className="flex w-full justify-between">
              <div className="modal-action">
                <label
                  htmlFor={img}
                  className="btn bg-assoCol glass hover:bg-assoCol text-white"
                  onClick={deleteImg}
                >
                  Elimina
                </label>
              </div>
              <div className="modal-action">
                <label
                  htmlFor={img}
                  className="btn bg-assoCol glass hover:bg-assoCol text-white"
                >
                  Annulla
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DelFiliale = ({ info, type, title, messaggio }) => {
  const [all, setAll] = useState([]);
  useEffect(() => {
    const eventRef = collection(database, title);
    const q = query(eventRef, orderBy("id_dip", "desc"));
    onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAll(data);
    });
  }, [title]);
  const handleDel = (e) => {
    e.preventDefault();
    deleteDoc(doc(database, type, info.id));
    all
      .map((list) => deleteDoc(doc(database, title, list.id)))
      .then(() =>
        toast
          .success("Eliminato")
          .catch((err) => toast.error("Qualcosa è andato storto", err))
      );
  };

  return (
    <div className="w-1/2 h-full ">
      <label htmlFor={info.id} className="modal-button">
        <RiDeleteBin2Fill className={`w-8 h-full rounded py-2`} />
      </label>

      <input type="checkbox" id={info.id} className="modal-toggle" />
      <div className="modal modal-bottom h-full  sm:modal-middle ">
        <div className="modal-box dark:bg-zinc-800 flex justify-evenly items-start">
          <label
            htmlFor={info.id}
            className="btn bg-assoCol glass hover:bg-assoCol text-white"
            onClick={handleDel}
          >
            Elimina
          </label>

          <label
            htmlFor={info.id}
            className="btn bg-assoCol glass hover:bg-assoCol text-white"
          >
            Annulla
          </label>
        </div>
      </div>
    </div>
  );
};

export { DelButton, DelImg, DelFiliale };
