import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";

import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { auth, database } from "../Utils/firebase_config";

const SignUp = () => {
  const navigate = useNavigate();
  const [account, setAccount] = useState({
    ragione: "",
    nome: "",
    cognome: "",
    pi: "",
    cell: "",
    messaggio: "",
    email: "",
    password: "",
    email2: "",
    password2: "",
    uid: "",
    accepted: false,
    remember: false,
  });
  const [marchi, setMarchi] = useState({ marchi: [] });

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleBrand = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setMarchi({
        marchi: [...marchi.marchi, e.target.name],
      });
    } else {
      const index = marchi.marchi.indexOf(e.target.value);
      marchi.marchi.splice(index, 1);
      setMarchi({
        marchi: marchi.marchi,
      });
    }
  };

  const checkPassword = (str) => {
    // at least one number, one lowercase and one uppercase letter
    // at least six characters
    let check = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/;
    check.test(str);
    if (check.test(str) === false) {
      return false;
    }
  };

  const addToDb = async (e) => {
    e.preventDefault();
    if (!account) return null;
    if (account.email !== account.email2) {
      toast.error("Le email non sono uguali");
      return;
    }
    if (account.password !== account.password2) {
      toast.error("Le password non sono uguali");
      return;
    }
    checkPassword(account.password);

    if (checkPassword(account.password) === false) {
      return toast.error(
        "La password deve contenere minimo 6 caratteri, 1 numero, 1 lettera minuscola, e 1 maiuscola "
      );
    }

    setAccount({
      ragione: "",
      nome: "",
      cognome: "",
      pi: "",
      cell: "",
      attivita: "",
      messaggio: "",
      email: "",
      password: "",
      uid: "",
      accepted: false,
      remember: false,
      marchi: [],
    });
    try {
      await createUserWithEmailAndPassword(
        auth,
        account.email,
        account.password
      );

      const user = auth.currentUser;
      const accountRef = collection(database, "user");
      await addDoc(accountRef, {
        ragione: account.ragione,
        nome: account.nome,
        cognome: account.cognome,
        pi: account.pi,
        cell: account.cell,
        attivita: account.attivita,
        messaggio: account.messaggio,
        marchi: marchi,
        email: account.email,
        livello: "Installatore 1",
        createdAt: Timestamp.now().toDate().toDateString(),
        uid: user.uid,
        accepted: false,
        remember: account.remember,
      });
      await sendEmailVerification(auth.currentUser);

      await emailjs.send(
        "asso_gm",
        "registrazione",
        account,
        "bKba04qrI658pumvI"
      );
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("Email già registrata");
      } else {
        toast.error("C'è qualcosa che non va...");
      }
    }
    //toast("Registrazione effettuata, ora verifica la tua email");

    navigate("/controlla_la_posta");
  };

  return (
    <div className=" w-full h-full">
      <div className="w-full h-full text-zinc-800 bg-assoBg flex flex-col sm:justify-center items-center pt-6 sm:pt-0 ">
        <h2 className="mt-8 text-center text-4xl font-extrabold uppercase">
          Registrati
        </h2>
        <form className="w-5/6  mt-14 flex flex-col items-center justify-center">
          <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
            <div className="w-full md:w-1/3 font-bold mb-3">
              <label className="block mb-1" htmlFor="ragione">
                Ragione Sociale
              </label>
              <input
                id="ragione"
                type="text"
                name="ragione"
                value={account.ragione}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-full md:w-1/3 font-bold mb-3">
              <label className="block mb-1" htmlFor="nome">
                Nome
              </label>
              <input
                id="nome"
                type="text"
                name="nome"
                value={account.nome}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-full md:w-1/3 font-bold mb-3">
              <label className="block mb-1" htmlFor="cognome">
                Cognome
              </label>
              <input
                id="cognome"
                type="text"
                name="cognome"
                value={account.cognome}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
            <div className="w-full md:w-1/3 font-bold mb-3">
              <label className="block mb-1" htmlFor="pi">
                Partita Iva
              </label>
              <input
                id="pi"
                type="text"
                name="pi"
                value={account.pi}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-full md:w-1/3 font-bold mb-3">
              <label className="block mb-1" htmlFor="cell">
                Cellulare
              </label>
              <input
                id="cel"
                type="text"
                name="cell"
                value={account.cell}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-1/3"></div>
          </div>
          <div className="w-full">
            <p className="text-left">Attività:</p>
            <div className="flex flex-wrap justify-start my-2 pb-2">
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="INSTALLATORE"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  INSTALLATORE
                </label>
              </div>

              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="RIVENDITORE"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  RIVENDITORE
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="DISTRIBUTORE"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  DISTRIBUTORE
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="PROGETTISTA"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  PROGETTISTA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="ENTE STATALE"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  ENTE STATALE
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  name="attivita"
                  value="SYSTEM INTEGRATOR"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  SYSTEM INTEGRATOR
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-80">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                                    checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                                    mr-2 cursor-pointer"
                  type="radio"
                  value="UTENTE FINALE"
                  name="attivita"
                  onChange={handleChange}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  UTENTE FINALE
                </label>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center">
            <div className="w-full font-bold mb-4">
              <label
                htmlFor="messaggio"
                className="form-label inline-block mb-2 text-gray-700"
              >
                Come hai conosciuto ASSO SICUREZZA?
              </label>
              <textarea
                className="form-control block w-full px-3 py-1.5 text-base font-normal
                                text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300
                                rounded transition ease-in-out m-0
                                focus:text-gray-700 focus:bg-white focus:border-assoCol focus:outline-none"
                id="messaggio"
                name="messaggio"
                rows="3"
                value={account.messaggio}
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>
          <div>
            <p>Marchi utilizzati</p>
            <div className="flex flex-wrap justify-start my-4 ">
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol 
                        checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left 
                        mr-2 cursor-pointer"
                  type="checkbox"
                  name="KSENIA"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800 font-bold">
                  KSENIA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="RISCO"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  RISCO
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="INIM"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  INIM
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="PESS"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  PESS
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="DAHUA"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  DAHUA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="HIKVISION"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  HIKVISION
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="HANWHA"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  HANWHA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="BOS"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  BOS
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="NOTIRIER"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  NOTIRIER
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="ESSER"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  ESSER
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="FDP"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  FDP
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="TECNOCONTROL"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  TECNOCONTROL
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="PROEL"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  PROEL
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="UBIQUITI"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  UBIQUITI
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="D-LINK"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  D-LINK
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="PLANET"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  PLANET
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="PULSAR"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  PULSAR
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="FARSISA"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  FARSISA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="NICE"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  NICE
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="FADINI"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  FADINI
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="BENINCA"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  BENINCA
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="CARDIN"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  CARDIN
                </label>
              </div>
              <div className="form-check form-check-inline font-bold w-40">
                <input
                  className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-assoCol checked:border-assoCol focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  name="LABEL"
                  onChange={handleBrand}
                />
                <label className="form-check-label inline-block text-gray-800">
                  LABEL
                </label>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
            <div className="w-full md:w-1/3 font-bold my-4">
              <label className="block mb-1" htmlFor="email">
                E-mail
              </label>
              <input
                type="text"
                name="email"
                value={account.email}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-full md:w-1/3 font-bold my-4">
              <label className="block mb-1" htmlFor="email">
                Ripeti E-mail
              </label>
              <input
                type="text"
                name="email2"
                value={account.email2}
                onChange={handleChange}
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-full md:w-1/3 font-bold">
              <label className="block mb-1">Password</label>
              <input
                type="password"
                name="password"
                value={account.password}
                onChange={handleChange}
                placeholder="min 6 caratteri "
                minLength="6"
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>

            <div className="w-full md:w-1/3 font-bold">
              <label className="block mb-1">Ripeti Password</label>
              <input
                type="password"
                name="password2"
                value={account.password2}
                onChange={handleChange}
                placeholder="min 6 caratteri "
                minLength="6"
                className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                required
              />
            </div>
            <div className="w-1/3 mb-4"></div>
          </div>

          <div className="w-full mb-4 flex justify-between items-center space-x-4 my-4">
            <div className="flex justify-start items-center">
              <input
                name="remember"
                id="remember"
                type="checkbox"
                onChange={handleChange}
                className="border border-gray-300 text-red-600 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm leading-5 text-gray-900"
              >
                Ricorda la mia password
              </label>
            </div>
            <Link to="/reset-password" className="text-sm">
              Hai dimenticato la password?
            </Link>
          </div>
          <div className="w-full mb-4 pb-2 flex flex-col md:flex-row justify-start items-center">
            <button
              onClick={addToDb}
              className="w-full bg-assoCol glass inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-zinc-300 active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition"
            >
              Registrati
            </button>
            <button className="w-full bg-assoBg glass inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold capitalize text-zinc-800 hover:bg-zinc-300 active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">
              <Link to="/login">Indietro</Link>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
