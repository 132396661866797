import React from "react";

const CaviData = ({ data }) => {
  return (
    <div className="w-full flex flex-col rounded py-2 my-2">
      <p className="font-bold mt-4 underline text-assoCol uppercase">Cavi</p>

      <div className=" flex flex-col">
        {data.map((d, i) => (
          <p key={i} className="capitalize font-bold lg:w-1/4 mt-2">
            {d}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CaviData;
