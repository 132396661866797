import React, { useEffect, useState } from "react";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { Link } from "react-router-dom";
import { database } from "../../Utils/firebase_config";
import { motion } from "framer-motion";
const cutPara = (para) => {
  let words = para.split(/\s+/).slice(0, 10).join(" ") + "...";
  return words;
};

cutPara(
  " ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also"
);

const Download = () => {
  const [brandsState, setBrandsState] = useState([]);
  useEffect(() => {
    const brandsRef = collection(database, "brand");
    {
      const q = query(brandsRef, orderBy("importance", "asc"));
      onSnapshot(q, (snapshot) => {
        const brandsNew = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBrandsState(brandsNew);
      });
    }
  }, []);

  return (
    <div className="w-full bg-gradient-to-b pt-4  ">
      <div className="flex justify-center  bg-white">
        <div className="rounded-xl border-4  backdrop-blur-lg neumorphism   border-assoCol p-8  mt-6 w-11/12 bg-white/40">
          <div className="  flex   items-center justify-center  pb-2 border-b-2 border-assoCol my-0 md::mx-8 w-12/12 h-11 ">
            <h2 className="flex sm:text-2xl text-[16px] items-center p-4 text-center text-md  pt-2  text-assoCol  w-full font-semibold">
              Scegli uno dei seguenti brand:
            </h2>
          </div>
          <div className="m-10  mt-24 grid gap-10 sm:grid-cols-2  md:grid-cols-3   grid-cols-1 lg:grid-cols-6  justify-center items-center content-center flex-wrap lg:inset-y-0 ">
            {brandsState &&
              brandsState.map((logo) => (
                <div key={logo.name} className="w-full flex justify-center">
                  <div
                    className={` justify-center rounded duration-100 cursor-pointer hover:scale-105 flex items-center w-54 p-10 lg:p-1 lg:w-32  bg-white`}
                  >
                    <Link
                      to={`/${logo?.name?.replace(/%20/g, " ")}`}
                      rel="noreferrer"
                    >
                      <motion.img
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        transition={{ duration: 1.2 }}
                        src={logo.img}
                        alt="logo"
                      />
                    </Link>
                  </div>
                </div>
              ))}

            {/* <img
                            className="  object-contain sm:h-full rounded-xl  xl:max-w-[70vw]  xl:h-full"
                            src={prodottiScreen}
                            alt=""
                        /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
