import React from "react";

const Uploader = ({ name, upload, handleUp, progress, handleUpload }) => {
    return (
        <div className="w-full flex items-center justify-between">
            <input
                name={name}
                type="file"
                accept="application/pdf, image/*"
                onChange={handleUp}
                className="w-1/3 flex items-center rounded px-4 py-3 bg-white bg-opacity-50 focus:border-none ml-4 mt-2"
            />
            {upload && (
                <button
                    onClick={handleUpload}
                    className="w-1/4 glass bg-assoCol hover:bg-orange-600 py-2.5 mt-2 rounded mr-4"
                >
                    Upload
                    <span className="pl-5">
                        {progress === 0 ? 0 : progress}
                    </span>
                </button>
            )}
        </div>
    );
};

export default Uploader;
