import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../../Utils/firebase_config";
// import { database } from "../../firebase-config";
import Filiale from "./Filiale";

const FilialiGroup = () => {
  const [rubrica, setRubrica] = useState([]);

  useEffect(() => {
    const rubRef = collection(database, "filiali");
    const q = query(rubRef, orderBy("id_filiale", "asc"));
    onSnapshot(q, (snapshot) => {
      const sedi = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRubrica(sedi);
    });
  }, []);
  return (
    <div className="md:grid flex px-10 flex-col items-center justify-center grid-cols md:grid-cols-3 md:gap-4 ">
      {rubrica.map((item, idx) => (
        <div key={idx} className="w-full h-full">
          <div className="w-11/12  h-full flex justify-content-center items-center ">
            <Filiale
              filiale={item}
              sk={item.id}
              code={item.code}
              city={item.filiale}
              via={item.via}
              indirizzo={item.indirizzo}
              tel={item.tel}
              orari={item.orari}
              map={item.map}
              imgs={item.imgs}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilialiGroup;
