import React from "react";
import FormNews from "../../components/Forms/FormNews";
import Gestione from "../../components/Layout/Gestione";
import NovitàAdmin from "../../components/Organism/Novità/NovitàAdmin";

const GestioneNovità = () => {
    return (
        <Gestione title="novità">
            <div className="w-11/12 h-full mb-20">
                <FormNews />
            </div>
            <div className="w-full h-full ">
                <NovitàAdmin />
            </div>
        </Gestione>
    );
};

export default GestioneNovità;
