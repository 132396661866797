import {
    collection,
    doc,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { database } from "../../../Utils/firebase_config";
import Spinner from "../../UI/Spinner";
import { DelButton } from "../../UI/Button/DelButton";
import EditNews from "../../UI/Button/EditNews";
import { HtmlToJSX } from "../../../Utils/utils";

const NovitàAdmin = () => {
    const [news, setNews] = useState([]);
    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        const newsRef = collection(database, "novità");
        const q = query(newsRef, orderBy("date", "desc"));
        onSnapshot(q, (snapshot) => {
            const novità = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setNews(novità);
            setLoaded(false);
        });
    }, []);
    return (
        <div className="w-full h-full flex flex-col items-center justify-center pb-10 overflow-x-auto ">
            <div className="w-11/12 h-full overflow-x-auto shadow-2xl rounded-xl">
                {loaded && <Spinner />}
                <div className="table table-compact w-full ">
                    <div className="w-full">
                        <p className="dark:bg-zinc-300 p-4 uppercase text-2xl font-bold">
                            Novità
                        </p>
                    </div>
                    {news.map((list, i) => (
                        <div
                            key={i}
                            className="dark:bg-zinc-100 dark:border-b-zinc-800 border-2 flex w-full p-4 space-y-2"
                        >
                            <div className="w-1/3 flex flex-col ">
                                <p className="font-semibold capitalize">
                                    {list.date}
                                </p>
                                <p className="font-normal capitalize">
                                    evento:
                                    <span className="font-semibold ml-2 capitalize">
                                        {list.nome}
                                    </span>
                                </p>

                                {list.selected ? (
                                    <p
                                        onClick={() => {
                                            const ref = doc(
                                                database,
                                                "novità",
                                                `${list.id}`
                                            );
                                            updateDoc(ref, {
                                                selected: false,
                                            });
                                        }}
                                        className="font-bold text-green-700 py-2 underline"
                                    >
                                        selezionato
                                    </p>
                                ) : (
                                    <p
                                        onClick={() => {
                                            const ref = doc(
                                                database,
                                                "novità",
                                                `${list.id}`
                                            );
                                            updateDoc(ref, {
                                                selected: true,
                                            });
                                        }}
                                        className="font-bold text-red-700 cursor-pointer underline hover:text-red-800 py-2"
                                    >
                                        non selezionato
                                    </p>
                                )}
                                <div className="flex w-16 ">
                                    <DelButton
                                        type="novità"
                                        info={list}
                                        title={list.nome}
                                        kind="icon"
                                        messaggio="Sei sicuro di voler eliminare "
                                    />
                                    <EditNews info={list} />
                                </div>
                            </div>
                            <div className="w-[30rem] flex flex-end ">
                                <pre className="text-justify truncate hover:">
                                    {HtmlToJSX(list.descrizione)}
                                </pre>
                            </div>
                            <div className="w-[30rem] flex justify-end">
                                <img
                                    src={list.image}
                                    alt="evento_img"
                                    className="w-1/2 h-full object-contain"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default NovitàAdmin;
