import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { AiFillEdit } from "react-icons/ai";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import mansioni from "../../../Utils/mansioni";
import { InputEdit, SelectEdit } from "../Input/InputEdit";

const EditButtonAccount = ({ info }) => {
    const [edit, setEdit] = useState({
        id: info.id,
        livello: info.livello,
        ragione: info.ragione,
        nome: info.nome,
        cognome: info.cognome,
        pi: info.pi,
        cell: info.cell,
    });

    const handleChange = (e) => {
        setEdit({ ...edit, [e.target.name]: e.target.value });
    };

    const handleEdit = async () => {
        const ref = doc(database, "user", info.id);
        try {
            await updateDoc(ref, {
                livello: edit.livello,
                ragione: edit.ragione,
                nome: edit.nome,
                cognome: edit.cognome,
                pi: edit.pi,
                cell: edit.cell,
            });
            toast.success("Aggiornato");
        } catch (err) {
            toast.error("Oops!Riprova");
        }
    };
    return (
        <div className="w-full h-full">
            <label htmlFor={edit.nome + edit.id} className="modal-button">
                <AiFillEdit className="w-4 h-full rounded" />
            </label>
            <input
                type="checkbox"
                id={edit.nome + edit.id}
                className="modal-toggle"
            />
            <div className="modal modal-bottom sm:modal-middle">
                <div className="modal-box dark:bg-zinc-100 mx-1">
                    <div className="w-full">
                        <SelectEdit
                            title="livello"
                            value={edit.livello}
                            handler={handleChange}
                            options={mansioni}
                        />
                        <InputEdit
                            name="ragione"
                            title="Ragione Sociale"
                            value={edit.ragione}
                            handler={handleChange}
                        />
                        <div className="flex items-center justify-between">
                            <label htmlFor="nome">Nome Completo</label>
                            <div className="flex w-1/2">
                                <input
                                    id="nome"
                                    type="text"
                                    name="nome"
                                    value={edit.nome}
                                    onChange={handleChange}
                                    className="w-1/2 py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block"
                                />
                                <input
                                    id="cognome"
                                    type="text"
                                    name="cognome"
                                    value={edit.cognome}
                                    onChange={handleChange}
                                    className="w-1/2 py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block"
                                />
                            </div>
                        </div>
                        <InputEdit
                            name="pi"
                            title="Partita Iva"
                            value={edit.pi}
                            handler={handleChange}
                        />
                        <InputEdit
                            name="cell"
                            title="Telefono"
                            value={edit.cell}
                            handler={handleChange}
                        />
                    </div>
                    <div className="w-full flex justify-between mt-4 space-x-1">
                        <label
                            htmlFor={edit.nome}
                            className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded 
                          hover:bg-assoCol"
                            onClick={handleEdit}
                        >
                            Aggiorna
                        </label>
                        <label
                            className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded
                          hover:bg-assoCol"
                            htmlFor={edit.nome + edit.id}
                        >
                            Chudi
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditButtonAccount;
