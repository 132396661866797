import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { CardEventi } from "../../components/UI/Card/Card";
import Hero from "../../components/UI/Hero/Hero";
import { database } from "../../Utils/firebase_config";
import { Box } from "@chakra-ui/react";
function News() {
  const [novità, setNovità] = useState([]);
  useEffect(() => {
    const newsRef = collection(database, "novità");
    const q = query(newsRef, orderBy("date", "desc"));
    onSnapshot(q, (snapshot) => {
      const novità = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNovità(novità);
    });
  }, []);
  const srcImage =
    "https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/Immagini%2FWhatsApp%20Image%202023-04-07%20at%2016.52.24.jpeg?alt=media&token=cf424e73-ac0c-4926-ac74-f98d2b5d6f40";
  const srcImageMobile =
    "https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/Immagini%2FTEST%20NOVITA%20MOBILE.jpg?alt=media&token=c904aaa9-a5f7-400c-a9a4-610d3f1ef0ee";

  return (
    <div className="  min-h-screen">
      <Hero
        name="NOVITA'"
        srcImage={srcImage}
        srcImageMobile={srcImageMobile}
        desc={
          "Qui puoi scoprire tutti le ultime novità riguardante il mondo di Asso"
        }
      />

      <div className="flex p-4 justify-center flex-col items-center  pb-20 overflow-scroll w-screen   m-8 mt-12  mb-0 gap-6">
        {/* SINGLE CARD COMPONENT */}
        <Box className="flex w-full flex-col md:flex-row items-center p-3  justify-between">
          <p className=" text-[#ccc]">TUTTI LE NEWS</p>
        </Box>
        {novità.map((news, idx) => {
          return (
            <CardEventi
              image={news?.image}
              luogo={news?.luogo}
              descrizione={news?.descrizione}
              nome={news?.nome}
              isNews
            />
          );
        })}
      </div>
    </div>
  );
}

export default News;
