import React from "react";
import Gestione from "../../components/Layout/Gestione";
import FormEventi from "../../components/Forms/FormEventi";
import EventiAdmin from "../../components/Organism/Eventi/EventiAdmin";

const GestioneEventi = () => {
    return (
        <Gestione title="eventi">
            <div className="w-11/12 h-full mb-20">
                <FormEventi />
            </div>
            <div className="w-full h-full ">
                <EventiAdmin />
            </div>
        </Gestione>
    );
};

export default GestioneEventi;
