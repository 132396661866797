import React from "react";
import teamv from "./loghitele/teamwind.png";
import anydesk from "./loghitele/anywind.png";
import supremo from "./loghitele/supwind.png";
import teama from "./loghitele/teamapple.png";
import anydeska from "./loghitele/anyapple.jpg";
import supremoa from "./loghitele/supapple.png";

import { AiFillApple } from "react-icons/ai";
export const Teleassistenza = () => {
    return (
      <div className="hero backgroundAssistenza min-h-screen">
        <div className="hero-overlay bg-opacity-70"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="">
            <div className=" sm:p-12 p-8 lg:pt-0 space-y-6 w-full items-center flex-col justify-between flex text-black h-full border-assoCol">
              <div className=" w-full md:w-[80%] lg:w-[60%] mb-4 text-[#f3f1f1]  space-y-6">
                <h2 className="md:text-[40px]    border-b-2 border-assoCol text-[23px] font-bold ">
                  ASSISTENZA REMOTA
                </h2>
                <p className=" pt-1 text-[14px] ">
                  Disponibile esclusivamente per i clienti ASSO SICUREZZA su
                  richiesta del supporto tecnico dedicato. Seleziona il software
                  indicato dall'assistenza tecnica durante la sessione
                  telefonica:
                </p>
              </div>
              <div className="w-full  justify-center flex md:flex-row h-full  space-x-6 ">
                {" "}
                <div className="  max-w-[100px] w-full  hover:scale-105">
                  <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FTeamViewerQS_windows.exe?alt=media&token=3fc24130-7434-45bd-aa7d-c4e7d3b5adaa">
                    {" "}
                    <img src={teamv} alt="" className="w-full rounded h-full" />
                  </a>
                </div>
                <div className=" max-w-[100px] w-full hover:scale-105">
                  <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FAnyDesk_windows.exe?alt=media&token=5a10c29e-5dbd-4066-9283-caa97e86a927">
                    {" "}
                    <img
                      src={anydesk}
                      alt=""
                      className="w-full rounded h-full"
                    />
                  </a>
                </div>{" "}
                <div className=" max-w-[100px] w-full hover:scale-105">
                  <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FSupremo_windows.exe?alt=media&token=2769a266-8d9b-452f-adf3-c6ebb485581e">
                    <img
                      src={supremo}
                      alt=""
                      className="w-full rounded h-full"
                    />
                  </a>
                </div>{" "}
                <div className=" max-w-[100px] w-full hover:scale-105">
                  <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FRustdesk_windows.exe?alt=media&token=c830e788-83a8-4da5-85f8-55207227e1ea">
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FRustdesk_logo_con_testo.png?alt=media&token=c7fd462b-5112-4d25-893c-57d215b6abb1"
                      }
                      alt=""
                      className="w-full rounded h-full"
                    />
                  </a>
                </div>{" "}
              </div>
              <div className="w-full md:w-[100%]  flex flex-col justify-center items-center lg:w-[60%] mb-4 text-[#f3f1f1]">
                <p className="text-[12px] pt-1  flex items-center lg:text-[18px]">
                  Versione compatibile con MAC IOS <AiFillApple />
                </p>
                <div className="w-full  justify-center flex md:flex-row h-full pt-6 space-x-6 ">
                  {" "}
                  <div className="  max-w-[50px] w-full hover:scale-105 ">
                    <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FTeamViewerQS_macos.dmg?alt=media&token=36533134-d2e1-4683-a786-311e906fa20d">
                      <img
                        src={teama}
                        alt=""
                        className="w-full rounded h-full"
                      />
                    </a>
                  </div>
                  <div className=" max-w-[50px] w-full hover:scale-105">
                    <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FAnyDesk_macos.dmg?alt=media&token=c4b3077e-643c-43e0-8746-e244ec9aea53">
                      {" "}
                      <img
                        src={anydeska}
                        alt=""
                        className="w-full rounded h-full"
                      />
                    </a>
                  </div>{" "}
                  <div className=" max-w-[50px] w-full hover:scale-105">
                    <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FSupremo_macos.dmg?alt=media&token=92ac3ae3-0711-40eb-b382-40e04b93a474">
                      <img
                        src={supremoa}
                        alt=""
                        className="w-full rounded h-full"
                      />
                    </a>
                  </div>
                  <div className=" max-w-[50px] w-full hover:scale-105">
                    <a href="https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FRustdesk_macos.dmg?alt=media&token=3bcb6fed-26fc-4f36-bedf-a975185badb7">
                      <img
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/asso-sicurezza-d46a8.appspot.com/o/TELEASSISTENZA%2FRustdesk_logo_con_testo.png?alt=media&token=c7fd462b-5112-4d25-893c-57d215b6abb1"
                        }
                        alt=""
                        className="w-full rounded h-full"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};
