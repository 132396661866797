import {
  Accordion,
  AccordionButton,
  AccordionItem,
  Box,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import { getDownloadURL, listAll, ref } from "firebase/storage";

import React, { useEffect, useState } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { BsArrowDownCircle } from "react-icons/bs";
import { IoMdArrowBack } from "react-icons/io";
import { motion } from "framer-motion";
import folderIcon from "../../assets/images/folder.png";
import { storage } from "../../Utils/firebase_config";
import { useNavigate, Link } from "react-router-dom";
import BrandItems from "./BrandItems";
//import { Fetcher } from './fetcher';
import { FaUserCircle } from "react-icons/fa";

function BrandPage({ brand, livello }) {
  const [selectOrNoData, setSelectOrNoData] = useState(
    "Seleziona una tipologia"
  );
  const navigate = useNavigate();
  const [selectedFolder, setSelectedFolder] = useState("");
  const [files, setFiles] = useState([]);
  useEffect(() => {
    let isMounted = true;
    if (selectedFolder === undefined || selectedFolder === "") return;
    const bucketRef = ref(
      storage,
      `${brand.name.toUpperCase()}/${selectedFolder.toUpperCase()}/`
    );
    if (isMounted) {
      listAll(bucketRef).then((response) => {
        if (response.items.length !== 0) {
          response.items.forEach((item) => {
            getDownloadURL(item).then((url) => {
              setFiles((prev) => [...prev, { url: url, title: item.name }]);
            });
          });
        } else {
          setSelectOrNoData("Non ci sono download disponibili");
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [selectedFolder, brand.name]);

  return (
    <div className="w-full lg:py-12 flex justify-center text-white">
      <div className="lg:w-[80%] w-full flex-col lg:flex rounded-xl shadow-xl bg-opacity-10 bg-stone-200">
        <div className="bg-white  cursor-pointer">
          <div
            className="w-full text-assoCol flex items-center space-x-2"
            onClick={() => navigate(-1)}
          >
            <IoMdArrowBack />
            Indietro
          </div>
          <div className=" md:px-6 py-10 pb-0  z-50 flex-col md:flex-row shadow-md bg-stone-200/40 rounded-md backdrop-blur-lg items-center justify-center  lg:flex lg:h-128 lg:py-10 ">
            <div className="flex flex-col justify-center items-center w-full lg:flex-row lg:w-1/2">
              <div className="max-w-sm block">
                <div className="w-full border-b-2   pl-[4px] pb-2 ">
                  <h1 className="text-3xl tracking-wide text-center lg:text-left lg:pb-0 pb-2 w-full font-normal text-gray-800 lg:text-[30px]">
                    AREA DOWNLOAD
                  </h1>

                  <b className="text-assoCol lg:text-2xl  hidden lg:block lg:text-[40px] uppercase py-4">
                    {brand.name}
                  </b>
                </div>

                <p className="mt-4 px-8 lg:px-0 lg:text-left text-center  text-gray-800">
                  Benvenuto nell'area Download. Qui puoi effettuare il download
                  di tutto il materiale riguardante {brand.name}
                </p>
              </div>
            </div>
            <div
              className="flex items-center self-center mt-10 md:mt-4 rounded-md md:py-12 justify-center w-full  bg-white  
            lg:w-1/2"
            >
              <div className="w-3/3 py-6">
                <img
                  className="object-cover  max-w-[150px] md:max-w-[250px] rounded-md "
                  src={brand.img ? brand.img : "folderIcon"}
                  alt="none"
                />
              </div>
            </div>
          </div>
        </div>
        {/* <Button onClick={saveFile}></Button> */}
        <div className="w-full bg-zinc-100 flex justify-center">
          <Accordion w="100%" color="black" defaultIndex={[0]} allowMultiple>
            <AccordionItem
              display={"flex"}
              w="full"
              justifyContent="center"
              alignItems={"center"}
              flexDirection={"column"}
            >
              <h2>
                <AccordionButton
                  _hover={{
                    background: "transparent",
                    color: "teal.500",
                    borderRadius: "100px",
                  }}
                >
                  <Box display={"flex"} alignItems="center">
                    <Text
                      color="#fd3e02"
                      py="16px"
                      pr="10px"
                      textAlign={"center"}
                    >
                      Sezione Contenuti{" "}
                    </Text>
                    <BsArrowDownCircle color="#fd3e02" />{" "}
                  </Box>
                </AccordionButton>
              </h2>
              {files.length !== 0 && (
                <AccordionPanel>
                  <motion.div
                    initial={{ x: -100, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.2 }}
                    viewport={{ once: true }}
                  >
                    <BrandItems data={files} />
                  </motion.div>
                </AccordionPanel>
              )}
              {files.length === 0 && (
                <AccordionPanel w="full" pb={4}>
                  <div className="w-full hover:scale-105 xl:hover:scale-100  duration-200   cursor-pointer py-4 flex hover:text-white  md:p-8 justify-start">
                    <div className="overflow-x-auto">
                      <div className=" w-full flex space-x-4 py-4 items-center">
                        <AiOutlineFile
                          color="assoCol"
                          className="text-assoCol lg:text-[22px] text-[18px]"
                        />
                        <p className="text-black  lg:text-[16px] text-[14px]">
                          {selectOrNoData}
                        </p>
                      </div>
                    </div>
                  </div>
                </AccordionPanel>
              )}
            </AccordionItem>
          </Accordion>
        </div>
        <div className="w-full flex justify-center flex-wrap">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 w-full ">
            {livello === "Installatore 1" &&
              link.map(
                (list, key) =>
                  (list === "LISTINI E CATALOGHI" ||
                    list === "PROMO IN CORSO" ||
                    list === "BOLLETTINI TECNICI" ||
                    list === "SCHEDE TECNICHE E BROCHURE") && (
                    <div key={key}>
                      <div className="flex glass p-6 pl-12 w-full items-center border-4   ">
                        <div className="w-[80px] p-2 h-[80px] flex justify-center items-center">
                          <img
                            src={folderIcon}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <button
                          onClick={() => {
                            setSelectedFolder(list);
                            setFiles([]);
                          }}
                        >
                          <p className="text-left px-10 text-assoCol text-[16px]">
                            {list.toUpperCase()}
                          </p>
                        </button>
                      </div>
                    </div>
                  )
              )}
            {(livello === "Installatore 2" || livello === "Rivenditore") &&
              link.map(
                (list, key) =>
                  (list === "LISTINI E CATALOGHI" ||
                    list === "PROMO IN CORSO" ||
                    list === "BOLLETTINI TECNICI" ||
                    list === "SCHEDE TECNICHE E BROCHURE" ||
                    list === "MANUALI E GUIDE" ||
                    list === "SOFTWARE" ||
                    list === "FIRMWARE" ||
                    list === "VIDEO") && (
                    <div key={key}>
                      <div className="flex glass p-6 pl-12 w-full items-center border-4   ">
                        <div className="w-[80px] p-2 h-[80px] flex justify-center items-center">
                          <img
                            src={folderIcon}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <button
                          onClick={() => {
                            setSelectedFolder(list);
                            setFiles([]);
                          }}
                        >
                          <p className="text-left px-10 text-assoCol text-[16px]">
                            {list.toUpperCase()}
                          </p>
                        </button>
                      </div>
                    </div>
                  )
              )}

            {livello === "Admin" &&
              link.map(
                (list, key) =>
                  (list === "LISTINI E CATALOGHI" ||
                    list === "PROMO IN CORSO" ||
                    list === "BOLLETTINI TECNICI" ||
                    list === "SCHEDE TECNICHE E BROCHURE" ||
                    list === "MANUALI E GUIDE" ||
                    list === "SOFTWARE" ||
                    list === "FIRMWARE" ||
                    list === "VIDEO") && (
                    <div key={key}>
                      <div className="flex glass p-6 pl-12 w-full items-center border-4   ">
                        <div className="w-[80px] p-2 h-[80px] flex justify-center items-center">
                          <img
                            src={folderIcon}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <button
                          onClick={() => {
                            setSelectedFolder(list);
                            setFiles([]);
                          }}
                        >
                          <p className="text-left px-10 text-assoCol text-[16px]">
                            {list.toUpperCase()}
                          </p>
                        </button>
                      </div>
                    </div>
                  )
              )}
          </div>
          {(livello === null || livello === "" || livello === "ospite") && (
            <div className="flex w-full p-12 border pl-12 text-center justify-center align-center text-black flex-start flex-col items-center    ">
              Per poter visualizzare i file è necessario effettuare il login
              <div className=" h-full  ">
                <Link
                  to={{
                    pathname: "/login",
                    state: {
                      fromDashboard: true,
                    },
                  }}
                  className="cursor-pointer  rounded-xl duration-200  hover:scale-105 bg-assoCol p-2 mr-3 mt-10 flex items-center px-6   justify-center"
                >
                  <p className="text-zinc-100  pr-2">Accedi</p>
                  <FaUserCircle className="ico " color="white" />
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrandPage;

const link = [
  "LISTINI E CATALOGHI",
  "PROMO IN CORSO",
  "MANUALI E GUIDE",
  "BOLLETTINI TECNICI",
  "SCHEDE TECNICHE E BROCHURE",
  "SOFTWARE",
  "FIRMWARE",
  "VIDEO",
];
