import React from "react";

function PrivacyPolicy() {
    return (
      <div className="p-16 text-zinc-800 md:px-44">
        <h2 className="text-xl font-bold">PRIVACY POLICY - ASSO SICUREZZA</h2>
        <p className="py-3">
          Asso Sicurezza S.p.A. si impegna a proteggere la riservatezza dei dati
          personali degli utenti dei propri prodotti e servizi. La presente
          policy sintetizza i principi e le procedure adottate da Asso Sicurezza
          S.p.A. per la gestione dei dati personali e contiene la descrizione
          del tipo di dati da noi raccolti e il trattamento da noi operato sugli
          stessi. Gli eventuali dati personali forniti saranno trattati in base
          a quanto riportato nel presente documento. Il Regolamento UE 2016/679
          disciplina il trattamento dei dati personali riferiti a persone
          fisiche. Ai sensi di tale Regolamento, il trattamento dei dati
          personali forniti sarà improntato ai principi di correttezza, liceità,
          trasparenza e tutela dei Vostri dati personali e dei Vostri diritti.
        </p>
        <p className="py-3 leading-relaxed ">
          <b className="text-lg pb-2 "> 1. Titolare del trattamento dei dati</b>{" "}
          <br /> Il Titolare del trattamento è la società Asso Sicurezza S.p.A.
          (P.I. & Cod. Fisc.: 15190331007) con sede in Via Aurelia 1053, 00166 –
          Roma (RM); p.e.c.: assosicurezzaspa@pec.com.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2"> 2. Tipologia di dati trattati </b>
          <br /> Il titolare tratterà solo dati personali comuni. Nessun dato
          definito (ai sensi dell’Art. 9 del G.D.P.R.), particolare verrà
          trattato.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2">3. Conferimento dei dati</b> <br /> I dati
          personali richiesti sono suddivisi in due categorie: obbligatori e
          facoltativi. <br /> – Dati obbligatori: Ragione Sociale; Codice
          Fiscale; p.iva; Sede Legale e Sede Operativa; Tel., Fax ed e-mail
          azienda cliente.
          <br /> II conferimento dei dati obbligatori è strettamente funzionale
          all’esecuzione dei servizi richiesti dall’utente. Il rifiuto a
          fornirli in tutto o in parte può dar luogo all’impossibilità di
          fornire i servizi richiesti. <br /> – Dati facoltativi: Nome, Cognome,
          Professione, Cellulare e indirizzo mail Vs. Responsabile
          Acquisti/Referente. I dati facoltativi sono forniti a discrezione
          dell’utente e sono utilizzati da Asso Sicurezza S.p.A. al fine di
          offrire un servizio migliore.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2"> 4) Finalità del trattamento dei dati</b>{" "}
          <br /> I dati personali, saranno trattati per finalità strettamente
          connesse all’instaurazione e gestione del rapporto commerciale. <br />{" "}
          Il trattamento dati personali, saranno trattati per finalità
          strettamente connesse all’instaurazione e gestione del rapporto
          commerciale. Il trattamento è effettuato alle seguenti finalità:{" "}
          <br /> a) espletamento delle attività amministrativo-contabili,
          commerciali, fiscali, tributarie per l’adempimento degli obblighi
          contrattuali e imposte dalla legislazione vigente(quali ad esempio:
          inserimento del cliente in anagrafica aziendale, invio fatture,
          gestione ordini e consegne, servizi finanziari e di fidelizzazione
          cliente, etc.). Per espresso consenso, i dati potranno essere
          trattati, anche per le seguenti finalità: <br /> b) attività
          informative, editoriali e commerciali operate da Asso Sicurezza S.p.A.
          in settori connessi alla tipologia di prestazioni e servizi forniti,
          quali ad esempio newsletter, e-mail promozionali e/o sms, indagini,
          sondaggi, ricerche di mercato mediante interviste personali o
          telefoniche, questionari; <br /> c) elaborazione elettronica dei dati
          relativi ai prodotti e servizi utilizzati per l’analisi delle
          preferenze e propensioni dell’ interessato da utilizzare per scopi
          commerciali e promozionali; <br /> d) attività di natura tecnica,
          commerciale e formativa espletate da società terze (quali ad esempio:
          inviti a seminari, convegni, eventi, attività formative e
          professionali, invio di comunicazioni attraverso supporti
          informatizzati o cartacei, rilascio attestati da parte di enti
          formativi); <br /> e) messa a disposizione di linee guida e
          modulistica, invio di alert relativi alle scadenze di adempimenti di
          legge. Il conferimento dei dati personali per le finalità appena
          elencate è facoltativo, ma può risultare utile per migliorare i nostri
          servizi e aggiornarla su nuove attività di Suo interesse. Una Sua
          eventuale mancata prestazione del consenso del trattamento dei Suoi
          dati anche per questi fini non pregiudica in alcun modo la fornitura
          dei servizi da Lei richiesti. Le segnaliamo che se siete già nostri
          clienti, potremo inviarLe comunicazioni commerciali relative a servizi
          e prodotti analoghi a quelli di cui ha già usufruito, salvo Suo
          dissenso (art. 130 comma 4 del Regolamento UE n. 679/2016).
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2">
            5) Destinatari e i Modalità di trattamento dei dati
          </b>{" "}
          <br /> In relazione alle suddette finalità, i dati personali saranno
          trattati: <br /> – sia in formato cartaceo che con l’ausilio di
          procedure informatizzate e sistemi di comunicazione elettronica,
          protetti da idonee misure di sicurezza, nonchè con modalità e logiche
          coerenti con le finalità perseguite, nel rispetto di quanto previsto
          dall’art. 32 del GDPR 2016/679 e in ottemperanza a quanto previsto
          dall’art. 29 GDPR 2016/679;
          <br /> – dall’organizzazione del Titolare del trattamento, tramite
          dipendenti e collaboratori incaricati nell’ambito delle strutture
          preposte alle prestazioni; <br /> – da soggetti esterni a tale
          organizzazione facenti parte del gruppo Asso Sicurezza S.p.A. o da
          società di servizi, che potranno agire a seconda dei casi, come
          titolari autonomi del trattamento o come responsabili esterni del
          trattamento.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2"> 6) Periodo di conservazione</b> <br /> I
          dati obbligatori ai fini contrattuali e contabili sono conservati per
          il tempo necessario allo svolgimento del rapporto commerciale e
          contabile (per il conseguimento delle finalità per le quali sono
          raccolti e trattati) e secondo specifici criteri quali, ad esempio, le
          specifiche norme di legge contenute nella normativa fiscale e
          tributaria. I dati personali, dunque, verranno conservati e trattati
          anche successivamente alla conclusione dei contratti in essere al fine
          di adempiere agli obblighi di legge imposti. Il termine di
          conservazione extra-contrattuale decorre dalla fine dell’esercizio
          annuale nel quale sono state fatte le ultime iscrizioni, sono stati
          allestiti i documenti contabili ed è stata ricevuta/spedita la
          corrispondenza d’affari relativa al rapporto commerciale con lo
          specifico cliente. In ogni caso vengono applicate regole che
          impediscono la conservazione dei dati a tempo indeterminato e limitato
          quindi il tempo di conservazione nel rispetto del principio di
          minimizzazione del trattamento dei dati. I dati di chi non acquista o
          usufruisce di prodotti/servizi, pur avendo avuto un precedente
          contatto con dei rappresentanti dell’azienda, saranno immediatamente
          cancellati o trattati in forma anonima, ove la loro conservazione non
          risulti altrimenti giustificata, salvo che sia stato acquisito
          validamente il consenso informato degli interessati relativo ad una
          successiva attività di promozione commerciale o ricerca di mercato. I
          dati personali non saranno oggetto di diffusione e potranno essere
          comunicati (a soggetti terzi in qualità di autonomi titolari, operanti
          in Italia e all’estero, di cui Asso Sicurezza S.p.A. si avvale per lo
          svolgimento di compiti ed attività di natura anche tecnica ed
          organizzativa, strumentali alla fornitura dei servizi richiesti.
        </p>
        <p className="py-3 leading-relaxed	">
          L’elenco aggiornato di tali soggetti è consultabile presso la sede di
          Asso Sicurezza S.p.A., mediante richiesta da rivolgersi alla relativa
          “Funzione Privacy” anche via posta elettronica all’indirizzo e-mail
          info@assosicurezza.com
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2">
            7) Trasferimento dei dati verso paesi terzi
          </b>{" "}
          I dati personali possono essere trasferiti verso Paesi dell’Unione
          Europea e verso quei Paesi terzi che garantiscono un adeguato livello
          di protezione sostanzialmente equivalente a quello assicurato
          all’interno dell’Unione, sulla base di una decisione di adeguatezza
          della Commissione Europea.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2">8) Diritti dell’interessato</b> <br /> II
          Regolamento Le conferisce l’esercizio di specifici diritti, tra cui
          quelli di chiedere ai contitolari del trattamento: la conferma che sia
          in Regolamento Le conferisce l’esercizio di specifici diritti, tra cui
          quelli di chiedere ai contitolari del trattamento: la conferma che sia
          in corso un trattamento di Suoi dati personali e di ottenerne
          l’accesso (diritto di accesso, art. 15); la rettifica dei dati
          personali inesatti, o l’integrazione dei dati personali incompleti
          (diritto di rettifica, art. 16); la cancellazione dei dati stessi, se
          sussiste uno dei motivi previsti dal Regolamento (diritto all’oblio,
          art. 17); la limitazione del trattamento quando ricorre una delle
          ipotesi previste dal Regolamento (diritto di limitazione, art. 18); di
          ricevere in un formato strutturato, di uso comune e leggibile da
          dispositivo automatico i dati personali da Lei forniti al titolare e
          di trasmettere tali dati a un altro titolare del trattamento (diritto
          alla portabilità, si veda art. 20 del Regolamento).
        </p>
        <p className="py-3 leading-relaxed	">
          Lei ha, inoltre, il diritto di revocare il consenso al trattamento dei
          suoi dati, in qualsiasi momento, senza pregiudicare la liceità del
          trattamento basata sul consenso prestato prima della revoca e di
          opporsi in qualsiasi momento al trattamento per finalità di marketing
          (diritto di opposizione, si veda art. 21 del Regolamento). Per
          l’esercizio dei predetti diritti, per conoscere l’elenco dei
          Responsabili di trattamento e per qualsiasi questione attinente al
          trattamento dei dati personali, può rivolgersi al Titolare del
          trattamento dei dati personali: via posta elettronica all’indirizzo
          e-mail info@assosicurezza.com.
        </p>
          
            <h2 className="text-xl font-bold">EN: PRIVACY POLICY - ASSO SICUREZZA</h2>
        <p className="py-3">
        Asso Sicurezza S.p.A. is committed to protecting the confidentiality of personal data of users of its products and services. This policy summarizes the principles and procedures adopted by Asso Sicurezza S.p.A. for the management of personal data and contains a description of the type of data we collect and the processing we perform on it. Any personal data provided will be processed in accordance with this document. EU Regulation 2016/679 governs the processing of personal data referring to natural persons. Pursuant to this Regulation, the processing of personal data provided will be based on the principles of fairness, lawfulness, transparency and protection of your personal data and your rights.
        </p>
        <p className="py-3 leading-relaxed ">
          <b className="text-lg pb-2 "> Data controller </b>{" "}
          <br /> The Data Controller is the company Asso Sicurezza S.p.A. (P.I. & Cod. Fisc.: 15190331007) based in Via Aurelia 1053, 00166 - Rome (RM); p.e.c.: assosicurezzaspa@pec.com.
        </p>
        <p className="py-3 leading-relaxed	">
          <b className="text-lg pb-2"> Type of data processed </b>
          <br /> The owner will process only common personal data. No data defined (according to Art. 9 of the G.D.P.R.), particular will be processed.
        </p>
        <p className="py-3 leading-relaxed	">
          The requested personal data are divided into two categories: mandatory and optional.
- Compulsory data: Company Name; Tax Code; VAT number; Registered Office and Head Office; Tel., Fax and e-mail client company.
II provision of mandatory data is strictly functional to the execution of the services requested by the user. Refusal to provide them in whole or in part may result in the inability to provide the services requested.
- Optional data: First Name, Last Name, Profession, Mobile Phone and email address Vs. Purchasing Manager/Referent. Optional data are provided at the user's discretion and are used by Asso Sicurezza S.p.A. in order to provide a better service.
        </p>
        <p className="py-3 leading-relaxed	">
      Personal data, will be processed for purposes strictly related to the establishment and management of the business relationship.
Personal data processing, will be processed for purposes strictly related to the establishment and management of the business relationship. The processing is carried out for the following purposes:
(a) performance of administrative-accounting, commercial, fiscal, tax activities for the fulfillment of contractual obligations and imposed by current legislation(such as: entering the customer in the company registry, sending invoices, managing orders and deliveries, financial services and customer loyalty, etc.). By express consent, the data may be processed, also for the following purposes:
(b) informational, editorial and commercial activities operated by Asso Sicurezza S.p.A. in areas related to the type of services and benefits provided, such as newsletters, promotional e-mails and/or text messages, surveys, polls, market research through personal or telephone interviews, questionnaires;
c) electronic processing of data related to products and services used for the analysis of preferences and propensities of the 'data subject to be used for commercial and promotional purposes;
d) activities of a technical, commercial and educational nature carried out by third party companies (such as: invitations to seminars, conferences, events, training and professional activities, sending of communications through computerized or paper media, issuance of certificates by training institutions)
(e) making available guidelines and forms, sending alerts regarding deadlines of legal requirements. The provision of personal data for the purposes just listed is optional, but it may be useful to improve our services and update you on new activities of interest to you. Any failure on your part to consent to the processing of your data even for these purposes will in no way affect the provision of the services you have requested. We would like to point out that if you are already our customer, we may send you commercial communications related to services and products similar to those you have already used, unless you disagree (Article 130 paragraph 4 of EU Regulation No. 679/2016).

          
        </p>
        
        <div>
          Il sito utilizza: Google Tag Manager; Google Fonts; Google Analytics;
          Font Awesome.
        </div>
      </div>
    );
}

export default PrivacyPolicy;
