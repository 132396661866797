import React from "react";

const InfosData = ({ data, settore }) => {
    return (
        <div className="w-full flex flex-col space-y-2">
            <p className="text-lg capitalize font-bold ">
                Azienda :
                <span className="font-normal pl-2">{data.azienda}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Email :<span className="font-normal pl-2">{data.email}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Filiale:
                <span className="font-normal pl-2">{data.filiale}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Nome :<span className="font-normal pl-2">{data.nome}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Telefono :<span className="font-normal pl-2">{data.tel}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Agente :<span className="font-normal pl-2">{data.agente}</span>
            </p>
            <p className="text-lg capitalize font-bold ">
                Settore:
                {settore &&
                    settore.map((section, idx) => (
                        <span className="font-normal pl-2" key={idx}>
                            {section}
                        </span>
                    ))}
            </p>
            {data.upload && (
                <div>
                    <p className="capitalize text-lg font-bold">
                        Files :
                        <a
                            href={data.upload}
                            target="_blank"
                            className="font-normal text-sm ml-2 underline"
                            rel="noreferrer"
                        >
                            Clicca qui
                        </a>
                    </p>
                </div>
            )}
        </div>
    );
};

export default InfosData;
