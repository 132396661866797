import React, { Fragment, useRef } from "react";
import AntintrusioneData from "./Antintrusione/AntintrusioneData";
import AutomazioneData from "./AutomazioneData";
import InfosData from "./InfosData";
import TelefoniaData from "./TelefoniaData";
import TvccData from "./TVCC/TvccData";
import VidCitData from "./VidCitData";
import ReactToPrint from "react-to-print";

const PDF = ({ preventivo, submit }) => {
    let refToPrint = useRef();

    return (
        <Fragment>
            <div
                name="myform"
                className="fixed inset-0 z-50 w-full overflow-scroll h-full flex flex-col items-center justify-between bg-zinc-200 text-black "
            >
                <main className="w-11/12 my-8">
                    <div className="w-full h-full border-2 border-assoCol pt-8 px-4 ">
                        <div ref={(el) => (refToPrint = el)} className="m-5">
                            <InfosData
                                data={preventivo.infos}
                                settore={preventivo.Settore}
                            />
                            {preventivo.Settore.some(
                                (item) => item === "TVCC"
                            ) && <TvccData data={preventivo.tvcc} />}

                            {preventivo.Settore.some(
                                (section) => section === "Antintrusione"
                            ) && (
                                <AntintrusioneData
                                    data={preventivo.antintrusione}
                                />
                            )}
                            {preventivo.Settore.some(
                                (section) => section === "Antincendio"
                            ) && (
                                <div className="w-full my-8">
                                    <p className="text-xl uppercase font-bold my-6 border-b-2  border-assoCol">
                                        Antincendio
                                    </p>

                                    {preventivo.antincendio.contactMe && (
                                        <p className="text-black">
                                            Si prega di contattare il cliente :{" "}
                                            <span>
                                                {
                                                    preventivo.antincendio
                                                        .contactMe
                                                }
                                            </span>
                                        </p>
                                    )}
                                </div>
                            )}
                            {preventivo.Settore.some(
                                (section) => section === "Automazione"
                            ) && (
                                <AutomazioneData
                                    data={preventivo.automazione}
                                />
                            )}
                            {preventivo.Settore.some(
                                (section) => section === "Videocitofonia"
                            ) && (
                                <VidCitData data={preventivo.videocitofonia} />
                            )}
                            {preventivo.Settore.some(
                                (section) => section === "Telefonia"
                            ) && <TelefoniaData data={preventivo.telefonia} />}

                            {preventivo.Settore.some(
                                (section) => section === "Diffusione Sonora"
                            ) && (
                                <div className="w-full">
                                    <p className="text-xl  uppercase font-bold my-6 border-b-2  border-assoCol">
                                        Diffusione sonora
                                    </p>
                                    <p className="capitalize font-bold w-full my-4">
                                        richieste :
                                    </p>
                                    <div className="w-1/2">
                                        <p className="font-normal break-word">
                                            {
                                                preventivo.diffusione_sonora
                                                    .comment
                                            }
                                        </p>
                                    </div>
                                </div>
                            )}
                            {preventivo.Settore.some(
                                (section) => section === "Controllo Accessi"
                            ) && (
                                <Fragment>
                                    <p className="text-xl  uppercase font-bold my-6 border-b-2  border-assoCol">
                                        Controllo Accessi
                                    </p>
                                    <p className="capitalize font-bold w-full my-4">
                                        richieste :
                                        <span className="font-normal pl-2">
                                            {preventivo.accessi.comment}
                                        </span>
                                    </p>
                                </Fragment>
                            )}

                            {preventivo.Settore.some(
                                (section) => section === "Demotica"
                            ) && (
                                <Fragment>
                                    <p className="text-xl  uppercase font-bold my-6 border-b-2  border-assoCol">
                                        demotica
                                    </p>
                                    <p className="capitalize font-bold w-full my-4">
                                        Contattare il cliente :
                                        <span className="font-normal pl-2">
                                            {preventivo.demotica.contactMe}
                                        </span>
                                    </p>
                                </Fragment>
                            )}
                        </div>
                    </div>
                </main>
                <div className="w-full h-full flex justify-between items-end px-10 mb-6">
                    <label
                        htmlFor="pdf"
                        className="btn bg-assoCol text-white lg:text-2xl w-1/4 hover:bg-assoCol hover:bg-opacity-80"
                    >
                        chiudi
                    </label>
                    <ReactToPrint
                        trigger={() => (
                            <p className="btn bg-assoCol text-white lg:text-2xl w-1/4 hover:bg-assoCol hover:bg-opacity-80 ">
                                Stampa
                            </p>
                        )}
                        content={() => refToPrint}
                    ></ReactToPrint>

                    <label
                        type="submit"
                        onClick={submit}
                        className="btn bg-assoCol text-white lg:text-2xl w-1/4 hover:bg-assoCol hover:bg-opacity-80"
                    >
                        Invia
                    </label>
                </div>
            </div>
        </Fragment>
    );
};

export default PDF;
