import React from "react";

import { Link } from "react-router-dom";

function SoluzioniCard({ logo, name, link, descrizione }) {
    return (
        <Link to={`${link}`}>
            <div className=" w-11/12 md:h-[29vh] h-[22vh] cursor-pointer bg-opacity-80 flex justify-center  mr-1 glass text-white  items-center hover:border-2 border-gray duration-300 hover:scale-105   rounded-[20px]  shadow-xl hover:shadow-lg ">
                <div className="px-4 w-1/3">
                    <img src={logo} className="w-[60px]" alt="" />
                </div>{" "}
                <div className="w-full flex lg:h-3/6">
                    {" "}
                    <div className="pt-2 justify-center  flex flex-col  w-full">
                        {" "}
                        <div className="">
                            {" "}
                            <h4 className="w-[90%] font-semibold text-black text-md text-dark mb-1">
                                {name}
                            </h4>{" "}
                        </div>
                        <div>
                            {" "}
                            <p className="text-xs text-black pr-4 text-body-color">
                                {descrizione}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default SoluzioniCard;
