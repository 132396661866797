import React, { useState } from "react";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth, database } from "../../Utils/firebase_config";
import mansioni from "../../Utils/mansioni";

const FormGestioneAccount = ({ setLivello }) => {
  const [account, setAccount] = useState({
    ragione: "",
    nome: "",
    cognome: "",
    pi: "",
    cell: "",
    livello: "",
    email: "",
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const svuota = (e) => {
    e.preventDefault();
    setAccount({
      ragione: "",
      nome: "",
      cognome: "",
      pi: "",
      cell: "",
      livello: "",
      email: "",
      password: "",
      password2: "",
    });
  };

  const addToDb = async (e) => {
    e.preventDefault();

    if (
      !account.ragione ||
      !account.nome ||
      !account.cognome ||
      !account.pi ||
      !account.cell ||
      account.livello === "Seleziona" ||
      account.livello === "" ||
      !account.email ||
      !account.password ||
      !account.password2
    )
      return toast.error("Compila tutti i campi");

    if (account.password !== account.password2) {
      toast.error("la password non è uguale");
      return null;
    }
    setAccount({
      ragione: "",
      nome: "",
      cognome: "",
      pi: "",
      cell: "",
      livello: "",
      email: "",
      password: "",
      password2: "",
    });

    try {
      await createUserWithEmailAndPassword(
        auth,
        account.email,
        account.password
      );

      let user = auth.currentUser;
      const accountRef = collection(database, "user");
      await addDoc(accountRef, {
        ragione: account.ragione,
        nome: account.nome,
        cognome: account.cognome,
        pi: account.pi,
        cell: account.cell,
        email: account.email,
        livello: account.livello,
        createdAt: Timestamp.now().toDate().toDateString(),
        uid: user.uid,
        accepted: false,
      });
      await sendEmailVerification(auth.currentUser);
      toast("Registrazione effettuata");
      signOut(auth);
      setLivello("Admin");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("Email Already in Use");
      } else {
        toast.error("C'è qualcosa che non va...");
      }
    }
  };
  return (
    <div className="w-full h-full bg-zinc-100 text-zinc-800 flex flex-col sm:justify-center items-center pt-6 sm:pt-0 rounded-b-xl border-x-assoCol border-b-assoCol border-2 border-opacity-50 shadow-2xl">
      <form className="w-5/6 flex flex-col items-center justify-center pt-10">
        <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="ragione">
              Ragione Sociale
            </label>
            <input
              id="ragione"
              type="text"
              name="ragione"
              value={account.ragione}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="nome">
              Nome
            </label>
            <input
              id="nome"
              type="text"
              name="nome"
              value={account.nome}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="cognome">
              Cognome
            </label>
            <input
              id="cognome"
              type="text"
              name="cognome"
              value={account.cognome}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="pi">
              Partita Iva
            </label>
            <input
              id="pi"
              type="text"
              name="pi"
              value={account.pi}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="cell">
              Cellulare
            </label>
            <input
              id="cel"
              type="text"
              name="cell"
              value={account.cell}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold mb-4">
            <label className="block mb-1" htmlFor="cell">
              Livello
            </label>
            <select
              required
              className="select py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full dark:bg-white"
              name="livello"
              onChange={handleChange}
              value={account.livello}
            >
              {mansioni.map((list, idx) => (
                <option
                  key={idx}
                  value={list}
                  className="bg-assoBg selection:bg-assoCol"
                >
                  {list}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-start items-center md:space-x-4">
          <div className="w-full md:w-1/3 font-bold my-4">
            <label className="block mb-1" htmlFor="email">
              Indirizzo E-mail
            </label>
            <input
              type="text"
              name="email"
              value={account.email}
              onChange={handleChange}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold">
            <label className="block mb-1">Password</label>
            <input
              type="password"
              name="password"
              value={account.password}
              onChange={handleChange}
              placeholder="min 6 caratteri "
              minLength="6"
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="w-full md:w-1/3 font-bold">
            <label className="block mb-1">Ripeti Password</label>
            <input
              type="password"
              name="password2"
              value={account.password2}
              onChange={handleChange}
              placeholder="min 6 caratteri "
              minLength="6"
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
        </div>

        <div className="w-full my-8 flex md:flex-row justify-end items-center space-x-4">
          <button
            onClick={svuota}
            className=" uppercase bg-assoCol glass hover:bg-assoCol inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold text-white  active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition"
          >
            Svuota
          </button>
          {!account.ragione ||
          !account.nome ||
          !account.cognome ||
          !account.pi ||
          !account.cell ||
          account.livello === "Seleziona" ||
          account.livello === "" ||
          !account.email ||
          !account.password ||
          !account.password2 ? (
            <button className=" uppercase bg-assoCol glass hover:bg-assoCol inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold text-white  active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition">
              Compila Tutti i campi
            </button>
          ) : (
            <button
              onClick={addToDb}
              className=" uppercase bg-assoCol glass hover:bg-assoCol inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold text-white  active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition"
            >
              Aggiungi
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormGestioneAccount;
