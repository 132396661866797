import React from "react";

import { CardEventi } from "../../UI/Card/Card";
import { motion } from "framer-motion";

const CardSingleContainer = ({ data, user, isNews }) => {
  const newData = data.slice(0, 2);
  return (
    <motion.div
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.4 }}
      className="justify-center gap-2 flex flex-col lg:flex-row"
    >
      {newData.map((item, index) => {
        return (
          <CardEventi
            isNews={isNews}
            user={user ? user : ""}
            key={item.id}
            id={item.id}
            nome={item.nome}
            image={item.image}
            luogo={item.luogo}
            date={item.date}
            descrizione={item.descrizione}
            linkEvento={item.linkEvento}
            maxGuest={item.maxGuest}
            partecipanti={item.partecipanti}
            totPartecipanti={item.totPartecipanti}
            organizzatore={item.email}
          />
        );
      })}
    </motion.div>
  );
};

export default CardSingleContainer;
