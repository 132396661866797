
import React, { Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GoToTop(props) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <Fragment>{props.children}</Fragment>;
}