import React from "react";
import ContattiData from "./ContattiData";
import EsterniData from "./EsterniData";
import InterniData from "./Internidata";
import TastiereData from "./TastiereData";
import CaviData from "./CaviData";

const AntintrusioneData = ({ data }) => {
  return (
    <div className="w-full my-5">
      <p className="text-xl  uppercase font-bold">Antintrusione</p>
      <div className="divider mt-0 after:bg-assoCol before:bg-assoCol"></div>

      <div className="w-full flex flex-col space-y-2 ">
        <p className="capitalize  font-bold">
          Struttura da proteggere:
          <span className="font-normal pl-2">{data.struttura}</span>
        </p>
        <div className="w-full space-y-2 ">
          <p className="capitalize  font-bold">
            Tipo di impianto :
            <span className="font-normal pl-2">{data.centrale}</span>
          </p>
        </div>
        <p className="capitalize font-bold ">
          Marchio preferito:
          <span className="font-normal pl-2">{data.marchio}</span>
        </p>
      </div>
      <p className="capitalize font-bold  mt-2">
        numero di zone da proteggere:
        <span className="font-normal pl-2">{data.zone}</span>
      </p>
      {data.contatti && <ContattiData data={data} />}
      {data.rivelatori_interni && (
        <InterniData data={data.rivelatori_interni} />
      )}
      {data.rivelatori_esterni && (
        <EsterniData data={data.rivelatori_esterni} />
      )}

      {data.perimetrale && (
        <div className="py-4">
          <p className="text-lg uppercase border-b-2 text-justify underline text-assoCol font-bold my-2 ">
            Perimetrale
          </p>
          <p className="overflow-hidden font-light">{data.perimetrale}</p>
        </div>
      )}

      <p className="font-bold mt-2 underline text-assoCol uppercase">
        organi di comando
      </p>
      <div className="flex flex-col space-y-2 ">
        {data.organi.map((list, i) => (
          <li key={i} className="mx-4">
            {list}
          </li>
        ))}
      </div>
      {data.tastiere && (
        <div>
          <p className="font-bold mt-4 underline text-assoCol uppercase">
            Tastiere
          </p>
          <TastiereData data={data.tastiere} />
        </div>
      )}
      {data.telecomando && (
        <p className="font-bold mt-4 underline text-assoCol uppercase">
          num telecomandi :
          <span className="font-normal pl-2 text-black">
            {data.telecomando}
          </span>
        </p>
      )}
      {/* {data.disp_sonori && <DispSonoriData data={data.disp_sonori} />} */}
      <p className="font-bold mt-4 underline text-assoCol uppercase">
        moduli di comunicazione
      </p>
      <div className="flex flex-col  mt-4">
        {data.moduli &&
          data.moduli.map((list, i) => (
            <li key={i} className="mx-4">
              {list}
            </li>
          ))}
      </div>
      <p className="font-bold mt-4 underline text-assoCol uppercase">
        batterie
      </p>
      <div className="flex flex-col space-y-2 mt-4">
        {data.batterie &&
          data.batterie.map((el, i) => (
            <li key={i} className="mx-4 capitalize">
              {el}
            </li>
          ))}
      </div>
      {data.cavi && <CaviData data={data.cavi} />}
    </div>
  );
};

export default AntintrusioneData;
