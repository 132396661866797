import React from "react";

const Gestione = ({ title, children }) => {
  return (
    <div className="w-full h-full text-zinc-800 flex flex-col items-center justify-center">
      <h2 className="w-11/12 bg-assoCol mt-10 mb-4 py-4 rounded-t-xl text-center text-4xl font-extrabold uppercase py-2 glass hover:bg-assoCol">
        Gestione {title}
      </h2>
      {children}
    </div>
  );
};

export default Gestione;
