import { Text } from "@chakra-ui/react";

import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import DelButtonAccount from "../../components/UI/Button/DelButtonAccount";

const User = ({ user }) => {
  return (
    <div>
      <div className="relative max-w-sm mx-auto md:max-w-2xl  min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-xl mt-16">
        <div className="px-6 space-y-10">
          <Text textAlign={"center"} w="full" borderBottomWidth={"2px"}>
            <div className="text-lg mt-0 mb-2 lg:my-6 text-assoCol opacity-75 font-bold uppercase">
              <i className="fas fa-map-marker-alt mr-2  opacity-75"></i>
              Informazioni sul tuo profilo
            </div>
          </Text>
          {user &&
            user.map((item, i) => (
              <div key={i} className="flex flex-col">
                <div className=" mt-2">
                  <h3 className="md:text-2xl text-slate-700 font-bold leading-normal mb-1">
                    {item.email ? item.email : ""}
                  </h3>
                  <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
                    Indirizzo E-mail{" "}
                  </div>
                </div>
                <div className=" mt-2">
                  <h3 className="md:text-2xl text-slate-700 font-bold leading-normal mb-1">
                    {item.nome ? item.nome : ""}{" "}
                    {item.cognome ? item.cognome : ""}
                  </h3>
                  <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
                    Nome Completo
                  </div>
                </div>
                <div className=" mt-2">
                  <h3 className="md:text-2xl text-slate-700 font-bold leading-normal mb-1">
                    {item.ragione ? item.ragione : ""}
                  </h3>
                  <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
                    Nome / Ragione Sociale
                  </div>
                </div>
                <div className=" mt-2">
                  <h3 className="md:text-2xl text-slate-700 font-bold leading-normal mb-1">
                    {item.livello ? item.livello : ""}
                  </h3>
                  <div className="text-xs mt-0 mb-2 text-slate-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-slate-400 opacity-75"></i>
                    Livello
                  </div>
                </div>

                <div className="w-full mt-6 py-2 border-t border-slate-200 flex justify-between items-center ">
                  <div className="font-bold  w-1/2">
                    {item.accepted ? (
                      <div className="text-green-500">Account confermato</div>
                    ) : (
                      <div className="text-red-500">
                        Account non confermato
                        <span>
                          <div
                            class="tooltip"
                            data-tip="si prega di attendere la nostra conferma"
                          >
                            <AiOutlineInfoCircle />
                          </div>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end items-center text-red-500 w-1/2">
                    <p className="w-full text-right pr-4">Elimina Account </p>
                    <div>
                      <DelButtonAccount type="user" info={item} />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default User;
