import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../index.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { database } from "../../../Utils/firebase_config";
import { Autoplay, Navigation, Pagination } from "swiper";

export default function Slider() {
  const [slider, setSlider] = useState([]);

  const getImg = () => {
    const eventRef = collection(database, "carosello");
    const q = query(eventRef, orderBy("createdAt", "desc"));
    onSnapshot(q, (snapshot) => {
      const images = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSlider(images);
    });
  };

  useEffect(() => {
    getImg();
  }, []);

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {slider.map((slider, idx) => (
        <SwiperSlide className="w-full flex flex-col  z-0 " key={idx}>
          <img
            src={slider.link}
            className="h-full w-full md:object-cover"
            alt=""
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
