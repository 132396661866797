import antincendio from "../../assets/images/antincendio.png";
import intrusione from "../../assets/images/intrusione.png";
import automazion from "../../assets/images/automazion.png";
import network from "../../assets/images/network.png";
import telefonia from "../../assets/images/telefonia.png";
import videosorveglianza from "../../assets/images/videosorveglianza.png";
import cavi from "../../assets/images/cavi.png";
import diffusioneSonora from "../../assets/images/diffusionesonora.png";
import accumulatore from "../../assets/images/accumulatore.png";
import accessi from "../../assets/images/controlloaccessi.png";

export const soluzioniList = [
  {
    path: "/soluzioni/videosorveglianza",
    title: "Videosorveglianza",
    logo: videosorveglianza,
    descrizione: "Soluzione analogica e IP che integrano tecnologie avanzate",
  },
  {
    path: "/soluzioni/antintrusione",
    title: "Antintrusione / Antifurto",
    logo: intrusione,
    descrizione:
      "SISTEMI CABLATI E WIRELESS per la rivelazione e prevenzione delle effrazioni",
  },
  {
    path: "/soluzioni/antincendio",
    title: "Antincendio / Rilevamento Gas / Spegnimento",
    logo: antincendio,
    descrizione:
      " Sistemi analogici e convenzionare per la rivelazione fumi e gas",
  },
  {
    path: "/soluzioni/diffusione",
    title: "Diffusione Sonora / Sistemi Evac",
    logo: diffusioneSonora,
    descrizione: "Audio, PA e EVAC",
  },
  {
    path: "/soluzioni/accessi",
    title: "Controllo Accessi",
    logo: accessi,
    descrizione:
      "Sistemi, piattaforme e dispositivi dedicati alla gestione semplificata dei varchi di accesso e gestione del personale",
  },
  {
    path: "/soluzioni/telefonia",
    title: "Telefonia / Videocitofonia",
    logo: telefonia,
    descrizione:
      "Soluzioni analogiche e IP per la comunicazione applicabili in ambienti residenziali, industriali e condominiali",
  },
  {
    path: "/soluzioni/networking",
    title: "Networking",
    logo: network,
    descrizione:
      "dispositivi attivi e passivi per la trasmissione dati cablata e wi-fi",
  },
  {
    path: "/soluzioni/automazione",
    title: "Automazione",
    logo: automazion,
    descrizione:
      "Automazioni per qualsiasi tipo di varco e soluzioni per avvolgibili.",
  },
  {
    path: "/soluzioni/accumulatore",
    title: "Accumulatori / Gruppi di Continuità",
    logo: accumulatore,
    descrizione: "Batterie e gruppi di continuità per tutte le esigenze.",
  },
  {
    path: "/soluzioni/cavi",
    title: "Cavi",
    logo: cavi,
    descrizione:
      "Cavi e fibra ottica per tutte le soluzioni del settore sicurezza.",
  },
];
