import React from "react";

const VidCitData = ({ data }) => {
  return (
    <div className="w-full flex flex-col mt-6">
      <p className="text-xl uppercase font-bold my-6 border-b-2  border-assoCol">
        Videocitofonia
      </p>

      <div className="flex flex-col w-full space-y-2">
        <p className="capitalize font-bold">
          marchio preferito :
          <span className="font-normal pl-2">{data.marchio}</span>
        </p>
        <p className="capitalize font-bold">
          num. postazione esterne :
          <span className="font-normal pl-2">{data.num_post_est}</span>
        </p>
        <p className="capitalize font-bold">
          bussate differenti :
          <span className="font-normal pl-2">{data.bussate_diff}</span>
        </p>
        <p className="capitalize font-bold">
          tipo di installazione :
          <span className="font-normal pl-2">{data.tipo_inst}</span>
        </p>
        <p className="capitalize font-bold w-full mb-4">
          note :<span className="font-normal pl-2">{data.comment}</span>
        </p>

        <p className="capitalize font-bold">
          num. postazione interne :
          <span className="font-normal pl-2">{data.num_post_int}</span>
        </p>
      </div>
    </div>
  );
};

export default VidCitData;
