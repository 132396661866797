import React from "react";

const InterniData = ({ data }) => {
  return (
    <div className="w-full flex flex-col rounded  py-2 my-2 ">
      <p className="font-bold mt-2 underline text-assoCol uppercase">
        Rivelatori Interni
      </p>

      {data.map((interni, i) => (
        <p key={i}>{interni}</p>
      ))}
    </div>
  );
};

export default InterniData;
