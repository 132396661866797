import React from "react";

const Tastiere = ({ handler }) => {
    return (
        <div className="w-11/12 h-full flex flex-col ml-4 mt-10">
            <span className="text-zinc-700 font-semibold" htmlFor="username">
                Di quante Tastiere hai bisogno? *
            </span>
            <div className="w-full flex flex-col lg:flex-row mt-4 flex-wrap">
                {tastiere.map((option, index) => (
                    <label
                        className="cursor-pointer w-full flex items-center mb-1"
                        key={index}
                    >
                        <div className="w-4/5">
                            <span className="text-zinc-800 ml-4">
                                {option.title}
                            </span>
                        </div>
                        <div className="w-1/5 flex">
                            <span className="text-zinc-800 mx-4">Qnt</span>
                            <input
                                name={option.id}
                                title={option.title}
                                onMouseLeave={handler}
                                type="text"
                                className="w-10 h-5 ring-opacity-0 "
                            />
                        </div>
                    </label>
                ))}
            </div>
        </div>
    );
};

export default Tastiere;

const tastiere = [
    {
        id: "LCD",
        title: " Tastiere LCD",
    },
    {
        id: "LCDinseritore",
        title: " Tastiere LCD + inseritore",
    },
    {
        id: "TouchScreen",
        title: " Tastiere TouchScreen",
    },
];
