import React from "react";
import DeletecCaro from "../Button/DelCaro";

const CardCarosello = ({ img }) => {
    return (
      <div className="card w-96 h-52 bg-base-100 bg-opacity-10 shadow-xl image-full m-2">
        <figure>
          <img src={img.link} alt="card carosello" />
        </figure>
        <div className="card-body">
          <div className=" w-16 cursor-pointer flex items-center justify-center rounded-lg text-black">
            <DeletecCaro
              info={img}
              type="carosello"
              title="Sei sicuro di eliminare l'immagine ?"
              wdt="text-assoCol w-16"
            />
          </div>
        </div>
      </div>
    );
};

export default CardCarosello;
