import React from "react";
import DrawerExample from "../UI/Drawer/DrawerChakra";

import AppNavbar from "../UI/Navbar/AppNavbar";

const Header = ({ livello, brandsState }) => {
  return (
    <div className="w-full">
      <div className="w-full flex bg-stone-800  items-center">
        <div className="xl:hidden w-full top-0 h-[47px] z-50">
          <DrawerExample brandsState={brandsState} livello={livello} />
        </div>

        <div className="xl:w-full flex">
          <AppNavbar livello={livello} brandsState={brandsState} />
        </div>
      </div>
    </div>
  );
};

export default Header;
