import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom";

const Cookie = () => {
    return (
        <CookieConsent
            className="bg-assoCol"
            style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "#fd3e08",
                opacity: 0.95,
            }}
            buttonStyle={{
                padding: "10px 20px 10px 20px",
                backgroundColor: "white",
                borderRadius: "20px",
                paddingHorizontal: "10px",
                boxShadow: "2px 2px 2px 2",
            }}
            debug={true}
            buttonText="Ok, ho capito"
        >
            Questo sito utilizza Cookies.&nbsp;
            <b className="hidden md:inline-block">
                Puoi leggere la nostra Privacy Policy
                <NavLink
                    className="underline pl-[7px] text-zinc-200"
                    to="/privacypolicy"
                >
                    qui
                </NavLink>
                .
            </b>
        </CookieConsent>
    );
};

export default Cookie;
