import React,{ Fragment } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { deleteDoc, doc } from "firebase/firestore";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { database } from "../../../Utils/firebase_config";
import { toast } from "react-toastify";

const DeletecCaro = ({ info, type, title }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const handleDelete = async () => {
        try {
            await deleteDoc(doc(database, type, info.id));

            toast.success("Eliminato");
        } catch (err) {
            toast.error("OPS! Qualcosa è andato storto, riprova");
        }
    };

    return (
        <Fragment>
            <Button
                onClick={onOpen}
                size="md"
                height="48px"
                width="2xl"
                border="2px"
                margin="4px"
                borderColor="orange.500"
            >
                <RiDeleteBin2Fill className="w-4 h-full rounded py-2 z-10" />
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>ATTENZIONE!!!</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <div className="text-black">{title}</div>
                    </ModalBody>
                    <ModalFooter className="space-x-4">
                        <Button
                            onClick={handleDelete}
                            backgroundColor="#ff5723"
                            color={"white"}
                            textTransform={"uppercase"}
                            _hover={{ color: "black" }}
                        >
                            Elimina
                        </Button>
                        <Button
                            onClick={onClose}
                            backgroundColor="#ff5723"
                            color={"white"}
                            _hover={{ color: "black" }}
                            textTransform={"uppercase"}
                        >
                            Annulla
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Fragment>
    );
};
export default DeletecCaro;
