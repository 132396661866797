const mansioni = [
  "Seleziona",
  "Dipendente Livello 1",
  "Dipendente Livello 2",
  "Rivenditore",
  "Installatore 1",
  "Installatore 2",
  "Admin",
];

export default mansioni;