import React, { useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { AiFillEdit } from "react-icons/ai";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { Input } from "../Input/InputEdit";
import JoditEditor from "jodit-react";

const EditNews = ({ info }) => {
    const [edit, setEdit] = useState({
        ...info,
    });
    const editor = useRef(null);
    const [content, setContent] = useState("");
    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
    };

    const handleChange = (e) => {
        setEdit({
            ...edit,
            [e.target.name]: e.target.value,
            descrizione: content,
        });
    };

    const handleEdit = async () => {
        const ref = doc(database, "novità", info.id);
        try {
            await updateDoc(ref, {
                nome: edit.nome,
                descrizione: content,
                image: edit.image,
            });
            toast.success("Aggiornato");
        } catch (err) {
            toast.error("Oops!Riprova");
        }
    };

    const index = "news" + edit.id;
    return (
      <div className="w-full h-full flex justify-center items-center ">
        <label htmlFor={index} className="modal-button">
          <AiFillEdit
            classname="w-full h-full rounded 
                          hover:bg-assoCol"
          />
        </label>
        <input type="checkbox" id={index} className="modal-toggle" />
        <div className="modal bg-white p-8 w-full">
          <div className="w-2/3 rounded-xl px-8 py-1 dark:bg-zinc-100">
            <form className="w-full flex flex-col items-center justify-center pt-10">
              <div className="w-full flex flex-col justify-start items-center">
                <div className="w-full flex flex-col ">
                  <div className="w-full flex justify-between">
                    <Input
                      type="text"
                      name="nome"
                      title="Titolo novità"
                      value={edit.nome}
                      handler={handleChange}
                      required
                    />
                    <Input
                      type="text"
                      name="image"
                      value={edit.image}
                      handler={handleChange}
                      title="Inserisci link immagine"
                      required
                    />
                    <img src={edit.image} alt="news" className="w-32" />
                  </div>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                  />
                </div>
              </div>
            </form>
            <div className="w-full flex justify-between mt-4 space-x-1">
              <label
                for={index}
                className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded 
                          hover:bg-assoCol"
                onClick={handleEdit}
              >
                Aggiorna
              </label>
              <label
                className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded
                          hover:bg-assoCol"
                for={index}
              >
                Chudi
              </label>
            </div>
          </div>
        </div>
      </div>
    );
};

export default EditNews;
