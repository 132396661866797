import React from "react";
const Hero = ({ srcImage, srcImageMobile }) => {
  return (
    <div>
      <div className="w-full hidden lg:flex justify-center items-center">
        <img
          className="w-full h-full object-contain object-center"
          alt="event pic"
          src={srcImage}
        />
      </div>
      <div className="w-full  flex lg:hidden justify-center items-center">
        <img
          className="w-full h-full object-contain object-center"
          alt="event pic"
          src={srcImageMobile}
        />
      </div>
    </div>
  );
};

export default Hero;
