import React, { Fragment, useState } from "react";
import { MdOutlineLocationOn } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";
import Rubrica from "./Rubrica";
import Raggiungici from "./Raggiungici";

const Store = ({ sk, city, map, imgs, indirizzo, tel, orari, filiale }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [open, setOpen] = useState(false);
  return (
    <div
      key={sk}
      className="w-full h-[20vh]  flex justify-center items-center mx-auto my-auto"
    >
      <div
        className=" w-full border-2 cursor-pointer h-2/3  flex justify-center hover:scale-105 duration-200 items-center bg-assoCol glass py-4 rounded-lg 
                            lg:hover:scale-100 hover:bg-assoCol
                            lg:w-1/2"
        onClick={() => setOpen(!open)}
      >
        <div
          className="w-full h-1/3 font-bold flex items-center justify-center rounded-lg 
                   lg:h-full lg:mx-0 lg:justify-center"
        >
          <div className="flex items-center w-1/4 justify-center">
            <MdOutlineLocationOn className="text-center text-4xl text-assoBg " />
          </div>
          <div className=" w-3/5 space-y-2">
            <h1
              className="text-assoBg uppercase text-2xl 
                                       lg:text-md 
                                       xl:text-xl"
            >
              {city}
            </h1>
          </div>
        </div>
      </div>
      <div className="divider"></div>
      {open && (
        <div className=" h-[100vh] fixed inset-0 z-50 overflow-scroll w-screen  bg-zinc-400 flex flex-col items-center justify-center bg-opacity-90">
          <div className="w-6/6 h-[100%] border-2 pt-0   absolute top-10  lg:w-5/6  card  flex flex-col items-center justify-center bg-white/60 backdrop-blur-lg my-3 p-8 md:pt-32 lg:flex-row lg:space-y-0">
            <div className="w-full h-full flex flex-col justify-center space-y-6 text-sm ">
              <div className="flex  items-center justify-center flex-col space-y-10 lg:space-y-0 lg:flex-row">
                <div className="w-full flex flex-col lg:w-1/2 h-full text-[14px] md:text-[16px] text-lg">
                  <div className="w-full flex justify-between items-center border-b-2 pb-4 border-assoCol">
                    <h2
                      className="card-title   text-2xl md:text-4xl text-[#2a2a2a]  text-center 
                                            lg:text-left"
                    >
                      {city}
                    </h2>
                  </div>{" "}
                  <div className="pt-4">
                    <p className="">{indirizzo}</p>
                    <a href={`tel:+${tel}`}>
                      <p
                        className="badge badge-outline w-3/3  glass text-[11px] uppercase   mt-4 
                         hover:bg-assoCol hover:text-white hover:scale-105 mb-6 text-black "
                      >
                        Chiama: {tel}
                      </p>
                    </a>

                    <p>
                      Orari: <br /> {orari}
                    </p>
                  </div>
                  <div
                    className="w-full h-full flex justify-start 
                                  "
                  >
                    <Rubrica filiale={filiale} />
                  </div>
                  <div className="divider w-12/12"></div>
                  <div className="mt-5 ml-10 lg:ml-0">
                    {map && (
                      <iframe
                        title="maps"
                        src={map}
                        frameBorder="0"
                        className="border-0 rounded-md w-11/12 h-full"
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                      />
                    )}
                  </div>
                </div>
                <div className="w-full hidden md:block px-4 h-full lg:w-1/2">
                  {imgs && (
                    <Fragment>
                      <Swiper
                        style={{
                          "--swiper-navigation-color": "#fff",
                          "--swiper-pagination-color": "#fff",
                        }}
                        loop={true}
                        spaceBetween={10}
                        navigation={true}
                        thumbs={{
                          swiper: thumbsSwiper,
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                      >
                        {imgs &&
                          imgs.map((img, index) => (
                            <SwiperSlide
                              key={index}
                              className="w-full flex justify-center items-center"
                            >
                              <div className=" h-[340px]">
                                <img
                                  src={img}
                                  alt="filiale "
                                  className="object-contain w-full h-full"
                                />
                              </div>
                            </SwiperSlide>
                          ))}
                      </Swiper>
                      <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={10}
                        slidesPerView={7}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper"
                      >
                        {imgs &&
                          imgs.map((list, idx) => (
                            <SwiperSlide
                              key={idx}
                              style={{
                                height: "8rem",
                                width: "7rem",
                                object: "contain",
                              }}
                            >
                              <img
                                className="w-full  mt-1 h-full"
                                src={list}
                                alt="filiale"
                              />
                            </SwiperSlide>
                          ))}
                      </Swiper>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-8">
                <div className="w-full md:w-1/2">
                  <Raggiungici map={filiale.map} />
                  <div>
                    <button
                      onClick={() => setOpen(!open)}
                      className="btn text-assoCol w-full glass text-[12px] mt-3  md:text-lg 
                                           hover:bg-assoCol hover:opacity-80"
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
