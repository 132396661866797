import React, { useEffect, useState } from "react";
import Gestione from "../../components/Layout/Gestione";
import FormContatti from "../../components/Forms/FormContatti";
import FormFiliale from "../../components/Forms/FormFiliali";
import Sedi from "../../components/Organism/Contatti/Sedi";
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { database } from "../../Utils/firebase_config";

const GestioneContatti = () => {
    const [rubrica, setRubrica] = useState([]);

    useEffect(() => {
        const rubRef = collection(database, "filiali");
        const q = query(rubRef, orderBy("id_filiale", "asc"));
        onSnapshot(q, (snapshot) => {
            const sedi = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setRubrica(sedi);
        });
    }, []);
    return (
        <Gestione title="contatti">
            <div className="w-11/12 h-full flex flex-col  mb-20 border-x-assoCol border-b-assoCol border-2 border-opacity-50 rounded-b-xl shadow-2xl">
                <div className="w-full flex flex-col xl:flex-row">
                    <div className="w-full xl:w-1/2">
                        <FormFiliale />
                    </div>
                    <div className="w-full xl:w-1/2">
                        <FormContatti />
                    </div>
                </div>
                <div className="w-full  flex justify-center items-center "></div>
            </div>
            <div className="w-11/12 h-full ">
                <Sedi rubrica={rubrica} />
            </div>
        </Gestione>
    );
};

export default GestioneContatti;
