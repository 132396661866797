import React, { useState, useRef } from "react";
import { addDoc, collection } from "firebase/firestore";

import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import { Input } from "../UI/Input/InputEdit";

import JoditEditor from "jodit-react";

const FormNews = () => {
    const editor = useRef(null);
    const [content, setContent] = useState(null);
    const [selected, setSelected] = useState(false);

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
    };
    const [news, setNews] = useState({
        nome: "",
        descrizione: "",
        image: "",
        selected: false,
    });

    const handleChange = (e) => {
        if (e.target.type === "checkbox") {
            setSelected(!selected);
        }
        setNews({
            ...news,
            [e.target.name]: e.target.value,
            descrizione: content,
            selected: selected,
        });
    };
    const date = new Date();
    const formatDate = new Intl.DateTimeFormat("it-IT").format(date);

    const submit = (e) => {
        e.preventDefault();
        if (!news.nome || !news.image) return null;

        const eventRef = collection(database, "novità");
        addDoc(eventRef, {
            nome: news.nome,
            date: formatDate.toString(),
            descrizione: content,
            image: news.image,
            selected: selected,
        })
            .then(() => {
                toast.success("Novità inserita");
            })
            .catch((err) => {
                toast.error("Qualcosa è andato storto", err.message);
            });
        setNews({
            nome: "",
            descrizione: "",
            image: "",
            selected: false,
        });
    };

    return (
        <div className="w-full h-full bg-zinc-100 text-zinc-800 flex flex-col sm:justify-center items-center pt-6 sm:pt-0 rounded-b-xl border-x-assoCol border-b-assoCol border-2 border-opacity-50 shadow-2xl">
            <form className="w-5/6 flex flex-col items-center  pt-10">
                <div className="w-full flex flex-col justify-start items-center py-4">
                    <div className="w-2/3 flex flex-col space-y-4">
                        <div className="w-full flex space-x-4">
                            <Input
                                type="text"
                                name="nome"
                                title="Titolo novità"
                                value={news.nome}
                                handler={handleChange}
                                required
                            />
                            <Input
                                type="text"
                                name="image"
                                value={news.image}
                                handler={handleChange}
                                title="Inserisci link immagine"
                                required
                            />
                            {news.image && (
                                <img
                                    src={news.image}
                                    alt="news"
                                    className="w-32"
                                />
                            )}
                        </div>
                        <div className="text-black">
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            />
                        </div>
                        <div className="form-control w-full ">
                            <label className="cursor-pointer label w-1/5">
                                <span className="label-text text-zinc-800 uppercase font-bold">
                                    Aggiungi alla Home
                                </span>
                                <input
                                    name="selected"
                                    type="checkbox"
                                    value={selected}
                                    className="checkbox border-assoCol"
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                    </div>
                    {!news.nome || !news.image ? (
                        <button
                            disabled
                            className="btn glass w-2/3  bg-assoCol hover:bg-assoCol rounded-lg text-white px-5 py-2.5 text-center my-2"
                        >
                            compila tutti i campi
                        </button>
                    ) : (
                        <button
                            type="submit"
                            onClick={submit}
                            className="btn glass w-2/3  bg-assoCol hover:bg-assoCol rounded-lg text-white px-5 py-2.5 text-center my-2"
                        >
                            aggiungi
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default FormNews;
