import React from "react";

const ControlloAccessi = ({ handleChange, accessi }) => {
    return (
        <div className=" w-full mt-24 bg-assoBg text-zinc-800 pb-8">
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="form-control w-full flex-row ml-4">
                <div className="w-1/2">
                    <p className="text-xl text-black">
                        Richiesta Offerta Controllo Accessi
                    </p>
                </div>
            </div>
            <div className="my-6 mx-4">
                <p>Scrivi brevemente di cosa hai bisogno</p>
                <textarea
                    name="comment"
                    onChange={handleChange}
                    value={accessi.comment}
                    className="textarea w-full bg-orange-100 my-4"
                />
            </div>
        </div>
    );
};

export default ControlloAccessi;
