import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { BiLogInCircle, BiLogOutCircle, BiUser } from "react-icons/bi";
import { signOut } from "firebase/auth";
import { auth } from "../../../Utils/firebase_config";
const AccediButton = ({ livello }) => {
  const location = useNavigate();
  const scollega = () => {
    signOut(auth);
    location("/");
  };
  const currentUser = auth.currentUser;

  return (
    <div className="dropdown cursor-pointer  dropdown-hover dropdown-left">
      <label
        tabIndex="0"
        className="btn hover:bg-transparent hover:text-assoCol w-full focus:bg-transparent  border-none bg-transparent"
      >
        <FaUserCircle
          className="hover:scale-105 cursor-pointer hover:text-assoCol"
          fontSize="22px"
          color="white"
        />
      </label>
      <ul
        tabIndex="0"
        className="dropdown-content backdrop-blur-lg bg-stone-800 w-[300px] menu p-2 shadow rounded-box"
      >
        {currentUser ? (
          <div>
            <li className=" flex w-full  hover:bg-assoCol bg-opacity-50 rounded self-end flex-col">
              <p className=" w-full  flex items-center text-[15px]  text-sm text-white cursor-pointer">
                <BiUser className="text-white" size={"23px"} />
                <Link to="/user"> {currentUser.email}</Link>
              </p>
            </li>
            <li className=" flex w-full hover:bg-assoCol bg-opacity-50 rounded self-end flex-col">
              <p
                className=" w-full  flex items-center  text-[15px]  text-sm text-white  cursor-pointer"
                onClick={() => scollega()}
              >
                <BiLogOutCircle className="text-white" size={"23px"} />
                Scollegati
              </p>
            </li>
          </div>
        ) : (
          <li className="flex cursor-pointer w-full hover:bg-assoCol bg-opacity-50 rounded self-end flex-col">
            <Link to="/login" className=" ">
              <p className=" flex items-center text-[15px]  text-sm text-white pr-2">
                Accedi
                <BiLogInCircle className="text-white pl-2" size={"24px"} />
              </p>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default AccediButton;
