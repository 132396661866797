import React from "react";
import { deleteDoc, doc } from "firebase/firestore";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { RiDeleteBin2Fill } from "react-icons/ri";

const DelButtonAccount = ({ info, type }) => {
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(database, type, info.id));
      toast.success("Eliminato");
    } catch (err) {
      toast.error("OPS! Qualcosa è andato storto, riprova");
    }
  };

  return (
    <div className="w-full h-full ">
      <label htmlFor={info.id} className="modal-button">
        <RiDeleteBin2Fill className="w-4 h-full rounded " />
      </label>

      <input type="checkbox" id={info.id} className="modal-toggle" />
      <div className="modal modal-bottom sm:modal-middle">
        <div className="modal-box dark:bg-zinc-100">
          <h3 className="font-bold text-lg">ATTENZIONE!!!</h3>
          <p className="py-4">
            Sei sicuro di voler cancellare l'account di
            <span className="text-assoCol uppercase underline px-1">
              {info.nome} {info.cognome}
            </span>
            ?
          </p>
          <div className="flex w-full justify-between">
            <div className="modal-action">
              <label
                htmlFor={info.id}
                className="btn bg-assoCol glass hover:bg-assoCol text-white"
                onClick={handleDelete}
              >
                Elimina
              </label>
            </div>
            <div className="modal-action">
              <label
                htmlFor={info.id}
                className="btn bg-assoCol glass hover:bg-assoCol text-white"
              >
                Annulla
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelButtonAccount;
