import React, { Fragment } from "react";
import { database } from "../../firebase_config";

const TvccData = ({ data }) => {
    return (
        <div className="w-full h-full space-y-2">
            <p className="text-xl uppercase font-bold my-6 border-b-2  border-assoCol">
                Tvcc
            </p>
            <div className="w-full flex flex-col space-y-2 ">
                <p className="capitalize font-bold ">
                    Marchio preferito :
                    <span className="font-normal pl-2">{data.marchio}</span>
                </p>
                <p className="capitalize font-bold lg:w-1/2">
                    Tipo di impianto :
                    <span className="font-normal pl-2">{data.impianto}</span>
                </p>
                <p className="capitalize font-bold">
                    risoluzione impianto :
                    <span className="font-normal pl-2">
                        {data.risoluzione_impianto}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    tipo di regitratore
                    {database.impianto === "IP" ? "IP" : "ibrido"} :
                    <span className="font-normal pl-2">
                        {data.videoregistratore}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    ingressi videoregistratore :
                    {data.ingressi_video.length > 0 && (
                        <span className="font-normal pl-2">
                            {data.ingressi_video}
                        </span>
                    )}
                    {data.ingressi_altro.length > 0 && (
                        <span className="font-normal pl-2">
                            {data.ingressi_altro}
                        </span>
                    )}
                </p>
                <p className="capitalize font-bold">
                    HDD:
                    <span className="font-normal pl-2">{data.hdd}</span>
                </p>
                <p className="capitalize font-bold">
                    Numero di telecamere tipo Bullet Ottica Fissa :
                    <span className="font-normal pl-2">
                        {data.bullet_ottica_fissa}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Numero di telecamere tipo Bullet Ottica Varifocal :
                    <span className="font-normal pl-2">
                        {data.bullet_varifocal}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Numero di telecamere tipo Dome Ottica Fissa :
                    <span className="font-normal pl-2">
                        {data.dome_ottica_fissa}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Numero di telecamere tipo Dome Ottica Varifocal :
                    <span className="font-normal pl-2">
                        {data.dome_varifocal}
                    </span>
                </p>
                {data.impianto !== "IP" && (
                    <Fragment>
                        <p className="capitalize font-bold ">
                            Alimentatore :
                            <span className="font-normal pl-2">
                                {data.alimentatore}
                            </span>
                        </p>
                        <p className="capitalize font-bold ">
                            Balun :
                            <span className="font-normal pl-2">
                                {data.balun}
                            </span>
                        </p>
                    </Fragment>
                )}
                <p className="capitalize font-bold ">
                    Junction Box :
                    <span className="font-normal pl-2">
                        {data.junction_box}
                    </span>
                </p>
                <p className="capitalize font-bold ">
                    Attacco Palo :
                    <span className="font-normal pl-2">
                        {data.attacco_palo}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Tipo di cavo :
                    <span className="font-normal pl-2">{data.cavo}</span>
                </p>
                <p className="capitalize font-bold">
                    Matasse di cavo :
                    <span className="font-normal pl-2">{data.num_cavi}</span>
                </p>
                <p className="capitalize font-bold">
                    Gruppo di continuità :
                    <span className="font-normal pl-2">{data.continuita}</span>
                </p>
                <p className="capitalize font-bold">
                    {data.impianto === "IP"
                        ? "Connettori RJ45 :"
                        : "Connettori a pressione per cavo COAX :"}
                    <span className="font-normal pl-2">{data.connettori}</span>
                </p>
                <p className="capitalize font-bold">
                    Connettori Alimentazione Maschio :
                    <span className="font-normal pl-2">
                        {data.connettori_maschio}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Armadio Rack :
                    <span className="font-normal pl-2">
                        {data.armadio_rack}
                    </span>
                </p>
                <p className="capitalize font-bold">
                    Mensole :
                    <span className="font-normal pl-2">{data.mensole}</span>
                </p>
                <p className="capitalize font-bold">
                    Presiera :
                    <span className="font-normal pl-2">{data.presiera}</span>
                </p>
                <p className="capitalize font-bold">
                    Guidacavi :
                    <span className="font-normal pl-2">{data.guidacavi}</span>
                </p>
                <p className="capitalize font-bold">
                    Ventole :
                    <span className="font-normal pl-2">{data.ventole}</span>
                </p>
                <p className="capitalize font-bold">
                    Kit Dadi e Bulloni :
                    <span className="font-normal pl-2">{data.kit}</span>
                </p>
                <p className="capitalize font-bold">
                    Switch :
                    <span className="font-normal pl-2">{data.switch}</span>
                    {data.switch === "si" && (
                        <span className="font-normal pl-2">
                            {data.switch_text}
                        </span>
                    )}
                </p>
            </div>
        </div>
    );
};

export default TvccData;
