import React from "react";

const Input = ({ name, title, value, handler, type, sty, placeholder }) => {
    const style = sty || "md: w-1 / 3";
    const placeH = placeholder || value;

    return (
        <div className={`w-full ${style} font-bold mb-4`}>
            <label className="block mb-1" htmlFor="ragione">
                {title}
            </label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={handler}
                placeholder={placeH}
                className="py-2 px-3 border border-assoCol focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
        </div>
    );
};

const InputEdit = ({ name, title, value, handler, placeholder }) => {
    return (
        <div className="flex items-center justify-between">
            <label htmlFor="ragione">{title}</label>
            <input
                id={name}
                type="text"
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={handler}
                className="w-1/2 py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block"
            />
        </div>
    );
};

const SelectEdit = ({ title, value, handler, options }) => {
    return (
        <div className="flex items-center justify-between">
            <label htmlFor="livello" className="capitalize">
                {title}
            </label>
            <select
                className="w-1/2 ml-2 py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none 
                focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block"
                name={title}
                onChange={handler}
                value={value}
            >
                {options.map((list, idx) => (
                    <option
                        key={idx}
                        value={list}
                        className="bg-assoBg selection:bg-assoCol"
                    >
                        {list}
                    </option>
                ))}
            </select>
        </div>
    );
};

export { InputEdit, SelectEdit, Input };
