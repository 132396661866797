import { useEffect } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";
import { Routes, Route } from "react-router-dom";
import { database } from "../../Utils/firebase_config";
import BrandPage from "./BrandPage";

const BrandRoutes = ({ livello, brandsState, setBrandsState }) => {
  useEffect(() => {
    const brandsRef = collection(database, "brand");
    {
      const q = query(brandsRef);
      onSnapshot(q, (snapshot) => {
        const brandsNew = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBrandsState(brandsNew);
      });
    }
  }, [setBrandsState]);
  return (
    <Routes>
      {brandsState.map((brand, key) => {
        return (
          <Route
            key={key}
            path={`/${brand?.name?.replace("%20", / /g)}`}
            element={<BrandPage livello={livello} brand={brand} />}
          />
        );
      })}
    </Routes>
  );
};

export default BrandRoutes;
