import React from "react";
import BodyHeading from "../components/Organism/Body/BodyHeading";
import BodySkills from "../components/Organism/Body/BodySkills";
import Slider from "../components/UI/Slider/Slider";
import { Link } from "react-router-dom";
import LoghiPic from "../components/Organism/LoghiPic/LoghiPic";

const Azienda = ({ user }) => {
  return (
    <div className=" w-full">
      <div className="h-[30vh] md:h-[60vh] lg:h-[90vh] xl:h-[100vh]">
        <Slider />
      </div>
      <div className="pb-6">
        <BodyHeading />
      </div>
      <BodySkills />
      <div className=" flex flex-col mt-4">
        <h3 className="md:text-4xl text-[16px] text-center md:px-0 px-10 z-0 py-12 md:py-16 pb-12 font-bold text-assoCol">
          Oltre 40 Brand e più di 35.000 referenze/prodotti
        </h3>
        <div className="  mx-auto">
          <Link
            to="/soluzioni"
            value="Invia"
            className="btn bg-assoCol btn-md  border-none shadow btn-assoCol"
          >
            VAI A SOLUZIONI E PARTNER
          </Link>
        </div>
        <LoghiPic />
      </div>
    </div>
  );
};

export default Azienda;
