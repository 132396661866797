import React from "react";
import SoluzioniCard from "./SoluzioniCard";
  import { soluzioniList } from "./soluzioniList";
  import { Link } from "react-router-dom";
  import Hero from "../../components/UI/Hero/Hero";
  const soluzioni =
    "https://i.im.ge/2022/08/04/FxeZWK.SOLUZIONI-E-PARTNER-MOBILE.jpg";

  function Soluzioni() {
    return (
      <div className="bg-zinc-100">
        <div className="lg:hidden">
          <Hero
            srcImageMobile={soluzioni}
            desc={"Qui puoi scoprire tutti le nostre soluzioni e partner"}
            name={"SOLUZIONI"}
          />
        </div>

        <div className="h-full w-full pt-10 lg:pt-0 space-x-10 justify-center xl:justify-between  flex  ">
          <div className=" h-[100vh] ">
            <div className="h-[100vh]  hidden lg:block">
              <ul className="text-sm font-bold pt-14 space-y-6 px-10 overflow-y  h-full bg-gradient-to-b  from-black text-stone-200 to-stone-800">
                {soluzioniList.map((soluzione) => {
                  return (
                    <li>
                      <Link
                        to={soluzione.path}
                        className="hover:scale-105 rounded hover:shadow hover:text-white text-white  "
                      >
                        {soluzione.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:p-2 xl:my-4 mb-10 lg:my-2 lg:grid-cols-3 xl:grid-cols-4 grid-rows-3  flex-wrap gap-2 md:gap-1  from-white lg:w-[90vw] ">
            {soluzioniList.map((soluzione) => {
              return (
                <SoluzioniCard
                  logo={soluzione.logo}
                  name={soluzione.title}
                  link={soluzione.path}
                  descrizione={soluzione.descrizione}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }

export default Soluzioni;
