import React, { useState, useRef } from "react";
import { addDoc, collection } from "firebase/firestore";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import { Input } from "../UI/Input/InputEdit";

const initialState = {
  nome: "",
  luogo: "",
  date: "",
  linkEvento: "",
  email: "",
  maxGuest: "",
  image: "",
  selected: false,
};
const FormEvents = () => {
  const editor = useRef(null);
  const [content, setContent] = useState(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };
  const [formData, setFormData] = useState(initialState);
  const [selected, setSelected] = useState(false);

  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setSelected(!selected);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      descrizione: content,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const eventRef = collection(database, "eventi");

    addDoc(eventRef, {
      nome: formData.nome,
      luogo: formData.luogo,
      date: formData.date,
      linkEvento: formData.linkEvento,
      email: formData.email,
      maxGuest: Number(formData.maxGuest),
      image: formData.image,
      descrizione: content,
      selected: selected,
      totPartecipanti: 0,
      partecipanti: [],
    })
      .then(() => {
        toast.header.success("Evento inserito");
      })
      .catch((err) => {
        console.log(err);
      });

    //empty form
    setFormData(initialState);
    setContent(null);
  };

  return (
    <div className="w-full h-full bg-zinc-100 text-zinc-800 flex flex-col sm:justify-center items-center py-6 sm:pt-0 rounded-b-xl border-x-assoCol border-b-assoCol border-2 border-opacity-50 shadow-2xl">
      <form className="w-5/6 flex flex-col items-center justify-center pt-10">
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-full flex flex-col lg:flex-row lg:space-x-4">
            <Input
              type="text"
              name="nome"
              title="Nome dell' Evento"
              value={formData.nome}
              handler={handleChange}
              required
            />
            <Input
              type="text"
              name="luogo"
              title="Location"
              value={formData.luogo}
              handler={handleChange}
              required
            />

            <Input
              title="Data dell' Evento"
              type="date"
              name="date"
              value={formData.date}
              handler={handleChange}
              required
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:space-x-4">
            <Input
              type="text"
              name="linkEvento"
              value={formData.linkEvento}
              handler={handleChange}
              title="Inserisci il link dell'Evento / Email"
              required
            />
            <Input
              type="text"
              name="email"
              value={formData.email}
              handler={handleChange}
              title="Inserisci l' email dell' Organizzatore"
              required
            />
            <Input
              type="text"
              name="maxGuest"
              value={formData.maxGuest}
              handler={handleChange}
              title="Numero massimo di partecipanti"
              required
            />
          </div>
          <div className="w-full flex flex-col lg:flex-row lg:space-x-5">
            <Input
              type="text"
              name="image"
              value={formData.image}
              handler={handleChange}
              title="Inserisci il link dell' immagine"
              required
            />

            <div className="w-[15rem]">
              {formData.image && (
                <img
                  src={formData.image}
                  alt="evento"
                  className="w-full h-full"
                />
              )}
            </div>
          </div>
          <div className="text-black w-full my-2">
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
          </div>
          <div className="form-control w-full ">
            <label className="cursor-pointer label w-1/6">
              <span className="label-text text-zinc-800 uppercase font-bold">
                Aggiungi alla Home
              </span>
              <input
                name="selected"
                type="checkbox"
                value={selected}
                className="checkbox border-assoCol"
                onChange={handleChange}
              />
            </label>
          </div>
          {!formData.nome ||
          !formData.luogo ||
          !formData.date ||
          !formData.linkEvento ||
          !formData.email ||
          !formData.maxGuest ||
          !formData.image ? (
            <button
              disabled
              className="btn glass w-full  bg-assoCol hover:bg-assoCol  font-medium rounded-lg text-xl text-white  px-5 py-2.5 text-center mt-4 mb-1"
            >
              compila tutti i campi
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              type="submit"
              className="btn glass w-full  bg-assoCol hover:bg-assoCol  font-medium rounded-lg text-xl text-white  px-5 py-2.5 text-center mt-4 mb-1"
            >
              aggiungi
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FormEvents;
