import React, { Fragment, useState } from "react";
import { HtmlToJSX } from "../../../Utils/utils";
import {
  BsArrowRightCircle,
  BsCalendarDate,
  BsLink45Deg,
} from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import Partecipa from "../../../Routes/Eventi/Partecipa";

import { Button } from "@chakra-ui/react";
let moment = require("moment");
require("moment/locale/it");
moment.locale("it");

export const CardSmall = ({ data }) => {
  const [open, setOpen] = useState(false);

  const { link, nome, date, image, luogo, descrizione } = data;

  return (
    <div
      className={`card bg-black h-[40vh] lg:h-full neumorphism ${
        open ? "h-[60vh]" : ""
      }   w-full image-full `}
    >
      <figure className="w-full h-[40vh] ">
        <img
          className="w-full h-full object-contain"
          src={data.image}
          alt="Shoes"
        />
      </figure>
      <div className="card-body ">
        <h2 className="card-title font-normal pb-2 xl:text-2xl border-b lg:w-[60%] [border-white">
          {data.nome}
        </h2>

        <div className="card-actions justify-between">
          <div className="w-1/2 space-x-2">
            <div className="badge gap-2 badge-outline">{data.date}</div>
            <div className="badge badge-outline">Recenti</div>
          </div>
          <div
            onClick={() => setOpen(true)}
            className="flex cursor-pointer justify-start items-center"
          >
            <p className="text-[15px] pr-2"> scopri di piu</p>
            <label className=" text-stone-100 border-none bg-tranparent  cursor-pointer hover:scale-105 text-[12px] hover:bg-transparent">
              <BsArrowRightCircle size={25} color="white" />
            </label>
          </div>
        </div>
        <div>
          {open && (
            <div className="fixed  bg-white inset-0 z-50 overflow-scroll w-screen h-screen flex flex-col items-center justify-center bg-opacity-90">
              <div className="w-full  h-full ">
                <div className="w-full   bg-white bg-opacity-90 lg:flex-col items-start lg:items-center justify-center py-[-15px] 2xl:px-20 lg:px-0 px-0">
                  <div className=" h-[39vh] w-80 lg:block hidden">
                    {image ? (
                      <img
                        className="w-full h-full  object-cover"
                        alt="event pic"
                        src={image}
                      />
                    ) : (
                      <img
                        className="w-full h-full object-cover"
                        alt="evento pic"
                        src={image}
                      />
                    )}
                  </div>
                  <div className="lg:hidden w-full h-[50vh]">
                    {image && (
                      <img
                        className="w-full h-full object-contain"
                        alt="evento"
                        src={image}
                      />
                    )}
                  </div>
                  <div className="xl:w-full] flex flex-col items-center  p-8 bg-gradient-to-b from-zinc-50 to-zinc-100 rounded-xl shadow-xl  lg:ml-0 lg:mt-0 mt-6">
                    <div className="w-[80vw]">
                      <div className="border-b border-gray-200 pb-6">
                        <h1 className="lg:text-4xl text-xl py-4 font-semibold lg:leading-2 leading-7 text-assoCol  mt-2">
                          {nome ? nome : ""}
                        </h1>
                      </div>
                      <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                        <div className="text-sm leading-2 flex   text-gray-800">
                          <BsCalendarDate className="mr-4" size={14} />
                          Data Evento
                        </div>

                        <div className="flex items-center justify-center">
                          <p className="text-sm leading-none text-gray-600  ">
                            {moment(date).format("LL")}
                          </p>
                        </div>
                      </div>
                      <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                        <p className="text-sm leading-2 items-center flex text-gray-800 ">
                          <BsLink45Deg className="mr-4" size={18} />
                          Link:
                        </p>

                        <div className="flex items-center justify-center">
                          <a
                            className="text-sm leading-none text-gray-600 "
                            href={link}
                          >
                            {link ? link : "Non specificato "}
                          </a>
                        </div>
                      </div>
                      <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                        <div className="text-sm leading-2 items-center flex text-gray-800 ">
                          <GoLocation className="mr-4" size={18} />
                          Luogo
                        </div>

                        <div className="flex items-center justify-center">
                          <p className="text-sm leading-none text-gray-600 ">
                            {luogo ? luogo : "Non specificato"}
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="xl:pr-0 text-base  leading-relaxed text-gray-600  mt-7">
                          {HtmlToJSX(descrizione)}
                        </div>
                      </div>
                      <div className="w-full h-full  mt-14 flex justify-start">
                        <button
                          onClick={() => setOpen(false)}
                          className=" btn bg-transparent hover:bg-transparent focus:bg-transparent  border-none right-5 text-assoCol glass absolute border-0 focus:border-0 top-10 
                                                     hover:bg-assoCol hover:opacity-80"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export function CardEventi({
  user,

  linkEvento,
  nome,
  date,
  image,
  luogo,
  descrizione,
  id,
  maxGuest,
  partecipanti,
  totPartecipanti,
  organizzatore,
  isNews,
}) {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="flex bg-gradient-to-br flex-col my-8 md:h-auto  duration-300 hover:scale-105 cursor-pointer lg:flex-row md:space-x-5 space-y-2 md:space-y-0 rounded-md shadow-xl w-[76vw] border border-white bg-white">
        <div className="xl:w-3/6  h-[22vh] md:h-[32vh] lg:w-[40vw] flex rounded-xl p-2  w-full bg-white  place-items-center">
          <div className=" w-full  h-full text-4xl md:text-2xl rounded text-zinc-100 flex  items-center justify-center">
            <div className="card w-full h-full shadow-xl image-full">
              <figure className="w-full">
                <img
                  src={image}
                  alt="evento_img"
                  className="w-full h-full object-scale-down max-h-[30vh]"
                />
              </figure>

              <div className="card-body p-3 md:p-2">
                {!isNews && (
                  <div className="card-body md:p-2">
                    <div className="stat p-0 space-y-1 2xl:space-y-5">
                      <div className="stat-title text-[18px] lg:text-[30px]  2xl:text-[30px] text-[#ffff] ">
                        {moment(date).locale("it").format("dddd").toUpperCase()}
                      </div>
                      <div className="stat-value text-3xl lg:text-5xl  2xl:text-30px] text-[#ffff]">
                        {
                          moment(date).format("DD") // giu 22º 22
                        }
                      </div>
                      <div className="stat-desc md:text-xl 2xl:text-[30px] capitalize text-[#ffff]">
                        {
                          moment(date).locale("it").format("MMMM") // giu 22º 22
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full justify-between  px-4 lg:w-2/3 bg-white flex flex-col">
          <div className="flex justify-between item-center">
            <p className="text-gray-500 font-medium text-md pt-6 md:pt-4 ">
              {luogo}
            </p>
          </div>
          <h3 className="font-semibold border-b-2 py-2  border-assoCol md:w-[23vw] w-[80%] text-left md:text-left text-gray-800 md:text-xl text-[16px] lg:text-xl">
            {nome ? nome : "Non specificato"}
          </h3>
          <div className="overflow-scroll pb-8 pt-1"></div>
          <button
            onClick={() => setOpen(true)}
            className="w-full lg:w-[10vw] self-end border-none  my-4 float-right text-[12px]  btn btn-sm bg-assoCol"
          >
            Scopri di più
          </button>
        </div>
      </div>

      <div>
        {open && (
          <div className="fixed top-0  inset-0 z-50 overflow-scroll w-screen h-screen bg-white flex flex-col items-center justify-center bg-opacity-90">
            <div className="w-full  h-full ">
              <div className="w-full  bg-white bg-opacity-90 lg:flex-col items-start lg:items-center justify-center py-[-15px] 2xl:px-20 lg:px-0 px-0">
                <div className="w-full  flex justify-center items-center">
                  {image && (
                    <img
                      className="w-full md:h-full lg:object-cover object-center"
                      alt="event pic"
                      src={image}
                    />
                  )}
                </div>

                <div className="xl:w-full] flex flex-col items-center  p-8 bg-gradient-to-b from-zinc-50 to-zinc-100 rounded-xl shadow-xl  lg:ml-0 lg:mt-0 mt-6">
                  <div className="w-[80vw]">
                    <div className="border-b border-gray-200 pb-6">
                      <h1 className="lg:text-4xl text-xl py-4 font-semibold lg:leading-2 leading-7 text-assoCol  mt-2">
                        {nome ? nome : ""}
                      </h1>
                    </div>
                    {!isNews && (
                      <>
                        <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                          <div className="text-sm leading-2 flex   text-gray-800">
                            <BsCalendarDate className="mr-4" size={14} />
                            Data Evento
                          </div>

                          <div className="flex items-center justify-center">
                            <p className="text-sm leading-none text-gray-600  ">
                              {moment(date).format("DD/MM/YYYY")}
                            </p>
                          </div>
                        </div>
                        <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                          <div className="text-sm leading-2 items-center flex text-gray-800 ">
                            <BsLink45Deg className="mr-4" size={18} />
                            Link:
                          </div>

                          <div className="flex items-center justify-center">
                            <a
                              className="text-sm leading-none text-assoCol underline "
                              href={linkEvento}
                            >
                              {linkEvento
                                ? "Partecipa via link"
                                : "Non specificato"}
                            </a>
                          </div>
                        </div>
                        <div className="py-4 border-b border-gray-200 flex items-center justify-between">
                          <div className="text-sm leading-2 items-center flex text-gray-800 ">
                            <GoLocation className="mr-4" size={18} />
                            Luogo
                          </div>

                          <div className="flex items-center justify-center">
                            <p className="text-sm leading-none text-gray-600 ">
                              {luogo ? luogo : "Non specificato"}
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    <div>
                      <div className="xl:pr-0 text-base  leading-relaxed text-gray-600  mt-7">
                        {HtmlToJSX(descrizione)}
                      </div>
                    </div>
                    <div className="w-full h-ful space-x-4  mt-14 flex justify-end">
                      {user?.length > 0 && (
                        <Partecipa
                          user={user}
                          id={id}
                          maxGuest={maxGuest}
                          partecipanti={partecipanti}
                          totPartecipanti={totPartecipanti}
                          nome={nome}
                          organizzatore={organizzatore}
                        />
                      )}
                      <Button
                        textTransform="uppercase"
                        onClick={() => setOpen(false)}
                        backgroundColor="#ff4f19"
                        className="bg-assoCol text-white hover:bg-orange-700"
                      >
                        Chiudi
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
