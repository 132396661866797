import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { AiFillEdit } from "react-icons/ai";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { Input } from "../Input/InputEdit";

const EditContatti = ({ info, type }) => {
    const [edit, setEdit] = useState({
        ...info,
    });
    const handleChange = (e) => {
        setEdit({
            ...edit,
            [e.target.name]: e.target.value,
        });
    };

    const handleEdit = async () => {
        const ref = doc(database, type, info.id);

        try {
            await updateDoc(ref, {
                id_dip: Number(edit.id_dip),
                qrcode: edit.qrcode,
                nome: edit.nome,
                mansione: edit.mansione,
                telefono: edit.telefono,
                email: edit.email,
            });
            toast.success("Aggiornato");
        } catch (err) {
            toast.error("Oops!Riprova");
        }
    };
    return (
        <div className="w-full h-full flex justify-center items-center">
            <label htmlFor={edit.nome} className="modal-button">
                <AiFillEdit className="w-4 h-full rounded " />
            </label>
            <input type="checkbox" id={edit.nome} className="modal-toggle" />
            <div className="modal w-full">
                <div className="w-2/3 rounded-xl px-8 py-1 bg-zinc-100 text-black">
                    <form className="w-full flex flex-col items-center justify-center pt-10">
                        <div className="w-full flex flex-col justify-start items-center">
                            <div className="w-full flex flex-col lg:flex-row space-x-4">
                                <p className="text-left text-2xl uppercase text-bold mb-5 ">
                                    Modifica Dipendente
                                </p>
                            </div>

                            <div className="w-full flex items-center space-x-2">
                                <div className="w-2/12">
                                    <Input
                                        type="text"
                                        name="id_dip"
                                        value={edit.id_dip}
                                        handler={handleChange}
                                        title="ID *"
                                    />
                                </div>

                                <Input
                                    type="text"
                                    name="nome"
                                    value={edit.nome}
                                    handler={handleChange}
                                    title="Nome Completo *"
                                    sty="md:w-full "
                                />
                            </div>
                            <Input
                                type="text"
                                name="mansione"
                                value={edit.mansione}
                                handler={handleChange}
                                title="Mansione *"
                                sty="md:w-full "
                            />
                            <Input
                                type="text"
                                name="qrcode"
                                value={edit.qrcode}
                                handler={handleChange}
                                title="Link QRCode *"
                                sty="md:w-full "
                            />
                            <div className="w-full flex items-center space-x-2">
                                <Input
                                    type="text"
                                    name="telefono"
                                    value={edit.telefono}
                                    handler={handleChange}
                                    title="Telefono *"
                                    sty="md:w-full "
                                />
                                <Input
                                    type="text"
                                    name="email"
                                    value={edit.email}
                                    handler={handleChange}
                                    title="Email *"
                                    sty="md:w-full "
                                />
                            </div>
                        </div>
                    </form>

                    <div className="w-full flex justify-between mt-4 space-x-1">
                        <label
                            htmlFor={edit.nome}
                            className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded 
                          hover:bg-assoCol"
                            onClick={handleEdit}
                        >
                            Aggiorna
                        </label>
                        <label
                            className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded
                          hover:bg-assoCol"
                            htmlFor={edit.nome}
                        >
                            Chudi
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditContatti;
