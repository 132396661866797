import React from "react";

const EsterniData = ({ data }) => {
    return (
        <div className="w-full flex flex-col rounded py-2 my-2">
            <p className="font-bold mt-2 underline text-assoCol uppercase">
                Rivelatori Esterni
            </p>
            {data.map((esterni, i) => (
                <p key={i}>{esterni}</p>
            ))}
        </div>
    );
};

export default EsterniData;
