import React from "react";

const Partecipanti = ({ joiner, id }) => {
  return (
    <div className="w-full h-full  flex flex-col justify-center items-center mb-10">
      <label
        htmlFor={id}
        className="btn hover:bg-assoCol hover:opacity-80 modal-button"
      >
        Partecipanti
      </label>
      <input type="checkbox" id={id} className="modal-toggle" />
      <div className="modal w-full h-full">
        <div className="w-2/3 rounded-xl px-8 py-1 dark:bg-zinc-100">
          <div className="card-body p-0">
            <p className="text-sm leading-none"></p>
            <div className="overflow-x-auto">
              {joiner.length > 0 && (
                <div className="table w-full rounded dark:bg-white text-black dark:text-black">
                  <thead>
                    <tr>
                      <th className="dark:bg-assoCol bg-assoCol "></th>
                      <th className="dark:bg-assoCol bg-assoCol  ">Name</th>
                      <th className="dark:bg-assoCol bg-assoCol  ">Email</th>
                      <th className="dark:bg-assoCol bg-assoCol  ">
                        Num. Partecipanti
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {joiner.map((list, idx) => (
                      <tr key={idx}>
                        <th className="text-black dark:text-black">
                          {idx + 1}
                        </th>
                        <td className="text-black dark:text-black">
                          {" "}
                          {list.nome}
                        </td>
                        <td className="text-black dark:text-black">
                          {list.email}
                        </td>
                        <td className="text-black dark:text-black">
                          {list.numOfGuests}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </div>
              )}
              {joiner.length === 0 && (
                <div className="h-[10rem] w-full uppercase text-2xl flex justify-center items-center text-center">
                  <p>Non ci sono partecipanti al momento</p>
                </div>
              )}
            </div>
          </div>
          <label
            className="w-full btn glass  bg-assoCol text-assoBg uppercase font-bold rounded mt-2
                          hover:bg-assoCol"
            for={id}
          >
            Chudi
          </label>
        </div>
      </div>
    </div>
  );
};

export default Partecipanti;
