import {
  Button,
  ModalContent,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Spinner,
} from "@chakra-ui/react";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import emailjs from "@emailjs/browser";

const Partecipa = ({
  user,
  id,
  totPartecipanti,
  maxGuest,
  nome,
  organizzatore,
}) => {
  const [info, setinfo] = useState({
    nome: "",
    email: "",
    tel: "",
    numOfGuests: 1,
    organizzatore: organizzatore,
    evento: nome,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handler = () => {
    onOpen();
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      user !== null ||
      user !== "anonimo" ||
      user !== undefined ||
      user !== ""
    ) {
      setinfo({
        nome: user[0].nome + " " + user[0].cognome,
        email: user[0].email,
        tel: user[0].cell,
        numOfGuests: info.numOfGuests,
        organizzatore: info.organizzatore,
        evento: info.evento,
      });
    }
  }, [user, info.numOfGuests, info.organizzatore, info.evento]);
  const tot = Number(totPartecipanti) + Number(info.numOfGuests);
  const handleChange = (e) => {
    setinfo({ ...info, [e.target.name]: e.target.value });
  };

  const sendMail = (e) => {
    e.preventDefault();
    setLoading(true);

    if (maxGuest <= totPartecipanti) {
      toast.error("Abbiamo raggiunto il numero massimo di partecipanti");
      return;
    }

    const joinRef = doc(database, "eventi", `${id}`);
    updateDoc(joinRef, {
      totPartecipanti: Number(tot),
      partecipanti: arrayUnion(info),
    });

    emailjs
      .send("asso_gm", "registrazione_eventi", info, "bKba04qrI658pumvI")
      .then(() => {
        toast.success("Partecipazione Inviata");
        onClose();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <div>
      <Button
        onClick={handler}
        className="bg-assoCol text-white hover:bg-orange-700"
        backgroundColor="#ff4f19"
        textTransform="uppercase"
      >
        partecipa
      </Button>
      <Modal onClose={onClose} size="xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <p className="px-10 pt-2">
              Sei sicuro che vuoi partecipare all’evento
              <span className="text-assoCol"> {nome} </span>?
            </p>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody className="flex justify-center">
            <div className="modal-box">
              {(user === null ||
                user === "anonimo" ||
                user === undefined ||
                user === "") && (
                <p className="text-4xl font-bold text-black">
                  Per partecipare devi effettuare il login
                </p>
              )}
              {(user !== null ||
                user !== "anonimo" ||
                user !== undefined ||
                user !== "") && (
                <div className="w-full space-y-4">
                  <div className="w-full space-y-2 text-black">
                    <label htmlFor="nome" className="uppercase font-bold">
                      Nome Completo
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={info.nome}
                      name="nome"
                      className="input input-bordered input-error w-full"
                    />
                  </div>
                  <div className="w-full space-y-2 text-black">
                    <label htmlFor="email" className="uppercase font-bold">
                      Email
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={info.email}
                      name="email"
                      className="text-black input input-bordered input-error w-full "
                    />
                  </div>
                  <div className="w-full space-y-2 text-black">
                    <label htmlFor="tel" className="uppercase font-bold">
                      Telefono
                    </label>
                    <input
                      type="text"
                      onChange={handleChange}
                      value={info.tel}
                      name="tel"
                      className="text-black input input-bordered input-error w-full "
                    />
                  </div>
                  <div className="w-full flex flex-row justify-center items-center text-black">
                    <label
                      htmlFor="numOfGuests"
                      className="w-full uppercase font-bold"
                    >
                      Quanti siete ?
                    </label>
                    <input
                      type="number"
                      placeholder=" min 1"
                      name="numOfGuests"
                      onChange={handleChange}
                      value={info.numOfGuests}
                      className="input input-bordered input-error w-1/6 "
                    />
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              className="bg-assoCol text-assoCol hover:bg-orange-700"
              textTransform="uppercase"
              m={4}
            >
              Chiudi
            </Button>
            <Button
              onClick={sendMail}
              className="bg-assoCol text-assoCol hover:bg-orange-700"
              textTransform="uppercase"
            >
              {loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="orange.500"
                  size="md"
                />
              ) : (
                "Invia"
              )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Partecipa;
