import React from "react";

const Videocitofonia = ({ handleChange, videocitofonia }) => {
    return (
        <div className=" w-full pb-8 bg-assoBg text-zinc-800">
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="form-control w-full flex ">
                <div className="w-full mx-4">
                    <p className="text-xl text-black">
                        Richiesta Offerta Videocitofonia
                    </p>
                </div>
                <div className="divider bg-zinc-200 h-1 "></div>
                <div>
                    <div className="flex justify-start mb-10">
                        <div className=" w-full lg:1/2 flex flex-col">
                            <label className="text-zinc-700 font-semibold mb-4 ml-4">
                                Marchio Preferito *
                            </label>
                            <select
                                name="marchio"
                                onChange={handleChange}
                                className="select select-error w-full max-w-xs bg-white mx-3"
                                value={videocitofonia.marchio}
                            >
                                {marchi &&
                                    marchi.map((list, i) => (
                                        <option
                                            key={i}
                                            value={list}
                                            className="bg-assoBg selection:bg-assoCol"
                                        >
                                            {list}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="w-full lg:1/2 flex flex-col justify-center">
                            <label className="text-zinc-700 font-semibold mb-2">
                                Di quante postazione Esterne hai bisogno? *
                            </label>
                            <input
                                value={videocitofonia.num_post_est}
                                onChange={handleChange}
                                name="num_post_est"
                                type="text"
                                className="block w-4/5 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                                required
                            />
                            <label className="label text-xs font-bold text-zinc-400 ">
                                Scrivi il Peso di ogni singola porta
                            </label>
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-full lg:w-1/2 text-zinc-800 flex flex-col justify-between ">
                            <label className="label ml-4 text-lg font-bold ">
                                Le postazioni esterne hanno numeri di bussate
                                differenti? *
                            </label>
                            <div className="flex items-center space-x-12 ml-1">
                                <div className="ml-4 ">
                                    <input
                                        type="radio"
                                        name="bussate_diff"
                                        value="Si"
                                        onChange={handleChange}
                                    />
                                    <span className="ml-4">Si</span>
                                </div>
                                <div className="ml-4">
                                    <input
                                        type="radio"
                                        name="bussate_diff"
                                        value="no"
                                        onChange={handleChange}
                                    />
                                    <span className="ml-4">No</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 text-zinc-800 flex flex-col justify-between ">
                            <label className="label ml-4 text-lg font-bold ">
                                Tipo d'installazione *
                            </label>
                            <div className="flex items-center space-x-12 mt-5 ml-1">
                                <div className="ml-4 ">
                                    <input
                                        type="radio"
                                        name="tipo_inst"
                                        value="Filo Parete"
                                        onChange={handleChange}
                                    />
                                    <span className="ml-4">Filo Parete</span>
                                </div>
                                <div className="ml-4">
                                    <input
                                        type="radio"
                                        name="tipo_inst"
                                        value="Incasso"
                                        onChange={handleChange}
                                    />
                                    <span className="ml-4">Incasso</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full py-12 px-4">
                        <p className="text-lg my-5 ml-1">
                            Scrivi quante bussate ha ogni posto esterno e se
                            hanno diversi tipi di installazione *
                        </p>
                        <textarea
                            className="textarea textarea-assoCol bg-orange-100 w-full"
                            placeholder="Scrivi qui..."
                            name="comment"
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    <div className="w-full lg:w-1/2 px-4 flex flex-col justify-center">
                        <label className="text-zinc-700 font-semibold mb-2">
                            Di quante postazione Interne hai bisogno? *
                        </label>
                        <input
                            value={videocitofonia.num_post_int}
                            onChange={handleChange}
                            name="num_post_int"
                            type="text"
                            className="block w-4/5 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                            required
                        />
                        <label className="label text-xs font-bold text-zinc-400 ">
                            Scrivi il Peso di ogni singola porta
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Videocitofonia;

const marchi = ["Scegli", "Hikvision", "Dahua", "Farsisa"];
