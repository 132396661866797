import React from "react";
import Uploader from "../Uploader";

const Perimetrale = ({
  handler,
  handleUpload,
  progress,
  handleUp,
  upload,
  antintrusione,
}) => {
  return (
    <div className="w-full h-full  text-zinc-800 flex flex-col">
      <div className="divider bg-zinc-200 h-1 "></div>
      <div className="flex w-full mx-4 mt-5">
        <div className="w-1/2">
          <p className="text-2xl text-black">Protezione Perimetrale</p>
        </div>
      </div>
      <p className="text-sm m-5">
        Spiega in poche poche parole che tipo di perimetro devi proteggere, se
        il perimetro è complesso contattaci e inviaci una planimetria cosi
        riusciremo a trovare la soluzione ideale per la tua protezione. Oppure
        Carica una piantina dell'aria da proteggere *
      </p>
      <textarea
        className="textarea textarea-assoCol bg-white mx-4"
        placeholder="Scrivi qui..."
        id="perimetrale"
        name="messaggio"
        value={antintrusione.messaggio}
        onMouseLeave={handler}
      ></textarea>
      <Uploader
        handleUpload={handleUpload}
        progress={progress}
        handleUp={handleUp}
        upload={upload}
      />
    </div>
  );
};

export default Perimetrale;
