import { collection, onSnapshot, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { database } from "./firebase_config";

export const BrandFetcher = () => {
    const [brandsState, setBrandsState] = useState([]);

    useEffect(() => {
      let isMounted = true;
      const brandsRef = collection(database, "brand");
      if (isMounted) {
        const q = query(brandsRef);
        onSnapshot(q, (snapshot) => {
          const brandsNew = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBrandsState(brandsNew);
        });
      }
      return () => {
        isMounted = false;
      };
    }, [brandsState]);
};

export const routesList = [
    { name: "azienda", url: "azienda" },
    { name: "home", url: "azienda" },
    { name: "contatti", url: "contatti" },
    { name: "teleassistenza", url: "teleassistenza" },
    { name: "assistenza", url: "teleassistenza" },
    { name: "soluzioni", url: "soluzioni" },
    { name: "prodotti", url: "soluzioni" },
    { name: "novità", url: "news" },
    { name: "news", url: "news" },
    { name: "formazione", url: "eventi" },
    { name: "eventi", url: "eventi" },
    { name: "download", url: "download" },
    { name: "preventivo", url: "preventivo" },
];

export default routesList;
