import React, { Fragment } from "react";
import PDF from "./PDF";

const ButtonPDF = ({ preventivo, submit }) => {
    return (
        <Fragment>
            <label
                htmlFor="pdf"
                className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-assoCol rounded-md hover:bg-orange-400 focus:outline-none focus:bg-gray-600"
            >
                Anteprima
            </label>

            <input type="checkbox" id="pdf" className="modal-toggle" />
            <div className="modal flex flex-col">
                <div className="modal-box w-11/12 h-full max-w-5xl">
                    <PDF preventivo={preventivo} submit={submit} />
                </div>
            </div>
        </Fragment>
    );
};

export default ButtonPDF;
