import React, { useEffect, useState } from "react";
import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import { Input } from "../UI/Input/InputEdit";
import Spinner from "../UI/Spinner";
import FormIMGfiliale from "./FormIMGfiliale";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
} from "@chakra-ui/react";

const FormFiliale = () => {
    const [rubrica, setRubrica] = useState([]);
    const numFiliali = rubrica.length;
    const [loaded, setLoaded] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filiale, setFiliale] = useState({
        id_filiale: "",
        filiale: "",
        indirizzo: "",
        orari: "Lun-Ven 8:30-18:00 • Sabato-Domenica Chiusi",
        tel: "",
        map: "",
        code: "",
    });

    useEffect(() => {
      const rubRef = collection(database, "filiali");
      const q = query(rubRef, orderBy("id_filiale", "asc"));
      onSnapshot(q, (snapshot) => {
        const sedi = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRubrica(sedi);
        setLoaded(false);
      });
    }, []);

    const handleFiliale = (e) => {
      setFiliale({ ...filiale, [e.target.name]: e.target.value });
    };
    const addToDb = (e) => {
      e.preventDefault();
      if (
        !filiale.filiale ||
        !filiale.indirizzo ||
        !filiale.orari ||
        !filiale.tel
      ) {
        toast.error("Compila tutti i campi !!!");
        return;
      }
      setFiliale({
        id_filiale: "",
        filiale: "",
        indirizzo: "",
        orari: "Lun-Ven 8:30-18:00 • Sabato-Domenica Chiusi",
        tel: "",
        map: "",
      });

      const filialeRef = collection(database, `filiali`);
      addDoc(filialeRef, {
        id_filiale: numFiliali + 1,
        filiale: filiale.filiale,
        indirizzo: filiale.indirizzo,
        orari: filiale.orari,
        tel: filiale.tel,
        map: filiale.map,
      })
        .then(() => toast.success("Filiale Aggiunta"))
        .catch((err) => toast.error("Qualcosa è andato storto", err));
    };

    return (
        <div className="w-full h-full bg-zinc-100 text-zinc-800 flex flex-col py-16 pt-5 rounded-b-xl">
            {loaded && (
                <div className="w-[1rem]">
                    <Spinner />
                </div>
            )}

            <form className="w-full flex flex-col items-center  space-y-3">
                <div className="w-full flex flex-col justify-start items-center">
                    <div className="w-2/3 flex flex-col space-y-3">
                        <div className="w-full flex md:flex-col lg:flex-row lg:space-x-8">
                            <p className="text-left text-2xl uppercase text-bold mb-5 ">
                                Aggiungi Filiale
                            </p>
                            <p className="italic pb-4">
                                ci sono
                                {numFiliali && (
                                    <span className="text-assoCol mx-2 text-xl">
                                        {numFiliali}
                                    </span>
                                )}
                                filiali nel database
                            </p>
                        </div>

                        <Input
                            type="text"
                            name="filiale"
                            value={filiale.filiale}
                            placeholder="Es. Roma Aurelia"
                            handler={handleFiliale}
                            title="Filiale"
                        />

                        <Input
                            type="text"
                            name="indirizzo"
                            value={filiale.indirizzo}
                            handler={handleFiliale}
                            title="Indirizzo *"
                        />
                        <Input
                            type="text"
                            name="orari"
                            value={filiale.orari}
                            handler={handleFiliale}
                            title="orari *"
                        />

                        <Input
                            type="text"
                            name="tel"
                            value={filiale.tel}
                            handler={handleFiliale}
                            title="Telefono *"
                        />

                        <Input
                            title="Link Google Map *"
                            type="text"
                            name="map"
                            value={filiale.map}
                            handler={handleFiliale}
                        />

                        <p
                            onClick={onOpen}
                            className="cursor-pointer text-[0.8rem] text-assoCol"
                        >
                            (come inserire la mappa)
                        </p>

                        <Modal
                            isOpen={isOpen}
                            onClose={onClose}
                            style={{ width: "100vw" }}
                        >
                            <ModalOverlay />
                            <ModalContent>
                                <div className="px-4 mt-5">
                                    <li> Vai su google</li>
                                    <li>cerca il luogo desiderato</li>
                                    <li>clicca su condividi</li>
                                    <li>clicca su incorpora la mappa</li>
                                    <li>copia il link e scorpora solo l'src</li>
                                </div>

                                <ModalCloseButton />
                                <ModalBody>
                                    <p className="text-assoCol">
                                        esempio link:
                                    </p>
                                    https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2972.557203205335!2d12.59640371543983!3d41.837832079225414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe78eb12c66e41123!2sAsso%20Sicurezza%20SpA%20(Roma%20-%20Tuscolana)!5e0!3m2!1sit!2sde!4v1652521930305!5m2!1sit!2sde
                                </ModalBody>
                            </ModalContent>
                        </Modal>

                        <div className="w-full">
                            {!filiale.filiale ||
                            !filiale.indirizzo ||
                            !filiale.orari ||
                            !filiale.tel ? (
                                <button className="w-full btn glass bg-assoCol hover:bg-assoCol dark:text-white font-bold">
                                    compila tutti i campi
                                </button>
                            ) : (
                                <button
                                    onClick={addToDb}
                                    className="w-full btn glass bg-assoCol hover:bg-assoCol dark:text-white font-bold"
                                >
                                    salva
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            <div className="w-full flex flex-col items-center">
                <FormIMGfiliale />
            </div>
        </div>
    );
};

export default FormFiliale;
