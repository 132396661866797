import React, { useEffect, useState } from "react";
import { database } from "../../../Utils/firebase_config";
import { collection, onSnapshot, query, orderBy } from "firebase/firestore";
import { Link } from "react-router-dom";

const LoghiPic = () => {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    const brandsRef = collection(database, "brand");
    {
      const q = query(brandsRef, orderBy("importance"));
      onSnapshot(q, (snapshot) => {
        const brandsNew = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBrands(brandsNew);
      });
    }
  }, [setBrands]);

  return (
    <div className="w-full flex justify-center p-8  md:p-16">
      <div className="flex justify-center flex-wrap">
        {brands
          ? brands.map((logo) => {
              return (
                <div
                  key={logo.name}
                  className={`w-[25vw] h-[25vh] md:w-[16vw] md:h-[16vh]  p-4 md:p-10 md:py-[6vh] duration-100 cursor-pointer hover:scale-105 flex items-center  bg-white`}
                >
                  <Link to={`/${logo?.name?.replace("%20", / /g)}`}>
                    <img className="w-full h-full" src={logo.img} alt="logo" />
                  </Link>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default LoghiPic;
