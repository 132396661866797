//import { collection, onSnapshot, orderBy, query } from "firebase/firestore";

import React from "react";
import { Link } from "react-router-dom";
import { soluzioniList } from "./soluzioniList";

function SoluzioniSingola({
  name,
  brands,

  brandSize,
}) {
  return (
    <div className="">
      <div className="bg-opacity-[0.95] w-full h-full ">
        <div className="relative bg-white flex h-full flex-col lg:flex-row  overflow-hidden">
          <div className="bg-gradient-to-br from-zinc-100 to-zinc-100 w-full flex items-center justify-center lg:h-screen lg:w-[50vw]  mx-auto">
            <div className="relative  z-10 pb-8  sm:pb-16 lg:pb-20 lg:max-w-2xl lg:w-full  ">
              <main className="mt-16   mx-auto max-w-2xl px-4 sm:mt-12 sm:px-4 lg:mt-20 lg:px-2 xl:mt-0">
                <div className="sm:text-center  lg:pl-[8vw]  pt-[2vh] xl:pt-0">
                  <h1 className="text-3xl text-assoCol text-center lg:text-left font-extrabold text-opacity-[0.9] sm:text-4xl  lg:text-3xl xl:text-4xl">
                    {name}
                  </h1>
                  <h2 className="text-black block text-2xl text-center lg:text-left font-extrabold sm:text-3xl md:text-3xl xl:text-3xl">
                    Asso Sicurezza
                  </h2>

                  <p className="tracking-tight px-24 md:px-0 lg:pr-32 lg:w-[29vw] pb-[3vh] pt-2 text-center lg:text-left font-extrabold text-gray-900">
                    Qui puoi scoprire tutti i nostri brand relativi alla {name}.
                  </p>

                  <ul className="flex-col  lg:block hidden  pt-12 md:pt-2">
                    {soluzioniList.map((item) => (
                      <li
                        key={item.title}
                        className="font-bold text-sm text-center lg:text-left  text-zinc-600 hover:text-gray-900"
                      >
                        <Link to={`${item.path}`}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </main>
            </div>
          </div>
          <div className=" justify-center items-center  flex md:pt-0  pt-12 overflow-scroll lg:w-2/3">
            <div className="  justify-center items-center content-center flex-wrap  flex lg:inset-y-0 w-full  ">
              {brands
                ? brands.map((logo, key) => {
                    return (
                      <div
                        key={key}
                        className={`${brandSize} duration-100 cursor-pointer hover:scale-105 flex items-center  bg-white`}
                      >
                        <Link to={`/${logo.name}`}>
                          <img
                            className="w-full h-full"
                            src={logo.img}
                            alt="logo"
                          />
                        </Link>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>

        <ul className="py-8 space-y-2  bg-assoCol flex-col lg:hidden   flex">
          {soluzioniList.map((item) => (
            <button
              key={item.title}
              className="font-bold text-[13px] text-white hover:text-gray-900"
            >
              <Link to={`${item.path}`}>{item.title}</Link>
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default SoluzioniSingola;
