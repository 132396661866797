import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCwxRMy8jL1JA48dCkn0AQH_ekA6YBK6x8",
    authDomain: "asso-sicurezza-d46a8.firebaseapp.com",
    projectId: "asso-sicurezza-d46a8",
    storageBucket: "asso-sicurezza-d46a8.appspot.com",
    messagingSenderId: "814734152746",
    appId: "1:814734152746:web:7f5b60e6de3c125d425f53",
    measurementId: "G-R29492QF2V"
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const database = getFirestore();

const storage = getStorage();

const auth = getAuth();

export { app, database, storage, auth };
