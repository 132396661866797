import React from "react";

const DispSonori = ({ handleDisp }) => {
    return (
        <div className="w-full h-full flex">
            <div className="w-full flex flex-col lg:flex-row flex-wrap">
                {dispSonori.map((option, index) => (
                    <label
                        className="cursor-pointer w-full flex items-center mb-1"
                        key={index}
                    >
                        <div className="w-4/5">
                            <span className="text-zinc-800 ml-4">
                                {option.title}
                            </span>
                        </div>
                        <div className="w-1/5 flex">
                            <span className="text-zinc-800 mx-4">Qnt</span>
                            <input
                                name={option.title}
                                onMouseLeave={handleDisp}
                                type="text"
                                className="w-10 h-5 ring-opacity-0 "
                            />
                        </div>
                    </label>
                ))}
            </div>

            <div className="divider bg-zinc-200 h-1 "></div>
        </div>
    );
};

export default DispSonori;

const dispSonori = [
    {
        id: "sirena_esterna",
        title: "Sirena esterna",
    },
    {
        id: "sirena_esterna_antischiuma",
        title: "Sirene Esterna + Antischiuma ",
    },
    {
        id: "sirena_esterna_parlante",
        title: "Sirena Esterna parlante",
    },
    {
        id: "sirena",
        title: "Sirena",
    },
];
