import React from "react";

const RivelatoriInterni = ({ handle }) => {
    return (
        <div>
            <div className="w-full h-full  text-zinc-800 flex flex-col justify-between ">
                <div className="divider bg-zinc-200 h-1 "></div>
                <div className="flex w-full ml-4 mt-5">
                    <div className="w-1/2">
                        <p className="text-2xl text-black">
                            Rivelatore Interno
                        </p>
                    </div>
                </div>
                <p className="text-lg text-bold ml-4 mt-10 font-bold">
                    Che tipo di rivelatore desideri utilizzare *
                </p>
            </div>
            <div className="w-full h-full flex ml-4">
                <div className="w-full flex flex-col lg:flex-row mt-10 flex-wrap">
                    {interni.map((option, index) => (
                        <label
                            className="cursor-pointer w-full flex items-center mb-1"
                            key={index}
                        >
                            <div className="w-4/5">
                                <span className="text-zinc-800 ml-4">
                                    {option.title}
                                </span>
                            </div>
                            <div className="w-1/5 flex">
                                <span className="text-zinc-800 mx-4">Qnt</span>
                                <input
                                    name={option.title}
                                    onMouseLeave={handle}
                                    type="text"
                                    className="w-10 h-5 ring-opacity-0 "
                                />
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <div className="divider bg-zinc-200 h-1 "></div>
        </div>
    );
};

export default RivelatoriInterni;
const interni = [
    { id: "parete_IR", title: " Rivelatore a parete IR " },
    { id: "soffitto_360_IR", title: " Rivelatore a soffitto 360° IR " },
    { id: "parete_DT", title: " Rivelatore a parete DT " },
    {
        id: "rivelatore_a_soffitto_360_DT",
        title: " Rivelatore a soffitto 360° DT ",
    },
    {
        id: "parete_DT_Mascheramento",
        title: " Rivelatore a parete DT + Mascheramento ",
    },
    { id: "tenda_IR", title: " Rivelatore a tenda IR (porte e finestre) " },
    { id: "tenda_DT", title: " Rivelatore a tenda DT (porte e finestre) " },
    { id: "antiallagamento", title: " Rivelatore antiallagamento " },
    { id: "Incasso_IR", title: " Rivelatore ad Incasso IR " },
    { id: "Incasso_DT", title: " Rivelatore ad Incasso DT " },
];
