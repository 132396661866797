import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../Utils/firebase_config";
// import validateEmail from "../Utils/validateEmail";

const Login = () => {
  const email = useRef(null);
  const password = useRef(null);
  //const location = useLocation();
  const navigate = useNavigate();
  const login = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(
      auth,
      email.current.value,
      password.current.value
    )
      .then(() => {
        toast("accesso eseguito");
        navigate(-1);
        // history("/", { from: pathname, someOtherProp: "" });
      })
      .catch((error) => {
        if (error.code === "auth/wrong-password") {
          toast.error("Controlla la password");
        }
        if (error.code === "auth/user-not-found") {
          toast.error("Utente non trovato");
          console.log(error);
        }
        if (error.code === "auth/invalid-email") {
          toast.error("Email non valida");
          console.log(error);
        }
      });
  };

  return (
    <div className="w-full text-zinc-800  flex mt-10  md:mt-20 flex-col sm:justify-center items-center pt-6 sm:pt-0">
      <div className="w-full sm:max-w-md p-5 mx-auto">
        <h2 className="mb-12 text-center text-5xl font-extrabold">Accedi</h2>
        <form>
          <div className="mb-4">
            <label className="block mb-1" htmlFor="email">
              Indirizzo E-mail
            </label>
            <input
              type="text"
              name="email"
              ref={email}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-1" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              name="password"
              ref={password}
              className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
            />
          </div>
          <div className="mt-6 flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember_me"
                type="checkbox"
                className="border border-gray-300 text-red-600 shadow-sm focus:border-red-300 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              />
              <label
                htmlFor="remember_me"
                className="ml-2 block text-sm leading-5 text-gray-900"
              >
                Ricordami
              </label>
            </div>
            <Link to="/reset_password" className="text-xs pr-1">
              Password dimenticata?
            </Link>
          </div>
          <div className="mt-6">
            <button
              onClick={login}
              className="w-full bg-assoCol cursor-pointer inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-zinc-300  focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 transition"
            >
              Accedi
            </button>
          </div>
          <div className="mt-6  cursor-pointer text-center">
            <Link to="/registrati" className="underline">
              Registrati
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
