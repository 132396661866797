import React from "react";

const Telefonia = ({ handleChange, telefonia }) => {
    return (
        <div className=" w-full pb-4 bg-assoBg text-zinc-800">
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="form-control w-full flex ">
                <div className="w-full mx-4">
                    <p className="text-xl text-black">
                        Richiesta Offerta Telefonia
                    </p>
                </div>
                <div className="divider bg-zinc-200 h-1 "></div>
                <div className="text-zinc-800 flex flex-col justify-between ">
                    <label className="label ml-4 text-lg font-bold ">
                        Che tipo di impianto devi realizzare *
                    </label>
                    <div className="w-full h-full mb-6 ml-1">
                        <div className="flex justify-between lg:justify-start ml-4 mt-2">
                            {impianto.map((type, i) => (
                                <label className="cursor-pointer mr-12" key={i}>
                                    <input
                                        type="radio"
                                        name="impianto"
                                        value={type}
                                        onChange={handleChange}
                                        className="w-5 h-5 ring-opacity-0 "
                                    />
                                    <span className="text-zinc-800 ml-4">
                                        {type}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-1/2 text-zinc-800 flex flex-col justify-between ">
                        <label className="label ml-4 text-lg font-bold ">
                            Di quante linee d'ingresso hai bisogno? *
                        </label>
                        <input
                            value={telefonia.num_linee}
                            onChange={handleChange}
                            name="num_linee"
                            type="text"
                            className="block w-4/5 ml-4 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                            required
                        />
                        <label className="label ml-4 text-xs font-bold text-zinc-400 ">
                            Scrivi il Peso di ogni singola porta
                        </label>
                    </div>
                    <div className="w-ful lg:w-1/2 text-zinc-800 flex flex-col justify-between ">
                        <label className="label ml-4 text-lg font-bold ">
                            Di quanti interni hai bisogno? *
                        </label>
                        <input
                            value={telefonia.num_interni}
                            onChange={handleChange}
                            name="num_interni"
                            type="text"
                            className="block w-4/5 ml-4 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                            required
                        />
                        <label className="label ml-4 text-xs font-bold text-zinc-400 ">
                            Scrivi il Peso di ogni singola porta
                        </label>
                    </div>
                </div>
                <div className="w-full mb-6 px-4">
                    <p className="text-lg my-5 ml-1">
                        Se hai richieste particolari segnali brevemente qui 
                        sotto. *
                    </p>
                    <textarea
                        className="textarea textarea-assoCol bg-orange-100 w-full"
                        placeholder="Scrivi qui..."
                        name="richiesta"
                        onChange={handleChange}
                    ></textarea>
                </div>
            </div>
        </div>
    );
};

export default Telefonia;

const impianto = ["Analogico", "Voip", "Misto"];
