import React, { useEffect, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Layout/Header";
import GoToTop from "./components/UI/Button/GoToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Eventi from "./Routes/Eventi/Eventi";
import { auth, database } from "./Utils/firebase_config";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import ComponiKit from "./Utils/componiKit/ComponiKit";

import Footer from "./components/Organism/Footer/Footer";
import User from "./Routes/User/User";

import { onAuthStateChanged } from "firebase/auth";
import { AdminRoutes } from "./Routes/admin/AdminRoutes";

import Cookie from "./Utils/CookieConsent";
import BrandRoutes from "./Routes/Brand/BrandRoutes";
import { routesList } from "./Utils/routes/routesList";

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUser] = useState(null);
  const [livello, setLivello] = useState("");
  const [brandsState, setBrandsState] = useState([]);

  const authChange = () => {
    onAuthStateChanged(auth, (user) => {
      user ? setCurrentUser(user) : setCurrentUser("ospite");
    });
  };
  useEffect(() => {
    authChange();
    if (
      currentUser === "ospite" ||
      currentUser === null ||
      currentUser === undefined
    )
      return;
    if (currentUser.uid) {
      const userRef = collection(database, "user");

      const q = query(userRef, where("uid", "==", `${currentUser.uid}`));
      onSnapshot(q, (snapshot) => {
        const account = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setUser(account);
        setLivello(account[0].livello);
      });
    }
  }, [currentUser]);

  return (
    <div>
      <div className="w-screen min-h-screen">
        <Header livello={livello} brandsState={brandsState} />
        <main>
          <Routes>
            <Route
              path="/*"
              element={
                <BrandRoutes
                  livello={livello}
                  brandsState={brandsState}
                  setBrandsState={setBrandsState}
                  user={user}
                />
              }
            />
            <Route path="/eventi" element={<Eventi user={user} />} />
            <Route
              path="/admin/*"
              element={
                <AdminRoutes livello={livello} setLivello={setLivello} />
              }
            />
            <Route
              exact
              path="/preventivo"
              element={<ComponiKit user={user} />}
            />
            <Route path="/user" element={<User user={user} />} />

            {routesList.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
                brandsState={brandsState}
                setBrandsState={setBrandsState}
                user={user}
                setUser={setUser}
              />
            ))}
          </Routes>
        </main>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          closeOnClick
          draggable
          pauseOnHover
        />
        <GoToTop />
      </div>
      <Cookie />
      <Footer />
    </div>
  );
}

export default App;
