import React from "react";
import Cavi from "./Cavi";
import Contatti from "./Contatti";
import DispSonori from "./DispSonori";
import Perimetrale from "./Perimetrale";
import RivelatoriEsterni from "./RivelatoriEsterni";
import RivelatoriInterni from "./RivelatoriInterni";
import Tastiere from "./Tastiere";
import Telecomando from "./Telecomando";

const Antintrusione = ({
    setOpen,
    open,
    antintrusione,
    setAntintrusione,
    handleUp,
    handleUpload,
    progress,
    upload,
}) => {
    //handler Antintrusione
    const handleAntintrusione = (e) => {
        setAntintrusione({
            ...antintrusione,
            [e.target.name]: e.target.value,
        });
    };

    const handleCheckAnti = (e) => {
        if (
            e.target.id === "dispositivi" &&
            (e.target.name !== "Inseritore" || e.target.name !== "App")
        ) {
            setOpen({ ...open, [e.target.name]: e.target.checked });
            if (e.target.checked) {
                setAntintrusione({
                    ...antintrusione,
                    dispositivi: [...antintrusione.dispositivi, e.target.title],
                });
            } else {
                const index = antintrusione.dispositivi.indexOf(e.target.name);
                antintrusione.dispositivi.splice(index, 1);
                setAntintrusione({
                    ...antintrusione,
                    dispositivi: [...antintrusione.dispositivi],
                });
            }
        }
        if (e.target.name === "Inseritore" || e.target.name === "App") {
            setAntintrusione({ ...antintrusione, [e.target.name]: "si" });
        }
    };

    const handleOrgani = (e) => {
        if (e.target.checked) {
            setAntintrusione({
                ...antintrusione,
                organi: [...antintrusione.organi, e.target.name],
            });
            setOpen({ ...open, [e.target.name]: true });
        } else {
            const index = antintrusione.organi.indexOf(e.target.name);
            antintrusione.organi.splice(index, 1);
            setAntintrusione({
                ...antintrusione,
                organi: [...antintrusione.organi],
            });
            setOpen({ ...open, [e.target.name]: false });
        }
    };
    const handleModuli = (e) => {
        if (e.target.checked) {
            setAntintrusione({
                ...antintrusione,
                moduli: [...antintrusione.moduli, e.target.name],
            });
        } else {
            const index = antintrusione.moduli.indexOf(e.target.name);
            antintrusione.moduli.splice(index, 1);
            setAntintrusione({
                ...antintrusione,
                moduli: [...antintrusione.moduli],
            });
        }
    };

    const handleTelecomando = (e) => {
        setAntintrusione({
          ...antintrusione,
          telecomando: e.target.value,
        });
    };
    const handleDisp = (e) => {
        setAntintrusione({
            ...antintrusione,
            disp_sonori: [
                ...antintrusione.disp_sonori,
                [e.target.name] + " : " + e.target.value,
            ],
        });
    };
    const handleBatterie = (e) => {
        if (e.target.checked) {
            setAntintrusione({
                ...antintrusione,
                batterie: [...antintrusione.batterie, e.target.name],
            });
        } else {
            const index = antintrusione.batterie.indexOf(e.target.name);
            antintrusione.batterie.splice(index, 1);
            setAntintrusione({
                ...antintrusione,
                batterie: [...antintrusione.batterie],
            });
        }
    };
    // handler-------------------------
    const handleContatti = (e) => {
        if (e.target.value === "") {
            return;
        }
        setAntintrusione({
            ...antintrusione,
            contatti: [
                ...antintrusione.contatti,
                [e.target.name] + " : " + e.target.value,
            ],
        });
    };
    const handleInterni = (e) => {
        if (e.target.value === "") {
            return;
        }

        setAntintrusione({
            ...antintrusione,
            rivelatori_interni: [
                ...antintrusione.rivelatori_interni,
                [e.target.name] + " : " + e.target.value,
            ],
        });
    };

    const handleEsterni = (e) => {
        if (e.target.value === "") {
            return;
        }
        setAntintrusione({
            ...antintrusione,
            rivelatori_esterni: [
                ...antintrusione.rivelatori_esterni,
                [e.target.name] + " : " + e.target.value,
            ],
        });
    };
    const handlePerimetrale = (e) => {
        if (e.target.value === "") {
            return;
        }
        setAntintrusione({
            ...antintrusione,
            perimetrale: [[e.target.name] + " : " + e.target.value],
        });
    };

    const handleTastiere = (e) => {
        if (e.target.value === "") {
            return;
        }
        setAntintrusione({
            ...antintrusione,
            tastiere: [
                ...antintrusione.tastiere,
                [e.target.title] + " : " + e.target.value,
            ],
        });
    };
    const handleCavi = (e) => {
      if (e.target.value === "") {
        return;
      }
      setAntintrusione({
        ...antintrusione,
        cavi: [...antintrusione.cavi, [e.target.name] + " : " + e.target.value],
      });
    };
      return (
        <div className="w-full bg-assoBg text-zinc-800 pb-8">
          <div className="divider bg-zinc-200 h-1"></div>
          <div className="flex w-full ml-4">
            <div className="w-1/2">
              <p className="text-xl text-black">
                Richiesta Offerta Antitrusione
              </p>
            </div>
          </div>
          <div className="divider bg-zinc-200 h-1"></div>
          <div className="text-zinc-800 flex flex-col justify-between ">
            <label className="label pl-4 text-lg font-bold ">
              Che tipo di struttura vuoi proteggere ?
            </label>
            <div className="w-full h-full flex flex-col mb-6 ml-1 lg:flex-row lg:items-center lg:space-x-12 ">
              <div className="form-control pl-4 mt-2">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    id="struttura"
                    name="struttura"
                    value="residenziale"
                    onChange={handleAntintrusione}
                    className="w-5 h-5 ring-opacity-0"
                  />
                  <span className="text-zinc-800 pl-4">
                    Struttura Residenziale
                  </span>
                </label>
              </div>
              <div className="form-control flex pl-4 mt-2">
                <label className="cursor-pointer">
                  <input
                    type="radio"
                    id="struttura"
                    name="struttura"
                    value="industriale"
                    onChange={handleAntintrusione}
                    className="w-5 h-5"
                  />
                  <span className="text-zinc-800 pl-4">
                    Struttura Industriale
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="divider bg-zinc-200 h-1"></div>
          <div className="w-1/2 h-full  text-zinc-800 flex flex-col justify-between">
            <label className="label ml-4 text-lg font-bold ">
              Che tipo di centrale vuoi utilizzare?
            </label>
          </div>
          <div className="w-full h-full flex flex-col lg:flex-row lg:items-center lg:space-x-12">
            <div className="form-control flex ml-5 mt-2">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  name="centrale"
                  value="cablata"
                  onChange={handleAntintrusione}
                  className="w-5 h-5 ring-opacity-0 "
                />
                <span className="text-zinc-800 ml-4">Cablata</span>
              </label>
            </div>
            <div className="form-control flex ml-5 mt-2">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  name="centrale"
                  value="wireless"
                  onChange={handleAntintrusione}
                  className="w-5 h-5"
                />
                <span className="text-zinc-800 ml-4">Wireless</span>
              </label>
            </div>
            <div className="form-control flex ml-5 mt-2">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  name="centrale"
                  value="Ibrida"
                  onChange={handleAntintrusione}
                  className="w-5 h-5"
                />
                <span className="text-zinc-800 ml-4">
                  Ibrida (Cablata + Wireless)
                </span>
              </label>
            </div>
          </div>
          <div className="form-control w-full flex-col items-center lg:flex-row ml-2 mt-10 ">
            <div className="w-full lg:w-1/2 pl-4">
              <label className="label capitalize text-lg font-bold ">
                marchio preferito?
              </label>
              <select
                name="marchio"
                onChange={handleAntintrusione}
                className="select border-assoCol w-full max-w-xs dark:bg-white"
              >
                <option className="bg-assoBg selection:bg-assoCol">
                  Scegli
                </option>
                {brand.map((list, index) => (
                  <option
                    key={index}
                    value={list}
                    className="bg-assoBg selection:bg-assoCol "
                  >
                    {list}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-full mt-4 pl-4 lg:w-1/2 lg:mt-0">
              <label className="label capitalize text-lg font-bold ">
                Numero di zone da proteggere
              </label>
              <select
                name="zone"
                onChange={handleAntintrusione}
                className="select border-assoCol w-full max-w-xs dark:bg-white"
              >
                <option className="bg-assoBg selection:bg-assoCol">
                  Scegli
                </option>
                {zone.map((zo, index) => (
                  <option
                    key={index}
                    value={zo}
                    className="bg-assoBg selection:bg-assoCol"
                  >
                    {zo}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="divider bg-zinc-200 h-1"></div>
          <div className="w-full ml-4 mt-5">
            <label className="label capitalize text-lg font-bold ">
              Dispositivi da utilizzare sulla tua centrale *
            </label>
            <div className="w-full h-full flex items-center mb-5">
              <div className="w-full flex flex-col ml-4 mt-10 lg:flex-row lg:space-x-10">
                {dispositivi.map((option, index) => (
                  <label className="cursor-pointer" key={index}>
                    <input
                      id="dispositivi"
                      type="checkbox"
                      name={option.id}
                      title={option.name}
                      onChange={handleCheckAnti}
                      className="w-5 h-5 ring-opacity-0 "
                    />
                    <span className="text-zinc-800 ml-4">{option.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          {open.contatti && (
            <Contatti
              handle={handleAntintrusione}
              handleCheck={handleCheckAnti}
              handleContatti={handleContatti}
              antintrusione={antintrusione}
            />
          )}
          {/*//////////////////RIVELATORI INTERNI///////////////// */}
          {open.interni && <RivelatoriInterni handle={handleInterni} />}
          {/*////////////////RIVELATORI ESTERNI////////////// */}
          {open.esterni && <RivelatoriEsterni handle={handleEsterni} />}
          {/*///////////PROTEZIONE PERIMETRALE///////////// */}
          {open.perimetrale && (
            <Perimetrale
              antintrusione={antintrusione.perimetrale}
              handler={handlePerimetrale}
              handleUpload={handleUpload}
              handleUp={handleUp}
              progress={progress}
              name="perimetrale"
              upload={upload}
            />
          )}
          {/*"////////////////////////////////////////////////"*/}
          <div className="divider bg-zinc-200 h-1 "></div>
          <p className="text-lg text-bold pl-4 mt-10 font-bold">
            Scegli i tuoi organi di comando? *
          </p>
          <div className="w-full h-full flex items-center mb-5">
            <div className="w-full flex flex-col mt-10 lg:flex-row ml-8 lg:space-x-10">
              {organi.map((option, index) => (
                <label className="cursor-pointer" key={index}>
                  <input
                    id="organi"
                    type="checkbox"
                    name={option}
                    onChange={handleOrgani}
                    className="w-5 h-5 ring-opacity-0 "
                  />
                  <span className="text-zinc-800 ml-4">{option}</span>
                </label>
              ))}
            </div>
          </div>
          {open.Tastiere && <Tastiere handler={handleTastiere} />}
          {open.Telecomando && (
            <Telecomando
              handler={handleTelecomando}
              antintrusione={antintrusione}
            />
          )}
          <div className="divider bg-zinc-200 h-1 "></div>
          <p className="text-lg text-bold ml-4 mt-10 font-bold">
            Scegli i tuoi dispositivi sonori *
          </p>
          <div className="w-full h-full flex items-center mb-5">
            <div className="w-full flex flex-col ml-4 mt-4 lg:flex-row lg:space-x-10">
              <DispSonori handleDisp={handleDisp} />
            </div>
          </div>

          <div className="divider bg-zinc-200 h-1 "></div>
          <p className="text-lg text-bold ml-4 mt-10 font-bold">
            Scegli i tuoi moduli di comunicazione
          </p>
          <div className="w-full h-full flex items-center mb-5">
            <div className="w-full flex flex-col pl-8 mt-10 lg:flex-row lg:space-x-10">
              {moduli.map((option, i) => (
                <label className="cursor-pointer" key={i}>
                  <input
                    id="moduli"
                    type="checkbox"
                    name={option}
                    onChange={handleModuli}
                    className="w-5 h-5 ring-opacity-0 "
                  />
                  <span className="text-zinc-800 ml-4">{option}</span>
                </label>
              ))}
            </div>
          </div>
          <p className="text-lg text-bold ml-4 mt-10 font-bold">
            Alimentatore supplementare
          </p>
          <div className="w-full h-full flex items-center mb-5">
            <div className="w-full flex flex-col pl-8 mt-10 lg:flex-row lg:space-x-10">
              {batterie.map((option, idx) => (
                <label className="cursor-pointer" key={idx}>
                  <input
                    id="batterie"
                    type="checkbox"
                    name={option}
                    onChange={handleBatterie}
                    className="w-5 h-5 ring-opacity-0 "
                  />
                  <span className="text-zinc-800 ml-4">{option}</span>
                </label>
              ))}
            </div>
          </div>
          <p className="text-lg text-bold ml-4 mt-10 font-bold">
            Hai Bisogno di cavi ?
          </p>
          <div className="w-full h-full flex items-center mb-5">
            <div className="w-full flex flex-col ml-4 my-4 lg:flex-row lg:space-x-10">
              <Cavi handleCavi={handleCavi} />
            </div>
          </div>
        </div>
      );
};

export default Antintrusione;

const brand = ["Ksenia", "Risco", "Ajax", "Inim", "Pess", "Amc", "Hikvision"];
const zone = ["1 a 8", "8 a 40", "40 a 100", "Più di 100"];
const dispositivi = [
    {
        id: "contatti",
        name: " Contatti",
    },
    { id: "interni", name: " Rivelatori interni" },
    { id: "esterni", name: " Rivelatori esterni" },
    { id: "perimetrale", name: " Protezione perimetrale" },
];

const organi = ["Tastiere", "Inseritore", "Telecomando", "App"];

const moduli = [" PSTN", " GSM/GPRS", " IP"];
const batterie = ["Centrale", "Sirena Esterna", "Alimetatore supplementare"];
