import React from "react";

const AutomazioneData = ({ data }) => {
    return (
        <div className="w-full flex flex-col my-4 space-y-2">
            <p className="text-xl  uppercase font-bold mt-2">Automazione</p>
            <div className="divider after:bg-assoCol before:bg-assoCol"></div>

            <div className="flex flex-col w-full h-full space-y-2">
                <p className="capitalize font-bold">
                    marchio preferito :
                    <span className="font-normal pl-2">{data.marchio}</span>
                </p>
                <p className="capitalize font-bold">
                    tipo di impianto :
                    <span className="font-normal pl-2">{data.impianto}</span>
                </p>
                <p className="capitalize font-bold">
                    tipo di apertura:
                    <span className="font-normal pl-2">{data.apertura}</span>
                </p>
                {data.apertura === "Scorrevole" && (
                    <div>
                        <p className="capitalize font-bold">
                            Lunghezza Cancello :
                            <span className="font-normal pl-2">
                                {data.lunghezza_cancello}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Numero Ante :
                            <span className="font-normal pl-2">
                                {data.num_ante}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Peso Ante :
                            <span className="font-normal pl-2">
                                {data.peso_ante}
                            </span>
                        </p>
                    </div>
                )}
                {data.apertura === "Battente" && (
                    <div>
                        <p className="capitalize font-bold">
                            Dimensione Anta :
                            <span className="font-normal pl-2">
                                {data.dim_anta}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Numero Ante :
                            <span className="font-normal pl-2">
                                {data.num_ante}
                            </span>
                        </p>
                    </div>
                )}
                {data.apertura === "Barriere(Porte Veloci)" && (
                    <div>
                        <p className="capitalize font-bold">
                            Lunghezza Varco:
                            <span className="font-normal pl-2">
                                {data.lunghezza_varco}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Numero di Ante Scorrevoli :
                            <span className="font-normal pl-2">
                                {data.num_ante_scorr}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Peso Porte :
                            <span className="font-normal pl-2">
                                {data.peso_porte}
                            </span>
                        </p>
                        <p className="capitalize font-bold">
                            Tipo Porte :
                            <span className="font-normal pl-2">
                                {data.tipo_porte}
                            </span>
                        </p>
                    </div>
                )}
                <p className="capitalize font-bold">
                    tipo di installazione :
                    <span className="font-normal pl-2">
                        {data.installazione}
                    </span>
                </p>
            </div>
            <div className="flex flex-col w-full h-full"></div>
            <div className="flex flex-col w-full h-full">
                {data.accessori && (
                    <p className="capitalize font-bold mr-3">Accessori :</p>
                )}
                <div className="flex flex-col lg:flex-row lg:flex-wrap px-2 ">
                    {data.accessori &&
                        data.accessori.map((list, index) => (
                            <p
                                className="font-normal list-item mx-4"
                                key={index}
                            >
                                {list}
                            </p>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default AutomazioneData;
