import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../Utils/firebase_config";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Raggiungici from "./Raggiungici";

const Rubrica = ({ filiale }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState(false);
  const [dipendenti, setDipendenti] = useState([]);
  useEffect(() => {
    const dipRef = collection(database, `${filiale.filiale}`);

    const qry = query(dipRef, orderBy("id_dip", "asc"));
    onSnapshot(qry, (snapshot) => {
      const dip = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setDipendenti(dip);
    });
  }, [filiale]);

  return (
    <div className="w-full h-full flex justify-start ">
      <button
        className="badge badge-outline  glass text-[11px] uppercase  full  mt-4 
                         hover:bg-assoCol hover:text-white hover:scale-105 text-black
                       "
        type="button"
        onClick={() => setOpen(true)}
      >
        Rubrica dipendenti
      </button>

      {open && (
        <div
          className="fixed inset-0 z-50 pt-32 w-full overflow-scroll h-full flex
          items-center justify-center bg-black bg-opacity-60"
        >
          <div className="h-full flex flex-col items-center justify-center mt-32 divide-gray-500 rounded-lg">
            <div className=" w-full p-10 flex-col">
              <div className="w-full overflow-scroll ">
                <div key={filiale.id} className="w-full flex flex-col ">
                  <div className="overflow-x-auto mt-32 m-5 text-black dark:text-black">
                    <table className="table hidden lg:block w-full mt-5 ">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Nome</th>
                          <th>Mansione</th>
                          <th>Telefono</th>
                          <th>Email</th>
                          <th>QR Code</th>
                        </tr>
                      </thead>
                      <tbody className="text-[13px] text-black">
                        {dipendenti.length > 0 &&
                          dipendenti.map((list) => (
                            <tr key={list.id}>
                              <th>{list.id_dip}</th>
                              <td>{list.nome}</td>
                              <td>{list.mansione}</td>

                              <td>
                                <a href={`tel:+${list.telefono}`}>
                                  {list.telefono}
                                </a>
                              </td>
                              <td>
                                <a href={`mailto:${list.email}`}>
                                  {list.email}
                                </a>
                              </td>
                              <td>
                                <button
                                  bg="transparent"
                                  _hover={{
                                    bgColor: "transparent",
                                  }}
                                  _focus={{
                                    bgColor: "transparent",
                                  }}
                                  onClick={onOpen}
                                >
                                  {list.qrcode ? (
                                    <div className="w-[50px] h-[50px]">
                                      <img
                                        src={list.qrcode}
                                        alt="qrcode"
                                        className=" w-full h-full duration-200"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </button>

                                <Modal isOpen={isOpen} onClose={onClose}>
                                  <ModalContent>
                                    <ModalHeader>{list.nome}</ModalHeader>
                                    <ModalCloseButton />
                                    {list.qrcode ? (
                                      <div className="w-full h-full">
                                        <img
                                          src={list.qrcode}
                                          alt="qrcode"
                                          className=" w-full h-full duration-200"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </ModalContent>
                                </Modal>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="w-full h-full flex flex-col  items-center space-y-1 lg:hidden">
                    {dipendenti &&
                      dipendenti.map((list, i) => (
                        <div
                          key={i}
                          className="w-full mt-4 px-4 py-4 flex-col justify-center bg-white bg-opafiliale-90 rounded"
                        >
                          <p className="font-bold text-lg border-b bg-white border-assoCol">
                            {list.nome}
                          </p>
                          <div className="w-full flex justify-between items-center">
                            <p className="text-xs">{list.mansione}</p>
                            <p className="text-xs">{list.telefono}</p>
                          </div>
                          <p className="text-xs">{list.email}</p>
                        </div>
                      ))}
                  </div>

                  <div className="w-full mt-6 flex space-x-5 ">
                    <Raggiungici map={filiale.map} />

                    <button
                      onClick={() => setOpen(false)}
                      className="btn  glass w-1/2 rounded-lg text-lg
                                              hover:bg-assoCol"
                    >
                      Chiudi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Rubrica;
