import React, { Fragment } from "react";
import {
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    Menu,
    MenuItem,
    MenuButton,
    MenuList,
    Text,
    Box,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import { menulist } from "../../../Utils/menulist";
import AppInput from "../Input/AppInput";
import AccediButton from "../Button/AccediButton";
import logo from "../../Layout/AssoLogo.png";
import { IoChevronDownCircleOutline } from "react-icons/io5";

function DrawerExample({ brandsState, livello }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    return (
        <div>
            <div className="w-full">
                <div className="w-full flex items-center  justify-between ">
                    <div ref={btnRef} className="w-1/3" onClick={onOpen}>
                        <label
                            htmlFor="sidebar-id"
                            className=" hover:bg-transparent cursor-pointer flex justify-start pl-[10px]  bg-transparent border-none drawer-button"
                        >
                            <svg
                                className="block text-white fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 512 512"
                            >
                                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                            </svg>
                        </label>
                    </div>
                    <div className="w-1/3     h-full  flex justify-center">
                        <button className="btn bg-white  border-0 rounded-none px-3">
                            <Link to={"/"}>
                                <img
                                    src={logo}
                                    alt="asso_logo"
                                    className="  max-w-[3rem]"
                                />
                            </Link>
                        </button>
                    </div>

                    <div className="w-1/3 flex  justify-end ">
                        <AppInput brandsState={brandsState} />
                        <AccediButton />
                    </div>
                </div>
            </div>

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent bg="#282524e7">
                    <DrawerCloseButton color="white" ml="10px" />
                    <ul className="p-4 pt-10">
                        {menulist.map((item, index) => {
                            return (
                              <div
                                className={
                                  livello !== "Admin" && item.page === "Admin"
                                    ? "hidden"
                                    : "block "
                                }
                                key={index}
                              >
                                {item.list ? (
                                  <Menu>
                                    {({ isOpen }) => (
                                      <Fragment>
                                        <Box
                                          fontSize={16}
                                          h="50px"
                                          _hover={{
                                            backgroundColor: "#ff7b34",
                                          }}
                                          fontWeight="semibold"
                                          pl="16px"
                                          color="white"
                                          textAlign={"left"}
                                          w="full"
                                          alignItems={"center"}
                                          display="flex"
                                          justifyContent="space-between"
                                          bg="transparent"
                                        >
                                          <Link
                                            onClick={onClose}
                                            to={item.link}
                                          >
                                            {item.page}
                                          </Link>

                                          <MenuButton
                                            as={Button}
                                            _focus={{
                                              bg: "transparent",
                                            }}
                                            _hover={{
                                              bg: "transparent",
                                            }}
                                            bg="transparent"
                                            rightIcon={
                                              <IoChevronDownCircleOutline
                                                isActive={isOpen}
                                              />
                                            }
                                          ></MenuButton>
                                        </Box>

                                        <MenuList
                                          border={"0"}
                                          w="320px"
                                          style={{
                                            backgroundColor: "#000",
                                          }}
                                          // bg="#191919f9"
                                          p={4}
                                          color="white"
                                        >
                                          {item.list &&
                                            item.list.map((item, idx) => (
                                              <MenuItem
                                                key={idx}
                                                _hover={{
                                                  backgroundColor: "#ff7b34",
                                                  color: "#ffffff",
                                                }}
                                                _focus={{
                                                  bg: "#555555df",
                                                }}
                                                bg="#000"
                                                p="3"
                                              >
                                                <Link
                                                  onClick={onClose}
                                                  to={item.link}
                                                >
                                                  {item.title}
                                                </Link>
                                              </MenuItem>
                                            ))}
                                        </MenuList>
                                      </Fragment>
                                    )}
                                  </Menu>
                                ) : (
                                  <Box
                                    w="full"
                                    textAlign="left"
                                    fontSize={16}
                                    h="50px"
                                    _hover={{
                                      backgroundColor: "#ff7b34",
                                      color: "#ffffff",
                                    }}
                                    px="16px"
                                    alignItems={"center"}
                                    display={"flex"}
                                    borderRadius="2px"
                                    fontWeight="semibold"
                                    color="white"
                                    bg="transparent"
                                  >
                                    <Link onClick={onClose} to={item.link}>
                                      <Text textAlign="left" w="full">
                                        {item.page}
                                      </Text>
                                    </Link>
                                  </Box>
                                )}
                              </div>
                            );
                        })}
                    </ul>
                </DrawerContent>
            </Drawer>
        </div>
    );
}
export default DrawerExample;
