import React from "react";

const Telecomando = ({ handler, antintrusione }) => {
    return (
        <div className="w-full m-4 pt-10">
            <span className="text-zinc-700 font-semibold" htmlFor="username">
                Di quanti Telecomandi hai bisogno? *
            </span>
            <input
                id="text"
                name="telecomando"
                value={antintrusione.telecomando}
                onChange={handler}
                type="text"
                className="block w-1/12 px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                required
            />
        </div>
    );
};

export default Telecomando;
