import React from "react";

const TastiereData = ({ data }) => {
    return (
        <div className="w-full flex flex-col">
            {data.map((tastiera, i) => (
                <p key={i}>{tastiera}</p>
            ))}
        </div>
    );
};

export default TastiereData;
