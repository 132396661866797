import React, { useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { AiFillEdit } from "react-icons/ai";
import JoditEditor from "jodit-react";

import { toast } from "react-toastify";
import { database } from "../../../Utils/firebase_config";
import { Input } from "../Input/InputEdit";

const EditEventi = ({ info }) => {
  const [edit, setEdit] = useState({
    ...info,
  });
  const editor = useRef(null);
  const [content, setContent] = useState(null);
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const handleChange = (e) => {
    setEdit({
      ...edit,
      [e.target.name]: e.target.value,
      descrizione: content,
    });
  };
  const handleEdit = async () => {
    const ref = doc(database, "eventi", info.id);
    try {
      await updateDoc(ref, {
        nome: edit.nome,
        luogo: edit.luogo,
        date: edit.date,
        linkEvento: edit.linkEvento,
        email: edit.email,
        maxGuest: Number(edit.maxGuest),
        image: edit.image,
        descrizione: content,
        totPartecipanti: 0,
        partecipanti: edit.partecipanti,
      });
      toast.success("Aggiornato");
    } catch (err) {
      toast.error("Oops!Riprova");
    }
  };
  return (
    <div className="w-full h-full flex justify-center items-center">
      <label htmlFor={edit.nome} className="modal-button">
        <AiFillEdit
          classname="w-full h-full rounded 
                          hover:bg-assoCol"
        />
      </label>
      <input type="checkbox" id={edit.nome} className="modal-toggle" />
      <div className="modal  w-full">
        <div className="w-2/3 bg-white  rounded-xl p-8 ">
          <form className="w-full flex flex-col items-center justify-center ">
            <div className="w-full flex flex-col justify-start items-center">
              <div className="w-full flex flex-col lg:flex-row space-x-4">
                <Input
                  type="text"
                  name="nome"
                  title="Nome dell' Evento"
                  value={edit.nome}
                  handler={handleChange}
                  required
                />
                <Input
                  type="text"
                  name="luogo"
                  title="Location"
                  value={edit.luogo}
                  handler={handleChange}
                  required
                />

                <Input
                  title="Data dell' Evento"
                  type="date"
                  name="date"
                  value={edit.date}
                  handler={handleChange}
                  required
                />
              </div>
              <div className="w-full flex flex-col lg:flex-row space-x-4">
                <Input
                  type="text"
                  name="linkEvento"
                  value={edit.linkEvento}
                  handler={handleChange}
                  title="Inserisci il link dell'Evento / Email"
                  required
                />
                <Input
                  type="text"
                  name="email"
                  value={edit.email}
                  handler={handleChange}
                  title="Inserisci l' email dell' Organizzatore"
                  required
                />
                <Input
                  type="text"
                  name="maxGuest"
                  value={edit.maxGuest}
                  handler={handleChange}
                  title="Numero massimo di partecipanti"
                  required
                />
              </div>
              <div className="w-full flex flex-col lg:flex-row space-x-5">
                <Input
                  type="text"
                  name="image"
                  value={edit.image}
                  handler={handleChange}
                  title="Inserisci il link dell' immagine"
                  required
                />

                <div className="w-[10rem]">
                  {edit.image && (
                    <img
                      src={edit.image}
                      alt="evento"
                      className="w-full h-full"
                    />
                  )}
                </div>
              </div>
              <div className="text-black w-full my-2">
                <JoditEditor
                  ref={editor}
                  value={content}
                  config={config}
                  tabIndex={1} // tabIndex of textarea
                  onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                />
              </div>
            </div>
          </form>
          <div className="w-full flex justify-between mt-4 space-x-1">
            <label
              for={edit.nome}
              className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded 
                          hover:bg-assoCol"
              onClick={handleEdit}
            >
              Aggiorna
            </label>
            <label
              className="w-1/2 btn glass bg-assoCol text-assoBg uppercase font-bold rounded
                          hover:bg-assoCol"
              for={edit.nome}
            >
              Chudi
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEventi;
