import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../../Utils/firebase_config";
import { DelButton } from "../../UI/Button/DelButton";

//import DelContact from "./DelContact";
import EditContatti from "../../UI/Button/EditContatti";

const TableContatti = ({ filiale }) => {
    const [dipendenti, setDipendenti] = useState([]);
    useEffect(() => {
        const dipRef = collection(database, `${filiale}`);
        const qry = query(dipRef, orderBy("id_dip", "asc"));
        onSnapshot(qry, (snapshot) => {
            const dip = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDipendenti(dip);
        });
    }, [filiale]);

    return (
        <div className=" bg-white overflow-x-auto dark:text-black pt-5 rounded-xl">
            <table className="table w-full mt-5">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Mansione</th>
                        <th>Telefono</th>
                        <th>Email</th>
                        <th>QR Code</th>
                        <th>Gestisci</th>
                    </tr>
                </thead>
                <tbody>
                    {dipendenti.length > 0 &&
                        dipendenti.map((list) => (
                            <tr key={list.id}>
                                <th>{list.id_dip}</th>
                                <td>{list.nome}</td>
                                <td>{list.mansione}</td>
                                <td>{list.telefono}</td>
                                <td>{list.email}</td>
                                <td>
                                    {list.qrcode ? (
                                        <img
                                            src={list.qrcode}
                                            alt="qrcode"
                                            className="w-10"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </td>
                                <td>
                                    <div className="w--full flex justify-center items-center">
                                        <DelButton
                                            info={list}
                                            type={filiale}
                                            title={list.nome}
                                            messaggio="Sei sicuro di volere eliminare "
                                        />
                                        <EditContatti
                                            info={list}
                                            type={filiale}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableContatti;
