import {
    collection,
    doc,
    onSnapshot,
    orderBy,
    query,
    updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../../Utils/firebase_config";
import Spinner from "../../UI/Spinner";
import { DelButton } from "../../UI/Button/DelButton";
import EditEventi from "../../UI/Button/EditEventi";
import Partecipanti from "./Partecipanti";

import Moment from "react-moment";
import { HtmlToJSX } from "../../../Utils/utils";

const EventiAdmin = () => {
    const [eventi, setEventi] = useState([]);
    const [loaded, setLoaded] = useState(true);

    //get database data
    useEffect(() => {
        const eventRef = collection(database, "eventi");
        const q = query(eventRef, orderBy("date", "desc"));
        onSnapshot(q, (snapshot) => {
            const events = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setEventi(events);
            setLoaded(false);
        });
    }, []);
    return (
      <div className="w-full h-full flex flex-col items-center justify-center pb-10 overflow-hidden">
        <div className="w-11/12 h-full shadow-2xl rounded-xl">
          {loaded && <Spinner />}
          <div className="w-full ">
            <div className="w-full">
              <p className="dark:bg-zinc-300 p-4 uppercase text-2xl font-bold">
                eventi
              </p>
            </div>
            {eventi.map((list, idx) => (
              <div
                key={idx}
                className="dark:bg-zinc-100 dark:border-b-zinc-800 border-2 flex flex-col lg:flex-row w-full p-4 space-y-4"
              >
                <div className="lg:w-1/3 flex text-black flex-col ">
                  <p className="font-semibold capitalize">
                    {list.date}
                    <Moment format="YYYY/MM/DD">{list.createdAt}</Moment>
                  </p>
                  <p className="font-normal capitalize">
                    evento:
                    <span className="font-semibold pl-2 capitalize">
                      {list.nome}
                    </span>
                  </p>
                  <p className="font-normal capitalize">
                    luogo:
                    <span className="font-semibold capitalize pl-2">
                      {list.luogo}
                    </span>
                  </p>
                  <p className="font-normal capitalize overflow-ellipsis">
                    organizzatore:
                    <span className="font-semibold pl-2 ">{list.email}</span>
                  </p>
                  <p className="font-normal capitalize truncate">
                    link:
                    <span className="font-semibold pl-2 ">
                      {list.linkEvento}
                    </span>
                  </p>
                  <p className="font-normal capitalize">
                    Num Partecipanti:
                    <span className="font-semibold capitalize pl-2">
                      {list.totPartecipanti}/{list.maxGuest}
                    </span>
                  </p>
                  <div className="w-24 pl-8">
                    <Partecipanti
                      joiner={list.partecipanti}
                      id={idx + list.id}
                    />
                  </div>

                  {list.selected ? (
                    <p
                      onClick={() => {
                        const ref = doc(database, "eventi", `${list.id}`);
                        updateDoc(ref, {
                          selected: false,
                        });
                      }}
                      className="font-bold text-green-700 py-2 underline"
                    >
                      selezionato
                    </p>
                  ) : (
                    <p
                      onClick={() => {
                        const ref = doc(database, "eventi", `${list.id}`);
                        updateDoc(ref, {
                          selected: true,
                        });
                      }}
                      className="font-bold text-red-700 cursor-pointer underline hover:text-red-800 py-2"
                    >
                      non selezionato
                    </p>
                  )}
                  <div className="flex cursor-pointer justify-center items-center w-16 ">
                    <DelButton
                      type="eventi"
                      info={list}
                      title={list.nome}
                      kind="icon"
                      messaggio="Sei sicuro di voler eliminare "
                    />
                    <EditEventi info={list} />
                  </div>
                </div>

                <p className="lg:w-1/3 text-justify ">
                  {HtmlToJSX(list.descrizione)}
                </p>
                <div className="lg:w-1/3 flex lg:justify-end">
                  <img
                    src={list.image}
                    alt="evento_img"
                    className="w-1/2 h-full object-contain"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
};

export default EventiAdmin;
