import React from "react";
import CardSection from "../Containers/CardSection";
// import BodyBox from "./BodyBox";

const BodyHeading = ({ user }) => {
  return (
    <div className="w-full bg-white ">
      <div className="flex flex-col lg:px-16 lg:mt-4 mt-14 lg:mt-16  md:px-2 px-14 lg:flex-row  space-y-10 lg:space-y-0  lg:space-x-4 lg:py-16 lg:pb-4">
        <div className="w-full  md:px-10  lg:w-1/3">
          <p className="md:text-4xl text-3xl lg:text-4xl md:pr-3 font-semibold  text-assoCol ">
            Le migliori soluzioni per la sicurezza dei tuoi clienti
          </p>
        </div>
        <div className="w-full text-zinc-800 lg:px-6  px-10 hidden lg:block lg:w-1/3">
          <p className="leading-10 md:leading-8 text-md md:text-md md:px-2 ">
            <b>Asso Sicurezza Spa</b> I confini della distribuzione
            specializzata di apparecchiature per i sistemi di videosorveglianza
            (TVCC), antintrusione e domotica, antincendio ed evacuazione,
            controllo accessi, automazione varchi e cancelli, diffusione sonora,
            videocitofonia e networking, varcano nuove frontiere. Composto da un
            team di professionisti qualificati, Asso Sicurezza, svolge
            un'attività volta ad offrire
          </p>
        </div>
        <div className="w-full  px-10 lg:px-6 text-zinc-800 hidden lg:block lg:w-1/3">
          <p className="leading-10 md:leading-8 text-md md:px-2">
            <b> soluzioni complete</b> e <b>supporto tecnico</b> , ponendo la
            soddisfazione del cliente al primo posto. La continua ricerca di
            nuove tecnologie al fianco dei più importanti produttori nazionali
            ed internazionali, i corsi di formazione tecnica e commerciale,
            l'assistenza diretta post vendita, il supporto commerciale, sono i
            principali servizi messi sempre a disposizione del cliente.{" "}
            <b>Il team di Asso Sicurezza non ti lascia mai solo!!!</b>
          </p>
        </div>
        <div className="lg:hidden md:px-10  ">
          <p className="text-[16px] leading-8 text-justify ">
            <b>Asso Sicurezza Spa</b> I confini della distribuzione
            specializzata di apparecchiature per i sistemi di videosorveglianza
            (TVCC), antintrusione e domotica, antincendio ed evacuazione,
            controllo accessi, automazione varchi e cancelli, diffusione sonora,
            videocitofonia e networking, varcano nuove frontiere. Composto da un
            team di professionisti qualificati, Asso Sicurezza, svolge
            un'attività volta ad offrire <b> soluzioni complete</b> e{" "}
            <b>supporto tecnico</b> , ponendo la soddisfazione del cliente al
            primo posto. La continua ricerca di nuove tecnologie al fianco dei
            più importanti produttori nazionali ed internazionali, i corsi di
            formazione tecnica e commerciale, l'assistenza diretta post vendita,
            il supporto commerciale, sono i principali servizi messi sempre a
            disposizione del cliente.{" "}
            <b>Il team di Asso Sicurezza non ti lascia mai solo!!!</b>
          </p>
        </div>
      </div>
      <div className="divider pb-0 mb-0"></div>
      <div className="w-full  h-full bg-stone-300/10 py-10 backdrop-blue-lg">
        {<CardSection data={"data"} user={user} />}
      </div>
    </div>
  );
};

export default BodyHeading;
