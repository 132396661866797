import React from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineYoutube,
} from "react-icons/ai";
const SocialMedia = () => {
  return (
    <div>
      <div
        className={` sm:flex flex justify-center space-x-1  items-center 
                                    lg    lg:pb-0`}
      >
        <a
          href="https://www.linkedin.com/company/asso-sicurezza/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <AiOutlineLinkedin
            className="hover:scale-105 "
            size={28}
            color="white"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCWQ687BH-d93qCGlSr23ByQ?app=desktop"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <AiOutlineYoutube
            className="hover:scale-105 "
            size={28}
            color="white"
          />
        </a>
        <a
          href="https://www.facebook.com/assosicurezzaspa/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <AiOutlineFacebook
            className="hover:scale-105 "
            size={28}
            color="white"
          />
        </a>
        <a
          href="https://www.instagram.com/assosicurezza/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <AiOutlineInstagram
            className="hover:scale-105 "
            size={28}
            color="white"
          />
        </a>
      </div>
    </div>
  );
};

export default SocialMedia;
