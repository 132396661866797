import React, { Fragment } from "react";

const Cavi = ({ handleCavi }) => {
    return (
        <div className="w-full h-full flex">
            <div className="w-full flex flex-col lg:flex-row flex-wrap">
                {cavi.map((option, index) => (
                    <label
                        className="cursor-pointer w-full flex items-center mb-1"
                        key={index}
                    >
                        {option.title !== "Altro" ? (
                            <Fragment>
                                <div className="w-4/5">
                                    <span className="text-zinc-800 pl-4">
                                        {option.title}
                                    </span>
                                </div>
                                <div className="w-1/5 flex">
                                    <span className="text-zinc-800 mx-4">
                                        Qnt
                                    </span>
                                    <input
                                        name={option.title}
                                        onMouseLeave={handleCavi}
                                        type="text"
                                        className="w-10 h-5 ring-opacity-0 "
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <div className="text-zinc-800 w-full mx-4 flex items-center justify-between lg:justify-start lg:space-x-8">
                                <p>{option.title}</p>
                                <input
                                    name={option.title}
                                    onMouseLeave={handleCavi}
                                    type="text"
                                    className="w-10/12 lg:ml-4 h-5 ring-opacity-0 rounded"
                                />
                            </div>
                        )}
                    </label>
                ))}
            </div>

            <div className="divider bg-zinc-200 h-1 "></div>
        </div>
    );
};

export default Cavi;

const cavi = [
    {
        id: "cavi2",
        title: "2x0.50 + 2x0.22",
    },
    {
        id: "cavi4",
        title: "2x0.50 + 4x0.22",
    },
    {
        id: "cavi6",
        title: "2x0.50 + 6x0.22",
    },
    {
        id: "cavi8",
        title: "2x0.50 + 8x0.22",
    },
    { id: "caviAltro", title: "Altro" },
];
