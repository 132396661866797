import React, { useRef } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { auth } from "../Utils/firebase_config";

const ResetPass = () => {
    const user = auth;
    const email = useRef(null);

    if (user === null || user === undefined) {
        return toast.error("Contattaci!");
    }

    const sendReset = (e) => {
        if (!user) return toast.error("Contattaci!");
        e.preventDefault();

        sendPasswordResetEmail(user, email.current.value)
            .then(() => {
                toast.success(
                    `Hai ricevuto il link di reset al tuo indirizzo ${email.current.value},`
                );
            })
            .catch((error) => {
                toast.error("Ops, qualcosa  è andato storto!", error);
            });
    };
    return (
        <div className="w-full h-[60vh] text-zinc-800 bg-assoBg flex mt-10  md:mt-20 flex-col sm:justify-center items-center pt-6 sm:pt-0">
            <div className="w-full sm:max-w-md p-5 mx-auto">
                <h2 className="mb-12 text-center text-4xl font-bold uppercase">
                    reset password
                </h2>
                <form>
                    <div className="mb-4">
                        <label className="block mb-1" htmlFor="email">
                            Indirizzo E-mail
                        </label>
                        <input
                            type="text"
                            name="email"
                            ref={email}
                            className="py-2 px-3 border border-gray-300 focus:border-red-300 focus:outline-none focus:ring focus:ring-red-200 focus:ring-opacity-50 rounded-md shadow-sm disabled:bg-gray-100 mt-1 block w-full"
                        />
                    </div>

                    <div className="mt-6">
                        <button
                            onClick={sendReset}
                            className="w-full bg-assoCol inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold capitalize text-white hover:bg-zinc-300  focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 transition"
                        >
                            Invia
                        </button>
                        <div className="flex justify-between items-center">
                            <div className="mt-6 text-center">
                                <Link to="/registrati" className="underline">
                                    Registrati
                                </Link>
                            </div>
                            <div className="mt-6 text-center">
                                <Link to="/login" className="underline">
                                    Login
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPass;
