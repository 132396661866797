import React from "react";

import Gestione from "../../components/Layout/Gestione";

import FormGestioneAccount from "../../components/Forms/FormGestioneAccount";
import Accounts from "../../components/Organism/Accounts";

const GestioneAccounts = ({setLivello}) => {
    return (
        <Gestione title="Utenti">
            <div className="w-11/12 h-full mb-20">
                <FormGestioneAccount setLivello={setLivello}/>
            </div>
            <div className="w-full h-full ">
                <Accounts />
            </div>
        </Gestione>
    );
};

export default GestioneAccounts;
