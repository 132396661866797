import React, { useEffect, useState } from "react";
import {
    addDoc,
    collection,
    onSnapshot,
    orderBy,
    query,
    Timestamp,
} from "firebase/firestore";
import { database } from "../../Utils/firebase_config";
import { toast } from "react-toastify";
import Gestione from "../../components/Layout/Gestione";
import Spinner from "../../components/UI/Spinner";
import CardCarosello from "../../components/UI/Card/CardCarosello";

const GestioneHomepage = () => {
    const [link, setLink] = useState("");
    const [img, setImg] = useState([]);
    const [loaded, setLoaded] = useState(true);

    const handleChange = (e) => {
        setLink(e.target.value);
    };

    const addImg = () => {
        if (!link) return;
        setLink("");

        const imgRef = collection(database, "carosello");
        addDoc(imgRef, { link: link, createdAt: Timestamp.now().toDate() })
            .then(() => {
                toast.success("Immagine Aggiunta");
            })
            .catch((err) => {
                toast.error("Ops! Qualcosa è andato storto, riprova", err);
            });
    };
    const getImg = () => {
        const eventRef = collection(database, "carosello");
        const q = query(eventRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
            const images = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setImg(images);
            setLoaded(false);
        });
    };

    useEffect(() => {
        getImg();
    }, []);
    return (
        <Gestione title="Home Page">
            <div className="w-11/12 h-full mb-20">
                <div className="w-full h-full flex items-center justify-center">
                    <input
                        type="text"
                        name="link"
                        value={link}
                        onChange={handleChange}
                        className="input bg-white text-black   border-assoCol w-full max-w-md"
                        required
                    />
                    <button className="btn" onClick={addImg}>
                        Aggiungi
                    </button>
                </div>
                <div className="w-full flex justify-center mt-10">
                    {link && (
                        <img src={link} className="max-w-md " alt="logo" />
                    )}
                </div>
            </div>
            <div className="w-12/12 h-full flex  justify-center flex-wrap">
                {loaded && <Spinner />}

                {img &&
                    img.map((img, i) => <CardCarosello key={i} img={img} />)}
            </div>
        </Gestione>
    );
};

export default GestioneHomepage;
