import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { database } from "../../Utils/firebase_config";
import Soluzioni from "./Soluzioni";
import SoluzioniSingola from "./SoluzioniSingola";

const SoluzioniRoutes = () => {
    const [videosorveglianza, setVideosorveglianza] = useState([]);
    const [antintrusione, setAntintrusione] = useState([]);
    const [antincendio, setAntincendio] = useState([]);
    const [diffusione, setDiffusione] = useState([]);
    const [accessi, setAccessi] = useState([]);
    const [automazione, setAutomazione] = useState([]);
    const [cables, setCables] = useState([]);
    const [telefonia, setTelefonia] = useState([]);
    const [networking, setNetworking] = useState([]);
    const [accumulatore, setAccumulatore] = useState([]);

    useEffect(() => {
        const bradRef = collection(database, "brand");

        const q = query(
            bradRef,
            where("type", "array-contains", "videosorveglianza")
        );
        const a = query(
            bradRef,
            where("type", "array-contains", "antincendio")
        );
        const b = query(
            bradRef,
            where("type", "array-contains", "antintrusione")
        );
        const c = query(bradRef, where("type", "array-contains", "diffusione"));
        const d = query(bradRef, where("type", "array-contains", "accessi"));
        const e = query(
            bradRef,
            where("type", "array-contains", "automazione")
        );
        const f = query(bradRef, where("type", "array-contains", "cavi"));
        const g = query(bradRef, where("type", "array-contains", "telefonia"));
        const h = query(bradRef, where("type", "array-contains", "networking"));
        const i = query(
            bradRef,
            where("type", "array-contains", "accumulatore")
        );

        onSnapshot(q, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setVideosorveglianza(brandsNew);
        });
        onSnapshot(a, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setAntincendio(brandsNew);
        });
        onSnapshot(b, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAntintrusione(brandsNew);
        });
        onSnapshot(c, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setDiffusione(brandsNew);
        });
        onSnapshot(d, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAccessi(brandsNew);
        });
        onSnapshot(e, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAutomazione(brandsNew);
        });
        onSnapshot(f, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCables(brandsNew);
        });
        onSnapshot(g, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTelefonia(brandsNew);
        });
        onSnapshot(h, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setNetworking(brandsNew);
        });
        onSnapshot(i, (snapshot) => {
            const brandsNew = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setAccumulatore(brandsNew);
        });
    }, []);

    const soluzioni = [
      {
        name: "Videosorveglianza",
        path: "/videosorveglianza",
        brands: videosorveglianza,
        brandSize: "w-[200px] h-[200px] my-8 mx-12",
      },
      {
        name: "Antintrusione / Antifurto",
        path: "/antintrusione",
        link: "https://www.assosicurezza.com",
        brands: antintrusione,
        brandSize:
          antintrusione.length <= 4
            ? "w-[250px] h-[250px] m-2 mx-12"
            : "w-[105px] h-[105px] m-8  mx-8",
      },
      {
        name: "Antincendio / Rilevamento Gas / Spegnimento",
        path: "/antincendio",
        link: "https://www.assosicurezza.com",
        brands: antincendio,
        brandSize:
          antincendio.length <= 4
            ? "w-[250px] h-[250px] m-2 mx-12"
            : "w-[150px] h-[150px] m-8 mx-12",
      },
      {
        name: "Diffusione Sonora / Sistemi Evac",
        path: "/diffusione",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: diffusione,
        brandSize:
          diffusione.length <= 4
            ? "w-[250px] h-[250px] m-2 mx-12"
            : "w-[150px] h-[150px] m-8 mx-12",
      },
      {
        name: "Controllo Accessi",
        path: "/accessi",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: accessi,
        brandSize:
          accessi.length <= 4
            ? "w-[250px] h-[250px] m-2 mx-12"
            : "w-[150px] h-[150px] m-8 mx-12",
      },
      {
        name: "Telefonia / Videocitofonia",
        path: "/telefonia",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: telefonia,
        brandSize: "w-[230px] h-[230px] mx-12",
      },
      {
        name: "Networking",
        path: "/networking",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: networking,
        brandSize: "w-[160px] h-[160px] my-8  lg:my-2 mx-12",
      },
      {
        name: "Automazione",
        path: "/automazione",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: automazione,
        brandSize:
          automazione.length <= 4
            ? "w-[200px] h-[200px] mx-12"
            : "w-[150px] h-[150px] m-8 mx-12",
      },

      {
        name: "Accumulatori / Gruppi Di Continuità",
        path: "/accumulatore",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: accumulatore,
        brandSize: "w-[200px] h-[200px] mx-16",
      },
      {
        name: "Cavi",
        path: "/cavi",
        background: "hero-overlay bg-cavi bg-opacity-60 bg-center ",
        link: "https://www.assosicurezza.com",
        brands: cables,
        brandSize:
          cables.length <= 4
            ? "w-[230px] h-[230px] mx-12"
            : "w-[150px] h-[150px] m-8 mx-12",
      },
    ];
    return (
        <Routes>
            <Route path="/" element={<Soluzioni />} />
            {soluzioni.map((soluzione, key) => {
                return (
                    <Route
                        key={key}
                        path={`${soluzione.path}`}
                        element={
                            <SoluzioniSingola
                                name={soluzione.name}
                                image={soluzione.image}
                                brands={soluzione.brands}
                                brandSize={soluzione.brandSize}
                                background={soluzione.background}
                            />
                        }
                    />
                );
            })}
           
        </Routes>
    );
};

export default SoluzioniRoutes;
