import React from "react";

const Contatti = ({ handleContatti }) => {
    return (
        <div>
            <div className="w-full h-full  text-zinc-800 flex flex-col justify-between ">
                <div className="divider bg-zinc-200 h-1 "></div>
                <div className="flex w-full ml-4 mt-5">
                    <div className="w-1/2">
                        <p className="text-2xl text-black">Contatti</p>
                    </div>
                </div>
                <p className="text-lg text-bold ml-4 mt-10 font-bold">
                    Che tipo di contatto desideri utilizzare *
                </p>
            </div>
            <div className="w-full h-full flex ml-4">
                <div className="w-full flex flex-col lg:flex-row mt-10 flex-wrap">
                    {contatti.map((option, index) => (
                        <label
                            className="cursor-pointer w-full flex items-center mb-1"
                            key={index}
                        >
                            <div className="w-4/5">
                                <span className="text-zinc-800 ml-4">
                                    {option.title}
                                </span>
                            </div>
                            <div className="w-1/5 flex">
                                <span className="text-zinc-800 mx-4">Qnt</span>
                                <input
                                    name={option.title}
                                    onMouseLeave={handleContatti}
                                    type="text"
                                    className="w-10 h-5 ring-opacity-0 "
                                />
                            </div>
                        </label>
                    ))}
                </div>
            </div>
            <div className="divider bg-zinc-200 h-1 "></div>
        </div>
    );
};

export default Contatti;

const contatti = [
    {
        id: "scomparsa",
        title: " Contatti Magnetici a scomparsa ",
    },
    {
        id: "a_vista",
        title: " Contatti Magnetici a vista ",
    },
    {
        id: "vibrazione",
        title: " Contatti Vibrazione ",
    },
    {
        id: "tapparelle",
        title: " Contatti per tapparelle ",
    },
    {
        id: "alta_ptotezione",
        title: " Contatti ad alta protezione ",
    },
    {
        id: "ad_asta",
        title: " Contatti ad asta ",
    },
];
export { contatti };
