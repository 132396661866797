import Login from "../../auth/Login";
import ResetPass from "../../auth/ResetPass";
import SignUp from "../../auth/Signup";
import Verify from "../../auth/Verify";
import Azienda from "../../Routes/Azienda";
import Contatti from "../../Routes/Contatti/Contatti";
import Download from "../../Routes/Download/Download";
import News from "../../Routes/News/News";
import PrivacyPolicy from "../../Routes/Policy/PrivacyPolicy";
import SoluzioniRoutes from "../../Routes/Soluzioni/SoluzioniRoutes";
import { Teleassistenza } from "../../Routes/Teleassistenza/Teleassistenza";

export const routesList = [
  {
    path: "/",
    component: <Azienda />,
    exact: true,
  },
  {
    path: "/azienda",
    component: <Azienda />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/registrati",
    component: <SignUp />,
  },
  {
    path: "/reset_password",
    component: <ResetPass />,
  },
  {
    path: "/controlla_la_posta",
    component: <Verify />,
  },
  {
    path: "/soluzioni/*",
    component: <SoluzioniRoutes />,
  },
  {
    path: "/download",
    component: <Download />,
  },
  { path: "/contatti", component: <Contatti /> },
  {
    path: "/privacypolicy",
    component: <PrivacyPolicy />,
  },
  { path: "/teleassistenzaAsso", component: <Teleassistenza /> },
  { path: "/news", component: <News /> },
];

//  {
//    routesList.map((route) => (
//      <Route
//        key={route.path}
//        path={route.path}
//        element={route.component}
//        brandsState={route.brandsState && brandsState}
//        setBrandsState={route.setBrandsState && setBrandsState}
//        user={route.user && user}
//        setUser={route.setUser && setUser}
//      />
//    ));
//  }
