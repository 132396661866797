import React from "react";
import { AiOutlineFile } from "react-icons/ai";

const BrandItems = ({ data }) => {
  return (
    <div className="w-full flex justify-center flex-wrap">
      {data &&
        data.map((item, idx) => (
          <div
            key={idx}
            className="w-full p-4 flex hover:scale-105 xl:hover:scale-100  duration-200 bg-zinc-100 cursor-pointer hover:text-white  justify-start "
          >
            <AiOutlineFile
              color="assoCol"
              className="text-assoCol lg:text-[22px] text-[18px]"
            />
            <a
              key={idx}
              href={item.url}
              download
              target="_blank"
              rel="noreferrer noopener"
            >
              <p className="text-black  lg:text-[16px] text-[14px]">
                {item.title}
              </p>
            </a>
          </div>
        ))}
    </div>
  );
};

export default BrandItems;
