import React, { Fragment } from "react";

const Automazione = ({ automazione, handleChange }) => {
    return (
        <div className=" w-full mt-24 pb-8 bg-assoBg text-zinc-800">
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="form-control w-full flex-row ml-4">
                <div className="w-full">
                    <p className="text-xl text-black">
                        Richiesta Offerta Automazione
                    </p>
                </div>
            </div>
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="w-full flex flex-col lg:flex-row  justify-center item-center">
                <div className="w-1/2 mt-10 ml-4">
                    <p className="text-xl mb-2 ml-1">Marchio Preferito *</p>
                    <select
                        name="marchio"
                        onChange={handleChange}
                        className="select select-error w-full max-w-xs bg-white"
                        value={automazione.marchio}
                    >
                        {list &&
                            list.map((marchio, i) => (
                                <option
                                    key={i}
                                    value={marchio}
                                    className="bg-assoBg selection:bg-assoCol uppercase"
                                >
                                    {marchio}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="w-1/2 mt-10 ml-4">
                    <p className="text-xl mb-2 ml-1">Tipo di Impianto *</p>
                    <select
                        name="impianto"
                        onChange={handleChange}
                        className="select select-error w-full max-w-xs bg-white"
                        value={automazione.impiantoList}
                    >
                        <option className="bg-assoBg selection:bg-assoCol uppercase">
                            Scegli
                        </option>
                        {impiantoList &&
                            impiantoList.map((impianto, i) => (
                                <option
                                    key={i}
                                    value={impianto}
                                    className="bg-assoBg selection:bg-assoCol uppercase"
                                >
                                    {impianto}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row justify-center item-center">
                <div className="w-1/2 mt-10 ml-4">
                    <p className="text-xl mb-2 ml-1">Tipo di Apertura *</p>
                    <select
                        name="apertura"
                        onChange={handleChange}
                        className="select select-error w-full max-w-xs bg-white"
                        value={automazione.apertura}
                    >
                        <option className="bg-assoBg selection:bg-assoCol uppercase">
                            Scegli
                        </option>
                        {aperturaList &&
                            aperturaList.map((item, i) => (
                                <option
                                    key={i}
                                    value={item}
                                    className="bg-assoBg selection:bg-assoCol uppercase"
                                >
                                    {item}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="w-1/2 mt-10 ml-4">
                    <p className="text-xl mb-2 ml-1"> Tipo di Installazione*</p>
                    <select
                        name="installazione"
                        onChange={handleChange}
                        className="select select-error w-full max-w-xs bg-white"
                        value={automazione.installazione}
                    >
                        <option className="bg-assoBg selection:bg-assoCol uppercase">
                            Scegli
                        </option>
                        {instaList &&
                            instaList.map((insta, i) => (
                                <option
                                    key={i}
                                    value={insta}
                                    className="bg-assoBg selection:bg-assoCol uppercase"
                                >
                                    {insta}
                                </option>
                            ))}
                    </select>
                </div>
            </div>
            {automazione.apertura === "Scorrevole" && (
                <div>
                    <div className="w-full flex flex-col lg:flex-row justify-between px-4">
                        <div className="w-1/2 ">
                            <label className="text-xl mb-2 ml-1 ">
                                <p>
                                    Lunghezza Cancello
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <input
                                value={automazione.lunghezza_cancello}
                                onChange={handleChange}
                                name="lunghezza_cancello"
                                type="text"
                                className="block  max-w-xs px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md   focus:border-assoCol dark:focus:border-assoCol focus:outline-none focus:ring"
                                required
                            />
                        </div>
                        <div className="w-1/2 pl-2 lg:pl-6">
                            <label className="text-xl pl-4 pb-2">
                                <p>
                                    Numero Ante
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <div className="w-full h-full flex flex-col lg:flex-row lg:space-x-12 ">
                                <div className="form-control pt-4">
                                    <label className="cursor-pointer">
                                        <input
                                            type="radio"
                                            id="num_ante"
                                            name="num_ante"
                                            value="1 Anta"
                                            onChange={handleChange}
                                            className="w-5 h-5 ring-opacity-0"
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            1 Anta
                                        </span>
                                    </label>
                                </div>
                                <div className="form-control pt-4">
                                    <label className="cursor-pointer">
                                        <input
                                            type="radio"
                                            id="num_ante"
                                            name="num_ante"
                                            value="2 Ante"
                                            onChange={handleChange}
                                            className="w-5 h-5"
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            2 Ante
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="px-4 my-6">
                        <label className="text-xl py-4 ">
                            <p className="pb-4">
                                Peso Ante
                                <span className="text-assoCol"> *</span>
                            </p>
                        </label>
                        <div className="w-full flex flex-col items-center lg:flex-row lg:flex-wrap pl-2 space-y-2 lg:space-y-0">
                            {peso.map((peso, i) => (
                                <div className="w-full lg:w-1/4 h-full" key={i}>
                                    <label className="cursor-pointer flex items-center">
                                        <input
                                            type="radio"
                                            id="peso_ante"
                                            name="peso_ante"
                                            value={peso}
                                            onChange={handleChange}
                                            className="w-5 h-5"
                                        />
                                        <span className="text-zinc-800 pl-4">
                                            {peso}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            {automazione.apertura === "Battente" && (
                <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center px-4 mb-6">
                    <div className="w-1/2 ">
                        <label className="text-xl mb-2 pl-1 ">
                            <p>
                                Dimensione Anta
                                <span className="text-assoCol"> *</span>
                            </p>
                        </label>
                        <input
                            value={automazione.dim_anta}
                            onChange={handleChange}
                            name="dim_anta"
                            type="text"
                            className="block  max-w-xs px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md   focus:border-assoCol dark:focus:border-assoCol focus:outline-none focus:ring"
                            required
                        />
                    </div>
                    <div className="w-full lg:w-1/2 lg:pl-10">
                        <label className="text-xl pl-8 pb-2 ">
                            <p>
                                Numero Ante
                                <span className="text-assoCol"> *</span>
                            </p>
                        </label>
                        <div className="w-full h-full flex lg:pl-1 flex-row items-center lg:space-x-12">
                            <div className="w-1/2 lg:w-1/4 lg:pl-4 mt-2 ">
                                <label className="cursor-pointer w-full flex items-center">
                                    <input
                                        type="radio"
                                        id="num_ante"
                                        name="num_ante"
                                        value="1 Anta"
                                        onChange={handleChange}
                                        className="w-5 h-5 ring-opacity-0"
                                    />
                                    <span className="text-zinc-800 pl-4">
                                        1 Anta
                                    </span>
                                </label>
                            </div>
                            <div className="form-control flex lg:pl-2 pt-2">
                                <label className="cursor-pointer">
                                    <input
                                        type="radio"
                                        id="num_ante"
                                        name="num_ante"
                                        value="2 Ante"
                                        onChange={handleChange}
                                        className="w-5 h-5"
                                    />
                                    <span className="text-zinc-800 ml-4">
                                        2 Ante
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {automazione.apertura === "Barriere(Porte Veloci)" && (
                <div>
                    <div className="w-full flex flex-col lg:flex-row justify-between px-4">
                        <div className="w-1/2 ">
                            <label className="text-xl mb-2 ml-1 ">
                                <p>
                                    Lunghezza Varco
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <input
                                value={automazione.lunghezza_varco}
                                onChange={handleChange}
                                name="lunghezza_varco"
                                type="text"
                                className="block  max-w-xs px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md   focus:border-assoCol dark:focus:border-assoCol focus:outline-none focus:ring"
                                required
                            />
                        </div>
                        <div className="w-1/2 lg:w-1/2 pt-6 lg:pl-6">
                            <label className="text-xl ">
                                <p>
                                    Numero di Ante Scorrevoli
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <div className="w-full h-full flex flex-col lg:flex-row lg:space-x-12 my-6 ">
                                <div className="form-control ">
                                    <label className="cursor-pointer">
                                        <input
                                            type="radio"
                                            id="num_anta_scor"
                                            name="num_ante_scor"
                                            value="1 Anta"
                                            onChange={handleChange}
                                            className="w-5 h-5 ring-opacity-0"
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            1 Anta
                                        </span>
                                    </label>
                                </div>
                                <div className="form-control flex ">
                                    <label className="cursor-pointer">
                                        <input
                                            type="radio"
                                            id="num_anta_scor"
                                            name="num_ante_scor"
                                            value="2 Ante"
                                            onChange={handleChange}
                                            className="w-5 h-5"
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            2 Ante
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row lg:justify-between items-center px-4 mb-8">
                        <div className="w-full lg:w-1/2">
                            <label className="text-xl mb-2 ml-1 ">
                                <p>
                                    Peso Porte
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <input
                                value={automazione.peso_porte}
                                onChange={handleChange}
                                name="peso_porte"
                                type="text"
                                className="block  max-w-xs px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md   focus:border-assoCol dark:focus:border-assoCol focus:outline-none focus:ring"
                                required
                            />
                        </div>
                        <div className="w-full lg:w-1/2 lg:px-6 mt-8">
                            <label className="text-xl py-4 ">
                                <p className="pb-4">
                                    Tipo di Porte
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <div className="w-full flex">
                                {tipo_porte.map((tipo, i) => (
                                    <div
                                        className="w-1/2 h-full flex  ml-1"
                                        key={i}
                                    >
                                        <label className="cursor-pointer">
                                            <input
                                                type="radio"
                                                id="tipo_porte"
                                                name="tipo_porte"
                                                value={tipo}
                                                onChange={handleChange}
                                                className="w-5 h-5"
                                            />
                                            <span className="text-zinc-800 ml-4">
                                                {tipo}
                                            </span>
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="px-4 mt-8">
                        <p className="text-xl mb-2 ml-1">
                            Di quali Accessori Porte Veloci hai bisogno? *
                        </p>
                        <div className="w-full mt-5 flex flex-wrap items-center ml-1">
                            {porte_veloci.map((option, index) => (
                                <label
                                    className="cursor-pointer w-full lg:w-1/4 flex items-center mb-1 py-2"
                                    key={index}
                                >
                                    <div className="w-full">
                                        <input
                                            id="accessori"
                                            type="checkbox"
                                            name={option}
                                            onChange={handleChange}
                                            className="w-5 h-5 ring-opacity-0 "
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            {option}
                                        </span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {automazione.apertura === "Barriere Veloci" && (
                <Fragment>
                    <div className="w-full flex flex-col lg:flex-row justify-between px-4">
                        <div className="w-1/2 ">
                            <label className="text-xl mb-2 ml-1 ">
                                <p>
                                    Lunghezza Varco
                                    <span className="text-assoCol"> *</span>
                                </p>
                            </label>
                            <input
                                value={automazione.lunghezza_varco}
                                onChange={handleChange}
                                name="lunghezza_varco"
                                type="text"
                                className="block  max-w-xs px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md   focus:border-assoCol dark:focus:border-assoCol focus:outline-none focus:ring"
                                required
                            />
                        </div>
                    </div>
                    <div className="p-4">
                        <p className="text-xl mb-2 ml-1">
                            Accessori Barriere Stradali
                            <span className="text-assoCol"> *</span>
                        </p>
                        <div className="w-full mt-5 flex flex-wrap items-center ml-1">
                            {accessori_barriere.map((option, index) => (
                                <label
                                    className="cursor-pointer w-full lg:w-1/4 flex items-center mb-1 py-2"
                                    key={index}
                                >
                                    <div className="w-full">
                                        <input
                                            id="accessori"
                                            type="checkbox"
                                            name={option}
                                            onChange={handleChange}
                                            className="w-5 h-5 ring-opacity-0 "
                                        />
                                        <span className="text-zinc-800 ml-4">
                                            {option}
                                        </span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                </Fragment>
            )}
            <div className="px-4 mt-8">
                <p className="text-xl mb-2 ml-1">
                    Di quali Accessori hai bisogno? *
                </p>
                <div className="w-full mt-5 flex flex-wrap items-center ml-1">
                    {accessoriList.map((option, index) => (
                        <label
                            className="cursor-pointer w-full lg:w-1/4 flex items-center mb-1 py-2"
                            key={index}
                        >
                            <div className="w-full">
                                <input
                                    id="accessori"
                                    type="checkbox"
                                    name={option}
                                    onChange={handleChange}
                                    className="w-5 h-5 ring-opacity-0 "
                                />
                                <span className="text-zinc-800 ml-4">
                                    {option}
                                </span>
                            </div>
                        </label>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Automazione;

const list = ["Scegli", "NICE", "FADINI", "CAME", "CARDIN", "LABEL", "BENINCA"];
const impiantoList = ["Residenziale", "Urbano", "Industriale"];
const aperturaList = [
    "Scorrevole",
    "Battente",
    "Barriere(Porte Veloci)",
    "Barriere Veloci",
];
const instaList = ["interna", "esterna"];
const accessoriList = [
    "Fotocellule",
    "Colonnine per fotocellule",
    "Selettore",
    "Lampeggiante",
    "Telecomando",
    "Spira magnetica",
    "Tel Lettura targe",
    "Costa",
];
const peso = ["0-400kg", "400-800kg", "800-1200kg", "1200-4000kg"];
const tipo_porte = ["Solo Cristallo", "Cornice Alluminio + vetro"];
const accessori_barriere = [
    "Luci",
    "Adesivi",
    "Protezione Gomma",
    "Siepe",
    "Forcella Fissa",
    "Forcella Pensile",
    "Asta Snodata",
    "Costa",
];
const porte_veloci = [
    "Sensore di Apertura",
    "Selettore Chiave",
    "Programmatore",
    "Gruppo Batterie",
];
