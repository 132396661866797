import React from "react";
import TableContatti from "./TableContatti";
import { DelFiliale, DelImg } from "../../UI/Button/DelButton";
import EditSedi from "../../UI/Button/EditSedi";

const Sedi = ({ rubrica }) => {
    return (
        <div className="w-full h-full flex justify-center border-2 border-assoCol border-opacity-50 rounded-xl p-2 shadow-2xl mb-5">
            <div className="w-full text-zinc-800 flex flex-col sm:justify-evenly pt-6 sm:pt-0 ">
                <div className="w-full">
                    <p className=" text-2xl uppercase text-bold p-5 ">
                        Tutte le sedi
                    </p>
                    <div className="w-full space-y-5 ">
                        {rubrica &&
                            rubrica.map((list) => (
                                <div
                                    key={list.id}
                                    className="w-full border-zinc-100  bg-zinc-200 border-2 rounded-lg  "
                                >
                                    <div className="w-full bg-assoCol glass hover:bg-assoCol rounded-t-lg flex justify-between">
                                        <p className="text-2xl uppercase ml-2 p-4  w-full">
                                            {list.filiale}
                                        </p>
                                        <div className="z-[999] pr-8 cursor-pointer  text-black">
                                            <DelFiliale
                                                title={list.filiale}
                                                info={list}
                                                type="filiali"
                                                messaggio="Sei sicuro di volere eliminare la sede di "
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="w-2/3">
                                            <div className="m-2">
                                                <p>{list.indirizzo}</p>
                                                <p>Tel: {list.tel}</p>
                                                <p>Orari: {list.orari}</p>
                                            </div>
                                            <div className="flex flex-wrap">
                                                {list.imgs &&
                                                    list.imgs.map((img) => (
                                                        <DelImg
                                                            key={list.id}
                                                            img={img}
                                                            id={list.id}
                                                            filiale={
                                                                list.filiale
                                                            }
                                                        />
                                                    ))}
                                            </div>
                                        </div>
                                        <EditSedi
                                            title={list.filiale}
                                            info={list}
                                            type="filiali"
                                            messaggio="Aggiorna "
                                        />
                                    </div>

                                    <TableContatti filiale={list.filiale} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sedi;
