import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";

// HTML ====> JSX

// EDITOR
export const JoditEditorComp = () => {
    const editor = useRef(null);
    const [content, setContent] = useState("");

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
    };

    return (
        <div>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            />
            <button onClick="">Submit</button>
        </div>
    );
};

const HtmlToReactParser = require("html-to-react").Parser;

export const HtmlToJSX = (html) => {
    const htmlToReactParser = new HtmlToReactParser();
    const reactElement = htmlToReactParser.parse(html);
    return reactElement;
};

export const cutPara = (para) => {
    let words = para
        .split(/\s+/)
        .slice(0, 9)
        .join(" ");
    return HtmlToJSX(words);
};
