import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../firebase_config";
import Agenti from "./Agenti";

const FormData = ({ handleFiliale, formData, handleChange, handleCheck }) => {
  
    const [filiali, setFiliali] = useState([]);
 
    useEffect(() => {
      const filialiRef = collection(database, "filiali");
      const q = query(filialiRef, orderBy("id_filiale", "asc"));
      onSnapshot(q, (snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setFiliali(data);
      });
    }, []);
    return (
        <div className="grid grid-cols-1 gap-10 mt-4  mb-10 sm:grid-cols-2 lg:mb-24">
            <div>
                <span className="text-zinc-700 font-semibold">
                    Azienda/Ditta - Ragione Sociale *
                </span>
                <input
                    id="text"
                    name="azienda"
                    value={formData.azienda}
                    onChange={handleChange}
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    required
                />
            </div>

            <div>
                <label className="text-zinc-700 font-semibold ">
                    Nome e Cognome *
                </label>
                <input
                    value={formData.nome}
                    onChange={handleChange}
                    name="nome"
                    type="text"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    required
                />
            </div>

            <div>
                <label className="text-zinc-700 font-semibold ">Email *</label>
                <input
                    value={formData.email}
                    onChange={handleChange}
                    type="email"
                    name="email"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    required
                />
            </div>
            <div>
                <label className="text-zinc-700 font-semibold">
                    Numero di cellulare o fisso *
                </label>
                <input
                    value={formData.tel}
                    onChange={handleChange}
                    id="color"
                    type="text"
                    name="tel"
                    className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                    required
                />
            </div>
            <div className="flex flex-col flex-nowrap  ">
                <label className="text-zinc-700 font-semibold pb-4">
                    Settore di Interesse - Selezione multipla *
                </label>
                <div className="flex flex-col text-sm text-white lg:flex-row lg:flex-wrap">
                    {settoreOptions.map((option, idx) => (
                        <label
                            key={idx}
                            className="w-1/2 text-zinc-700 inline-flex items-center py-2"
                        >
                            <input
                                id={option.id}
                                type="checkbox"
                                name={option.name}
                                onChange={handleCheck}
                                className="form-checkbox h-5 w-5 text-gray-600"
                            />
                            <span className="ml-2 ">{option.name}</span>
                        </label>
                    ))}
                </div>
            </div>
            <div className="flex  flex-col w-full space-y-4">
                <div className="flex flex-col flex-nowrap ">
                    <label
                        className="text-zinc-700 font-semibold mb-4"
                        htmlFor="username"
                    >
                        Seleziona la tua filiale ASSO di riferimento *
                    </label>
                    <select
                        name="filiale"
                        onChange={handleFiliale}
                        className="select select-error w-full max-w-xs"
                        value={formData.filiale}
                    >
                        <option>scegli una filiale</option>
                        {filiali &&
                            filiali.map((list, i) => (
                                <option
                                    key={i}
                                    value={list.filiale}
                                    className="bg-assoBg selection:bg-assoCol"
                                >
                                    {list.filiale}
                                </option>
                            ))}
                    </select>
                </div>
                <Agenti formData={formData} handleChange={handleChange} />
            </div>
        </div>
    );
};

export default FormData;

const settoreOptions = [
    { id: "TVCC", name: "TVCC" },
    { id: "Antintrusione", name: "Antintrusione" },
    { id: "Automazione", name: "Automazione" },
    { id: "Videocitofonia", name: "Videocitofonia" },
    { id: "Anti", name: "Antincendio" },
    { id: "Telefonia", name: "Telefonia" },
    { id: "Diffusione", name: "Diffusione Sonora" },
    { id: "Accessi", name: "Controllo Accessi" },
    { id: "Domotica", name: "Domotica" },
];
