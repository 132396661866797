import React from "react";

const TelefoniaData = ({ data }) => {
  return (
    <div className="w-full flex flex-col my-4 ">
      <p className="text-xl border-b-2 border-assoCol uppercase font-bold my-2">
        Telefonia
      </p>

      <div className="flex flex-col pt-4 w-full space-y-2">
        <p className="capitalize font-bold lg:w-1/3">
          tipo di impianto :
          <span className="font-normal pl-2">{data.impianto}</span>
        </p>
        <p className="capitalize font-bold lg:w-1/3">
          num. linee di ingresso :
          <span className="font-normal pl-2">{data.num_linee}</span>
        </p>
        <p className="capitalize font-bold lg:w-1/3">
          num. interni :
          <span className="font-normal pl-2">{data.num_interni}</span>
        </p>

        <p className="capitalize font-bold w-full mb-4">
          richieste :<span className="font-normal pl-2">{data.richiesta}</span>
        </p>
      </div>
    </div>
  );
};

export default TelefoniaData;
