import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../../Utils/firebase_config";
import CardSingleContainer from "./CardSingleContainer";

const CardSection = ({ user }) => {
  const [novitàHome, setNovitàHome] = useState([]);

  useEffect(() => {
    if (novitàHome.length === 0) {
      const newsRef = collection(database, "novità");
      const q = query(newsRef, where("selected", "==", true));
      onSnapshot(q, (snapshot) => {
        const novità = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNovitàHome(novità);
      });
    }
  }, [novitàHome]);
  const [eventiHome, setEventiHome] = useState([]);

  //get database data

  useEffect(() => {
    if (eventiHome.length === 0) {
      const eventRef = collection(database, "eventi");
      const q = query(eventRef, where("selected", "==", true));
      onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventiHome(events);
      });
    }
  }, [eventiHome]);
  return (
    <div>
      {" "}
      <div className="grid gap-6 grid-cols-1 lg:grid-cols-1 mx-10">
        <div className="w-full">
          <div className="flex align-center mb-10 pb-2 items-center w-full border-assoCol justify-center">
            <div className="badge badge-outline text-assoCol px-8 py-1 uppercase">
              news recenti
            </div>
          </div>
          <CardSingleContainer data={novitàHome} type={"news"} isNews={true} />
        </div>

        <div className="flex align-center mb-4 pb-2 items-center  w-full border-assoCol justify-center">
          <div className="badge badge-outline text-assoCol px-8 my-10 py-1 uppercase">
            eventi recenti
          </div>
        </div>
        <CardSingleContainer data={eventiHome} type={"evento"} user={user} />
      </div>
    </div>
  );
};

export default CardSection;
