import React from "react";
// import logo from "../images/AssoLogo.png";

import { Link } from "react-router-dom";
import SocialMedia from "../SocialMedia";
// import { auth } from "../../firebase-config";
import FilialiGroup from "./FilialiGroup";

function Footer() {
    //   const currentUser = auth.currentUser;

    return (
      <div>
        <div
          className="w-full px-0 py-16  flex lg:justify-center md:space-x-14 bg-orange-600		 lg:items-start flex-col text-white items-center   space-y-4
                        lg:flex-row lg:flex-wrap "
        >
          <div className="flex mt-9  justify-center font-bold  flex-col items-center space-y-4">
            <div className="border-b-1 text-center border-white">
              ASSO SICUREZZA SPA{" "}
            </div>
            <div className=" text-xs pb-4">
              <div className="p-2 w-full text-center  border-white cursor-pointer">
                <a
                  href="https://form.jotform.com/230393792313355"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LAVORA CON NOI
                </a>
              </div>
              <div className="p-2 w-full text-center border-white">
                <Link to="/teleassistenzaAsso">TELEASSISTENZA</Link>
              </div>
              <div className="p-2 w-full text-center  border-white">
                <Link to="/download">DOWNLOAD</Link>
              </div>
              <div className="p-2 w-full text-center  border-white">
                <Link to="/privacypolicy">PRIVACY POLICY</Link>
              </div>
              <div className="p-2 w-full text-center  border-white">
                <Link to="/preventivo">RICHIEDI PREVENTIVO</Link>
              </div>
              {"currentUser" ? (
                ""
              ) : (
                <div className="p-2 w-full text-center  border-white">
                  <Link to="/login"> LOGIN/LOGOUT</Link>
                </div>
              )}

              <div className="p-2 w-full text-center  border-white">
                <Link to="/contatti"> CONTATTI</Link>
              </div>
            </div>
            <SocialMedia />
            <div className="divider divider-vertical"></div>
          </div>
          <div>
            <FilialiGroup />
          </div>
        </div>
      </div>
    );
}

export default Footer;
