import { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import GestioneAccounts from "./GestioneAccounts";
import GestioneContatti from "./GestioneContatti";
import GestioneEventi from "./GestioneEventi";
import GestioneHomepage from "./GestioneHomepage";
import GestioneNovità from "./GestioneNovità";

export const AdminRoutes = ({ livello, setLivello }) => (
  <Routes>
    {livello === "Dipendente 2" || livello === "Admin" ? (
      <Fragment>
        <Route
          path="/gestione_account"
          element={<GestioneAccounts setLivello={setLivello} />}
        />
        <Route path="/gestione_sito" element={<GestioneHomepage />} />
        <Route path="/gestione_filiali" element={<GestioneContatti />} />
      </Fragment>
    ) : (
      "accesso negato"
    )}

    {livello === "Dipendente 1" ||
    livello === "Dipendente 2" ||
    livello === "Admin" ? (
      <Fragment>
        <Route path="/gestione_eventi" element={<GestioneEventi />} />
        <Route path="/gestione_news" element={<GestioneNovità />} />
      </Fragment>
    ) : (
      "accesso negato"
    )}
  </Routes>
);
