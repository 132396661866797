import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { database } from "../../Utils/firebase_config";

import Store from "./Store";

const Contatti = () => {
  const [rubrica, setRubrica] = useState([]);

  useEffect(() => {
    const rubRef = collection(database, "filiali");
    const q = query(rubRef, orderBy("id_filiale", "asc"));
    onSnapshot(q, (snapshot) => {
      const sedi = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRubrica(sedi);
    });
  }, []);

  return (
    <div className="w-full bg-white h-full ">
      <iframe
        title="map"
        src="https://www.google.com/maps/d/embed?mid=1R__wpuVATurFvJ6CRGBsD4_bae1ZUvQ&ehbc=2E312F"
        className="w-full h-[47vh] "
      ></iframe>

      <div className="w-full h-full py-8 flex flex-col items-center space-y-2">
        {rubrica.map((item, id) => (
          <div
            key={id}
            className="w-full h-full bg-white flex flex-col items-center justify-center  leading-loose space-y-8 p-8 py-0  border-assoCol rounded-lg my-4
                                   lg:mx-auto lg:space-x-2 lg:space-y-0 lg:flex-row lg:justify-evenly  lg:border-zinc-200 
                                   hover:bg-zinc-100 hover:ease-in"
          >
            <div
              className="w-full h-full flex justify-center items-center
                                       lg:w-8/12    
                                       xl:w-[45vw]"
            >
              <Store
                filiale={item}
                sk={item.id}
                code={item.code}
                city={item.filiale}
                via={item.via}
                indirizzo={item.indirizzo}
                tel={item.tel}
                orari={item.orari}
                map={item.map}
                imgs={item.imgs}
              />
            </div>
            <div
              className="w-11/12 text-zinc-800 h-full
                                        lg:w-7/12
                                        xl:w-3/6 "
            >
              <div
                className="flex flex-col w-full
                                           lg:flex-row"
              >
                <h3 className="font-bold md:w-1/5">Indirizzo:</h3>
                <p>{item.indirizzo}</p>
              </div>
              <div
                className="flex flex-col w-full
                                            lg:flex-row"
              >
                <h3 className="font-bold md:w-1/5">Telefono:</h3>
                <a href={`tel:+${item.tel}`}>
                  {" "}
                  <p>{item.tel}</p>
                </a>
              </div>
              <div
                className="flex flex-col w-full
                                            lg:flex-row"
              >
                <h3 className="font-bold md:w-1/5">Orari:</h3>
                <p>{item.orari}</p>
              </div>
            </div>
            <div className="divider divider-vertical"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contatti;
