import React from "react";

const DiffusioneSonora = ({ handleChange, diffusioneSonora }) => {
    return (
        <div className=" w-full mt-24 pb-8 bg-assoBg text-zinc-800">
            <div className="divider bg-zinc-200 h-1 "></div>
            <div className="form-control w-full flex-row ml-4">
                <div className="w-1/2">
                    <p className="text-xl text-black">
                        Richiesta Offerta Diffusione Sonora
                    </p>
                </div>
            </div>
            <div className="my-6 mx-4">
                <p>Scrivi brevemente di cosa hai bisogno</p>
                <textarea
                    name="comment"
                    onChange={handleChange}
                    value={diffusioneSonora.comment}
                    className="textarea w-full bg-orange-100 my-4"
                />
            </div>
        </div>
    );
};

export default DiffusioneSonora;
