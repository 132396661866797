import React from "react";

const ContattiData = ({ data }) => {
  return (
    <div className="w-full flex flex-col rounded border-y-2 py-2 my-2 ">
      <p className="capitalize font-bold mt-2 underline text-assoCol">
        Contatti
      </p>
      {data.contatti.map((contatti, i) => (
        <p key={i}>{contatti}</p>
      ))}
    </div>
  );
};

export default ContattiData;
