import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { database } from "../../../Utils/firebase_config";
import { BrandFetcher, routesList } from "../../../Utils/routesList";
import { CardSmall } from "../Card/Card";
import Spinner from "../Spinner";

const AppInput2 = () => {
  const [searchWord, setSearchWord] = useState("");

  const [loaded, setLoaded] = useState(true);
  const [eventiHome, setEventiHome] = useState([]);
  const [itemsToShow, setItemsToShow] = useState([]);
  const [brandsState, setBrandsState] = useState([]);

  const [novità, setNovità] = useState([]);

  let navigate = useNavigate();
  //get database data
  useEffect(() => {
    const eventRef = collection(database, "eventi");
    const q = query(eventRef, orderBy("date", "desc"));
    onSnapshot(q, (snapshot) => {
      const events = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventiHome(events);
      setLoaded(false);
    });
    const newsRef = collection(database, "novità");
    const qnews = query(newsRef, orderBy("date", "desc"));
    onSnapshot(qnews, (snapshot) => {
      const novità = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNovità(novità);
      setLoaded(false);
    });
  }, []);

  useEffect(() => {
    const brandsRef = collection(database, "brand");
    {
      const q = query(brandsRef, orderBy("importance", "asc"));
      onSnapshot(q, (snapshot) => {
        const brandsNew = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBrandsState(brandsNew);
      });
    }
  }, []);

  const changeHandler = (e) => {
    setSearchWord(e.target.value);
  };
  const upSearchWord = searchWord ? searchWord.toUpperCase() : "";

  const brandList = brandsState.map((item, idx) => {
    return {
      name: item ? item.name : "",
      url: item ? item?.name?.toUpperCase() : "",
    };
  });
  const submitSearch = (e) => {
    e.preventDefault();
    let wordToSearch = new RegExp(searchWord, "gi");
    const searchedItems = eventiHome.filter((item) =>
      item.nome.match(wordToSearch)
    );
    const searchedNews = novità.filter((item) =>
      item?.nome?.match(wordToSearch)
    );
    const concatSearched = searchedItems.concat(searchedNews);

    routesList.forEach((item) => {
      if (searchWord === item.name) {
        navigate(`../${item.url}`);
      }
    });

    brandList.forEach((item) => {
      if (upSearchWord === item.name) {
        navigate(`../${item.name}`);
      }
    });

    setItemsToShow(concatSearched);
  };

  return (
    <div className="">
      <div className="form-control ">
        <div className="input-group flex flex-col">
          <div className="flex rounded  h-full items-center">
            <label htmlFor="my-modal-6">
              <div className=" hover:text-assoCol btn border-none text-white hover:bg-transparent hover:scale-105  bg-transparent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  className="inline-block   w-6 h-6 stroke-current"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
            </label>
            <BrandFetcher />
          </div>
        </div>
      </div>
      <input type="checkbox" id="my-modal-6" className="modal-toggle" />
      <div
        id="my-modal-6"
        className="modal w-full justify-start h-screen flex flex-col bg-white/90 backdrop-blur-lg overflow-scroll"
      >
        <div className="w-full flex   p-5 justify-end">
          <label
            className="btn bg-transparent hover:bg-transparent focus:bg-transparent glass text-assoCol border-none "
            htmlFor="my-modal-6"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </label>
        </div>

        <form className=" w-[80vw] flex " onSubmit={submitSearch}>
          <input
            className={
              "block w-full input bg-[#d2d2d2cc] rounded-r-none text-stone-800 input-bordered"
            }
            type="text"
            onChange={changeHandler}
            placeholder="Cerca..."
          />
          <button type="submit" className="btn rounded-l-none">
            <label
              htmlFor={
                searchWord === "contatti" ||
                searchWord === "soluzioni" ||
                searchWord === "assistenza" ||
                searchWord === "teleassistenzaAsso" ||
                searchWord === "contatti" ||
                searchWord === "prodotti" ||
                searchWord === "formazione" ||
                searchWord === "eventi" ||
                searchWord === "preventivo" ||
                searchWord === "home" ||
                searchWord === "azienda" ||
                searchWord === "news" ||
                searchWord === "download" ||
                brandList.map(
                  (item) => item?.name?.toLowerCase() === searchWord
                ) ||
                brandList.map(
                  (item) => item?.name?.toUpperCase() === searchWord
                )
                  ? "my-modal-6"
                  : ""
              }
              className="p-0 m-0 cursor-pointer"
            >
              VAI
            </label>
          </button>
        </form>
        <div className="w-full h-[90vh] pt-10 mt-4 overflow-scroll">
          <div className=" lg:pl-16 p-10 lg:py-20   w-full lg:gap-8 grid justify-center grid-cols-1 lg:grid-cols-2">
            {loaded && <Spinner />}
            {itemsToShow ? (
              itemsToShow.map((item, idx) => (
                <CardSmall key={idx} data={item} />
              ))
            ) : (
              <div className="flex bg-zinc-300 spacing-x-2 shadow-xl py-16 items-center mx-auto p-8 px-32 rounded-2xl text-[20px] lg:text-[40px] text-assoCol">
                <p className="">La tua ricerca non ha portato risultati...</p>
                <p className="ml-4">:(</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppInput2;
