import React from "react";
import { Link } from "react-router-dom";

const Verify = () => {
    return (
        <div className="hero w-full h-screen flex justify-center py-48 bg-base-200">
            <div className="w-5/6 h-full lg:max-w-4xl">
                <h1 className="text-5xl font-bold">
                    CONTROLLA LA TUA EMAIL E VERIFICA IL TUO ACCOUNT
                </h1>

                <Link
                    to="/"
                    className="w-full mt-24 bg-assoCol glass inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md font-semibold uppercase text-white hover:bg-zinc-300 active:bg-red-700 focus:outline-none hover:text-zinc-500 focus:border-red-700 focus:ring focus:ring-red-200 disabled:opacity-25 transition"
                >
                    Homepage
                </Link>
            </div>
        </div>
    );
};

export default Verify;
