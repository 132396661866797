import React, { useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { menulist } from "../../../Utils/menulist";
import logo from "../../Layout/AssoLogo.png";
import SocialMedia from "../../Organism/SocialMedia";
import AccediButton from "../Button/AccediButton";
import AppInput2 from "../Input/AppInput2";

const AppNavbar = ({ livello }) => {
  const { pathname } = useLocation();

  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 900) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <div
      className={
        pathname === "/azienda" || pathname === "/"
          ? `lg:fixed  left-0 h-[10px] navbar justify-between lg:backdrop-blur-lg p-0 w-full hidden xl:flex top-0 z-50 text-neutral lg:bg-black/40 ${
              navbar ? "lg:bg-black/90 " : "lg:bg-black/50 "
            } `
          : `   navbar lg:backdrop-blur-lg p-0 w-full justify-between hidden xl:flex top-0 z-50 text-neutral lg:bg-black/40 
                        
                       `
      }
    >
      <div className="px-4 hidden navbar-start  h-full w-[150px] bg-white lg:flex">
        <div className="w-full flex h-full bg-white  justify-center">
          <NavLink to="/">
            <img
              src={logo}
              alt="asso_logo"
              className={`w-full ${
                pathname === "/azienda" || pathname === "/"
                  ? "max-w-[5rem] "
                  : "max-w-[5rem]"
              }   h-full p-[1vh] xl:p-[1vh] object-contain`}
            />
          </NavLink>
        </div>
      </div>
      <div className="px-2 xl:pl-10 navbar-center flex justify-center ">
        <div className="hidden sm:flex h-full items-stretch">
          {menulist.map((item, index) => (
            <div
              key={index}
              className={
                livello !== "Admin" && item.page === "Admin"
                  ? "hidden"
                  : "block dropdown h-full uppercase  duration-500 hover:scale-105 font-bold px-[2vh] m-0  dropdown-hover"
              }
            >
              <NavLink
                to={`${item.link}`}
                style={(isActive) => ({
                  height: "100%",
                  color: isActive.isActive ? "#ff6c40" : "white",
                })}
              >
                <label
                  style={{}}
                  tabIndex="0"
                  className="hover:bg-transparent cursor-pointer w-full text-[11px] bg-transparent "
                >
                  {item.page}
                </label>
              </NavLink>
              {item.list && (
                <ul
                  tabIndex="0"
                  className="dropdown-content bg-[#181616]  menu p-4 shadow rounded-sm  w-64"
                >
                  {item.list.map((item, id) => (
                    <NavLink to={item.link} key={id}>
                      <li className="text-white border-b hover:scale-105 hover:text-assoCol duration-500 border-assoCol text-[11px] p-3">
                        {item.title}
                      </li>
                    </NavLink>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="navbar-end  w-[230px] 2xl:w-[500px]  h-full hidden lg:flex  justify-end ">
        <SocialMedia />
      </div>
      <div className="divider lg:divider-horizontal "></div>

      <div className="flex w-[120px] h-full border-b-[3.6px] cursor-pointer  border-assoCol 2xl:w-[170px] justify-end">
        <AppInput2 />
        <AccediButton livello={livello} />
      </div>
    </div>
  );
};

export default AppNavbar;
