import React, { useEffect, useState } from "react";

// import EventiSingle from "./EventiSingle";

import {
  collection,
  onSnapshot,
  // orderBy,
  query,
  where,
} from "firebase/firestore";

import { database } from "../../Utils/firebase_config";
import { CardEventi } from "../../components/UI/Card/Card";
import Hero from "../../components/UI/Hero/Hero";
import { Box, Spinner } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function Eventi({ user }) {
  let newDate = new Date();
  const [open, setOpen] = useState(false);
  const twoDigits = (item) => {
    return (item < 10 ? "0" : "") + item;
  };

  let day = twoDigits(newDate.getDate());
  let month = twoDigits(newDate.getMonth() + 1);

  let today = newDate.getFullYear() + "-" + month + "-" + day;
  const [eventi, setEventi] = useState([]);
  const [search, setSearch] = useState("coming");
  const [city, setCity] = useState("select");
  const [searchedEvents, setSearchedEvents] = useState("");
  const [newEventi, setNewEventi] = useState([]);

  const [loading, setLoading] = useState(true);
  const changeSearch = (e) => {
    setSearchedEvents(e.target.value);
  };
  function refreshPage(e) {
    e.preventDefault();
    setSearch("coming");
    setCity("select");
    setSearchedEvents("");
  }

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearch(e.target.value);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    let wordToSearch = new RegExp(searchedEvents.toLowerCase(), "gi");

    const searchedItems = eventi.filter(
      (item) =>
        item.nome.match(wordToSearch) || item.descrizione.match(wordToSearch)
    );
    setOpen(true);
    setNewEventi(searchedItems);
  };

  // get database data

  useEffect(() => {
    const eventRef = collection(database, "eventi");
    const q = query(eventRef);
    onSnapshot(q, (snapshot) => {
      const events = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventi(events);
    });

    if (searchedEvents.length > 0) {
      const eventRef = collection(database, "eventi");
      const nome = query(eventRef, where("nome", "==", `${searchedEvents}`));
      onSnapshot(nome, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNewEventi(events);
        setLoading(false);
      });
    }
    if (search === "coming" && city === "select") {
      const q = query(eventRef);
      onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventi(events);
        setLoading(false);
      });
    }
    if (search === "coming" && city !== "select") {
      const q = query(
        eventRef,

        where("luogo", "==", `${city}`)
      );
      onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventi(events);
        setLoading(false);
      });
    }
    if (search === "past" && city === "select") {
      const q = query(eventRef);
      onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventi(events);
        setLoading(false);
      });
    }

    if (city !== "select") {
      const q = query(eventRef, where("luogo", "==", `${city}`));
      onSnapshot(q, (snapshot) => {
        const events = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventi(events);
        setLoading(false);
      });
    }
  }, [city, today, searchedEvents, search]);
  console.log(eventi);
  const srcImage = "https://i.im.ge/2022/07/23/F731hC.png";
  const srcImageMobile = "https://i.im.ge/2022/07/23/F73l1M.png";

  return (
    <div className="md:flex-col bg-white  items-center content-center justify-center">
      <Hero
        name="FORMAZIONE & EVENTI"
        srcImage={srcImage}
        srcImageMobile={srcImageMobile}
        desc="Qui puoi scoprire tutti i nostri eventi e corsi di formazione"
      />

      <div className="w-full m-auto flex justify-center lg:flex-nowrap my-10 md:mb-0">
        <div className="w-full lg:w-4/5 flex flex-col lg:flex-row lg:items-center shadow mx-10  p-5 rounded-lg bg-white  space-y-4">
          <div className="w-full h-full flex flex-col px-1 space-y-4 lg:flex-row items-center lg:space-x-6 lg:space-y-0 ">
            <p className="font-medium  text-zinc-800">Filtra</p>
            <select
              name="search"
              value={search}
              onChange={handleSearch}
              className="lg:w-2/3 pl-4 h-[60px] text-zinc-800 md:h-full w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
            >
              <option name="search" value="coming">
                In arrivo
              </option>
              <option name="search" value="past">
                Eventi passati
              </option>
            </select>
            <select
              name="city"
              value={city}
              onChange={handleCity}
              className="lg:w-2/3 pl-4 h-[60px] text-zinc-800 md:h-full w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"
            >
              <option
                className="text-zinc-800  pl-4 "
                name="search"
                value="select"
              >
                Tutti
              </option>
              {eventi.map((item, idx) => (
                <option
                  key={idx}
                  className="text-zinc-800  pl-4 "
                  name="search"
                  value={item.luogo}
                >
                  {item.luogo}
                </option>
              ))}
            </select>
            <input
              name="text"
              type="text"
              value={searchedEvents}
              onChange={changeSearch}
              placeholder="Cosa cerchi?"
              className="input shadow lg:w-full h-[60px] text-zinc-800 dark:border-none bg-zinc-200 md:h-full input-bordered hover:border-assoCol hover:shadow active:border-2 w-full lg:mx-4 "
            />
            <button
              onClick={refreshPage}
              className="px-2 w-full lg:w-1/3 h-[60px] md:h-full bg-gray-100 hover:bg-gray-200 text-gray-800 text-sm font-medium rounded-md"
            >
              Reset Filtro
            </button>
            <button
              onClick={submitSearch}
              className="btn bg-assoCol hover:bg-zinc-200 hover:text-assoCol border-none w-full lg:w-1/5"
              type="submit"
            >
              Cerca
            </button>
          </div>
        </div>
      </div>
      <div className="map">
        <div>
          <div className="flex-col mt-12 flex w-screen border align-center items-center justify-content-center ">
            {loading && <Spinner color="red.500" size="xl" />}
            <div>
              {newEventi.length !== 0 ? (
                newEventi.map(
                  ({
                    id,
                    nome,
                    date,
                    image,
                    luogo,
                    descrizione,
                    linkEvento,
                    maxGuest,
                    partecipanti,
                    totPartecipanti,
                    email,
                  }) => (
                    <CardEventi
                      user={user ? user : ""}
                      key={id}
                      id={id}
                      nome={nome}
                      image={image}
                      luogo={luogo}
                      date={date}
                      descrizione={descrizione}
                      linkEvento={linkEvento}
                      maxGuest={maxGuest}
                      partecipanti={partecipanti}
                      totPartecipanti={totPartecipanti}
                      organizzatore={email}
                    />
                  )
                )
              ) : (
                <>
                  {open && (
                    <div className="flex flex-col  items-center justify-center">
                      <p className="text-2xl text-assoCol py-4">
                        Nessun evento trovato
                      </p>
                    </div>
                  )}
                </>
              )}
              <Box className="flex w-full flex-col md:flex-row items-center p-3 pt-8 justify-between">
                {eventi.length > 0 && (
                  <p className=" text-[#ccc]"> TUTTI GLI EVENTI</p>
                )}
                {!user && (
                  <Link
                    to={{
                      pathname: "/login",
                      state: {
                        fromDashboard: true,
                      },
                    }}
                  >
                    <p className="pr-2 text-assoCol underline">
                      Accedi per partecipare
                    </p>
                  </Link>
                )}
              </Box>

              {eventi.map(
                ({
                  id,
                  nome,
                  date,
                  image,
                  luogo,
                  descrizione,
                  linkEvento,
                  maxGuest,
                  partecipanti,
                  totPartecipanti,
                  email,
                }) => (
                  <CardEventi
                    user={user ? user : ""}
                    key={id}
                    id={id}
                    nome={nome}
                    image={image}
                    luogo={luogo}
                    date={date}
                    descrizione={descrizione}
                    linkEvento={linkEvento}
                    maxGuest={maxGuest}
                    partecipanti={partecipanti}
                    totPartecipanti={totPartecipanti}
                    organizzatore={email}
                  />
                )
              )}
            </div>
          </div>
          <div className="google-map border-b-2 border-assoCol">
            <iframe
              title="map"
              src="https://www.google.com/maps/d/embed?mid=1R__wpuVATurFvJ6CRGBsD4_bae1ZUvQ&ehbc=2E312F"
              width="100%"
              height="480"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Eventi;
