import React, { useEffect, useState } from "react";
import { arrayUnion, doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { database } from "../../Utils/firebase_config";
import { Input } from "../UI/Input/InputEdit";

const FormImg = ({ rubrica, filiale }) => {
    const [id, setId] = useState("");
    const [img, setImg] = useState("");

    const handleImg = (e) => {
        setImg([e.target.value]);
    };

    useEffect(() => {
        rubrica.map(
            (list) => list.filiale === filiale.filiale && setId(list.id)
        );
    }, [filiale, rubrica]);

    const addToDb = async (e) => {
        e.preventDefault();
        if (!img) {
            toast.error("Inserisci il link dell'immagine");
            return;
        }
        if (!filiale || filiale === "Scegli Filiale") {
            toast.error("Scegli filiale");
            return;
        }

        setImg("");
        const ref = doc(database, "filiali", `${id}`);

        try {
            await updateDoc(ref, { imgs: arrayUnion(`${img}`) });

            toast.success("Foto Aggiunta");
        } catch (err) {
            toast.error("Qualcosa è andato storto", err);
        }
    };
    return (
        <div className="w-full flex flex-col justify-center items-end mt-5">
            <Input
                title="Aggiungi Link dell' immagine *"
                type="text"
                name="img"
                value={img}
                handler={handleImg}
            />

            <button
                onClick={addToDb}
                className="w-full btn glass bg-assoCol hover:bg-assoCol dark:text-white font-bold"
            >
                Salva
            </button>
        </div>
    );
};

export default FormImg;
