import React from "react";

const Raggiungici = ({ map }) => {
  return (
    <div className="w-full h-full flex ">
      <label
        htmlFor="my-modal-4"
        className="btn w-full bg-assoCol glass text-[12px] md:text-lg  rounded-lg
          hover:bg-assoCol"
      >
        Raggiungici
      </label>

      <input type="checkbox" id="my-modal-4" className="modal-toggle" />
      <label htmlFor="my-modal-4" className="modal cursor-pointer">
        <label
          className="modal-box max-w-full w-11/12 h-[50vh] relative"
          htmlFor=""
        >
          <div className="fixed inset-0 w-full h-full z-50 overflow-scroll text-zinc-800 flex bg-gray-700 bg-opafiliale-90  ">
            <iframe title="map" src={map} className="relative w-full"></iframe>
          </div>
        </label>
      </label>
    </div>
  );
};

export default Raggiungici;
