import React, { Fragment } from "react";

const Tvcc = ({ tvcc, handleChange }) => {
  return (
    <div className="w-full bg-assoBg text-zinc-800 pb-8">
      <div className="divider bg-zinc-200 h-1 "></div>
      <div className="form-control w-full flex ">
        <div className="w-full mx-4">
          <p className="text-xl text-black">Richiesta Offerta TVCC</p>
        </div>
        <div className="divider bg-zinc-200 h-1 "></div>
        <div className="w-full flex flex-col justify-center items-center px-4 lg:flex-row">
          <div className="w-full lg:w-1/2 flex flex-col">
            <label className="label capitalize text-lg">
              marchio preferito?
            </label>
            <select
              name="marchio"
              onChange={handleChange}
              className="select border-assoCol w-full max-w-sm dark:bg-white"
            >
              {marchio.map((marchio, id) => (
                <option
                  key={id}
                  className="bg-assoBg selection:bg-assoCol"
                  value={marchio}
                >
                  {marchio}
                </option>
              ))}
            </select>
            {tvcc.marchio === "Altro" &&
              tvcc.marchio !== "scegli" &&
              tvcc.marchio !== "Hikvision" &&
              tvcc.marchio !== "Dahua" &&
              tvcc.marchio !== "Bos (Borinato)" && (
                <Fragment>
                  <label className="label capitalize text-lg mt-2">
                    Altro Scrivi qui:
                  </label>
                  <input
                    type="text"
                    name="marchio"
                    placeholder={tvcc.marchio}
                    onChange={handleChange}
                    className="block w-1/2 px-4 py-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                  />
                </Fragment>
              )}
          </div>
          <div className="w-full h-full text-zinc-800 flex flex-col justify-between lg:w-1/2">
            <label className="label capitalize text-lg pt-8 lg:pt-0">
              Tipo di impianto
            </label>
            <div className="lg:w-2/3">
              <div className="form-control">
                {impianto.map((imp, i) => (
                  <label className="cursor-pointer" key={i}>
                    <input
                      type="radio"
                      name="impianto"
                      value={imp}
                      onChange={handleChange}
                      className="w-4 h-4 ring-opacity-0"
                    />
                    <span className="text-zinc-800 ml-4">{imp}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        {tvcc.impianto.length > 0 && (
          <div>
            <div className="form-control w-full px-4 mt-10">
              <p className="text-xl text-black">Risoluzione Impianto</p>
              <div className="w-full flex flex-col lg:flex-row lg:flex-wrap lg:items-center lg:px-4">
                {ris.map((item, index) => (
                  <div className=" mt-3 lg:w-1/2" key={index}>
                    <div className="form-control">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          name="risoluzione_impianto"
                          value={item}
                          onChange={handleChange}
                          className="w-4 h-4 ring-opacity-0"
                        />
                        <span className="text-zinc-800 ml-4">{item}</span>
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {tvcc.risoluzione_impianto &&
          tvcc.impianto === "Analogico (AHD-TVI-CVI)" && (
            <div className="form-control w-full px-4 mt-10">
              <div className="w-full lg:w-1/2">
                <p className="text-xl text-black mb-4">
                  Tipo di Videoregistratore Ibrido
                </p>

                <select
                  name="videoregistratore"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                >
                  {anal.map((item, idx) => (
                    <option
                      key={idx}
                      value={item}
                      className="bg-assoBg selection:bg-assoCol"
                    >
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        {tvcc.risoluzione_impianto && tvcc.impianto === "IP" && (
          <div className="form-control w-full px-4 mt-10">
            <div className="w-full lg:w-1/2">
              <p className="text-xl text-black mb-4">
                Tipo di Videoregistratore IP
              </p>

              <select
                name="videoregistratore"
                onChange={handleChange}
                className="select border-assoCol w-full max-w-sm dark:bg-white"
              >
                {ip.map((ip, idx) => (
                  <option
                    key={idx}
                    value={ip}
                    className="bg-assoBg selection:bg-assoCol"
                  >
                    {ip}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {tvcc.risoluzione_impianto.length > 0 && (
          <Fragment>
            <div className="w-full px-4 py-8 ">
              <p className="text-xl text-black pb-4">
                Quanti Ingressi deve supportare il tuo Videoregistratore?
                <span className="text-assoCol">*</span>
              </p>
              <div className="w-full flex flex-col space-y-2 lg:flex-row lg:flex-wrap lg:items-center lg:px-4 ">
                {ingressi_video.map((ingresso, index) => (
                  <label
                    className="cursor-pointer w-1/2 flex items-center"
                    key={index}
                  >
                    <div className="w-full">
                      <input
                        type="radio"
                        name="ingressi_video"
                        value={ingresso}
                        onChange={handleChange}
                        className="w-4 h-4 ring-opacity-0"
                      />
                      <span className="text-zinc-800 ml-4">{ingresso}</span>
                    </div>

                    {ingresso === "Altro" &&
                      tvcc.ingressi_video === "Altro" && (
                        <input
                          placeholder="Altro"
                          type="text"
                          name="ingressi_altro"
                          value={tvcc.ingressi_altro}
                          onChange={handleChange}
                          className="input w-full max-w-sm border-assoCol"
                        />
                      )}
                  </label>
                ))}
              </div>
            </div>
            <div className="w-full px-4 py-8 ">
              <p className="text-xl text-black pb-4">
                HDD (Hard Disk)
                <span className="text-assoCol"> *</span>
              </p>
              <div className="w-full flex flex-col space-y-2 lg:flex-row lg:flex-wrap lg:items-center lg:px-4 ">
                {hdd.map((tb, index) => (
                  <label
                    className="cursor-pointer w-1/2 flex items-center my-2"
                    key={index}
                  >
                    <input
                      type="radio"
                      name="hdd"
                      value={tb}
                      onChange={handleChange}
                      className="w-4 h-4 ring-opacity-0"
                    />
                    <span className="text-zinc-800 ml-4">{tb}</span>
                  </label>
                ))}
              </div>
            </div>
            <div className="w-full flex flex-col space-y-2 lg:flex-row lg:flex-wrap px-4 lg:space-y-0">
              <div className="w-full flex flex-col flex-nowrap lg:w-1/2 ">
                <label className="text-zinc-700 font-semibold mb-4">
                  Numero di telecamere tipo Bullet Ottica Fissa
                </label>
                <select
                  name="bullet_ottica_fissa"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                  value={tvcc.bullet_ottica_fissa}
                >
                  {count.map((list, i) => (
                    <option
                      key={i}
                      value={list}
                      className="bg-assoBg selection:bg-assoCol"
                    >
                      {list}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full flex flex-col flex-nowrap lg:w-1/2">
                <label className="text-zinc-700 font-semibold mb-4">
                  Numero di telecamere tipo Bullet Varifocal
                </label>
                <select
                  name="bullet_varifocal"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                  value={tvcc.bullet_varifocal}
                >
                  {count.map((li, i) => (
                    <option
                      key={i}
                      value={li}
                      className=" selection:bg-assoCol"
                    >
                      {li}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="w-full flex flex-col space-y-2 lg:flex-row lg:flex-wrap px-4">
              <div className="w-full flex flex-col flex-nowrap lg:w-1/2">
                <label className="text-zinc-700 font-semibold py-4">
                  Numero di telecamere tipo Dome Ottica Fissa
                </label>
                <select
                  name="dome_ottica_fissa"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                  value={tvcc.dome_ottica_fissa}
                >
                  {count.map((dom, i) => (
                    <option
                      key={i}
                      value={dom}
                      className="bg-assoBg selection:bg-assoCol"
                    >
                      {dom}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-full flex flex-col flex-nowrap lg:w-1/2 ">
                <label className="text-zinc-700 font-semibold mb-4">
                  Numero di telecamere tipo Dome Varifocal
                </label>
                <select
                  name="dome_varifocal"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                  value={tvcc.dome_varifocal}
                >
                  {count.map((dome, i) => (
                    <option
                      key={i}
                      value={dome}
                      className=" selection:bg-assoCol"
                    >
                      {dome}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {tvcc.impianto === "Analogico (AHD-TVI-CVI)" && (
              <div className="w-full flex flex-col p-4 mt-10 lg:flex-row lg:space-x-0">
                <div className="flex flex-col space-y-4 lg:w-1/2 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0">
                  <div className="lg:w-1/4">
                    <p className="text-xl text-black">Alimentatore</p>
                    <label>
                      <input
                        name="alimentatore"
                        type="text"
                        placeholder={tvcc.alimentatore}
                        className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className="lg:w-1/4">
                    <p className="text-xl text-black">Balun</p>
                    <label>
                      <input
                        name="balun"
                        type="text"
                        placeholder={tvcc.balun}
                        onChange={handleChange}
                        className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                      />
                    </label>
                  </div>
                </div>
                {tvcc.risoluzione_impianto.length > 0 && (
                  <div className="flex flex-col space-y-4 pt-8 lg:w-1/2 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0 lg:pt-0">
                    <div className=" lg:w-1/4">
                      <p className="text-xl text-black">Junction Box</p>
                      <label>
                        <input
                          name="junction_box"
                          type="text"
                          onChange={handleChange}
                          placeholder={tvcc.junction_box}
                          className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        />
                      </label>
                    </div>
                    <div className="lg:w-1/4">
                      <p className="text-xl text-black">Attacco Palo</p>
                      <label>
                        <input
                          name="attacco_palo"
                          type="text"
                          onChange={handleChange}
                          placeholder={tvcc.attacco_palo}
                          className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tvcc.impianto === "IP" && tvcc.risoluzione_impianto.length > 0 && (
              <div className="flex flex-col space-y-4 mt-8 pl-4 lg:w-1/2 lg:flex-row lg:items-center lg:space-x-10 lg:space-y-0 lg:pt-0">
                <div className="lg:w-1/4">
                  <p className="text-xl text-black">Junction Box</p>
                  <label>
                    <input
                      name="junction_box"
                      type="text"
                      onChange={handleChange}
                      placeholder={tvcc.junction_box}
                      className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                    />
                  </label>
                </div>
                <div className="lg:w-1/4">
                  <p className="text-xl text-black">Attacco Palo</p>
                  <label>
                    <input
                      name="attacco_palo"
                      type="text"
                      onChange={handleChange}
                      placeholder={tvcc.attacco_palo}
                      className="block w-3/4 lg:w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                    />
                  </label>
                </div>
              </div>
            )}

            <div className="form-control w-full space-y-4 px-4 mt-10 lg:flex-row lg:space-y-0">
              <div className="lg:w-1/2">
                <p className="text-xl text-black mb-4">Tipo di Cavo</p>

                <select
                  name="cavo"
                  onChange={handleChange}
                  className="select border-assoCol w-full max-w-sm dark:bg-white"
                >
                  {cavi.map((cavo, i) => (
                    <option
                      key={i}
                      className="bg-assoBg selection:bg-assoCol"
                      value={cavo}
                    >
                      {cavo}
                    </option>
                  ))}
                </select>
                {tvcc.cavo !== "Scegli" && tvcc.cavo !== "" && (
                  <Fragment>
                    <p className="text-black font-bold mt-4 mb-1">
                      Matasse di cavo
                    </p>
                    <input
                      name="num_cavi"
                      type="text"
                      placeholder={tvcc.num_cavi}
                      className="block w-3/4 lg:w-1/4 px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                      onChange={handleChange}
                    />
                  </Fragment>
                )}
              </div>
              {tvcc.risoluzione_impianto.length > 0 && (
                <div className="w-1/2 pt-4 lg:pt-0">
                  <p className="text-xl text-bold">Gruppo di continuità</p>
                  <div className="w-2/3 h-full flex pt-6 space-x-12">
                    <div className="form-control ">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          name="continuita"
                          value="si"
                          onChange={handleChange}
                          className="w-4 h-4 ring-opacity-0"
                        />
                        <span className="text-zinc-800 ml-4">Si</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          name="continuita"
                          value="no"
                          onChange={handleChange}
                          className="w-4 h-4"
                        />
                        <span className="text-zinc-800 ml-4">No</span>
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="form-control w-full lg:flex-row px-4 mt-10">
              <div className="h-full text-zinc-800 flex flex-col justify-between lg:w-1/2">
                <p className="text-xl text-bold mb-5 mr-20">
                  {tvcc.impianto === "IP"
                    ? "Connettori RJ45"
                    : "Connettori a pressione per cavo COAX"}
                </p>
                <div className="w-2/3 h-full flex items-center space-x-12">
                  <div className="form-control ">
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="connettori"
                        value="si"
                        onChange={handleChange}
                        className="w-4 h-4 ring-opacity-0"
                      />
                      <span className="text-zinc-800 ml-4">Si</span>
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="connettori_coax"
                        value="no"
                        onChange={handleChange}
                        className="w-4 h-4"
                      />
                      <span className="text-zinc-800 ml-4">No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="h-full text-zinc-800 flex flex-col justify-between pt-6 lg:pt-0 lg:w-1/2">
                <p className="text-xl text-bold mb-5 mr-20">
                  Connettori Alimentazione Maschio
                </p>
                <div className="w-2/3 h-full flex items-center space-x-12">
                  <div className="form-control ">
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="connettori_maschio"
                        value="si"
                        onChange={handleChange}
                        className="w-4 h-4 ring-opacity-0"
                      />
                      <span className="text-zinc-800 ml-4">Si</span>
                    </label>
                  </div>
                  <div className="form-control">
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        name="connettori_maschio"
                        value="no"
                        onChange={handleChange}
                        className="w-4 h-4"
                      />
                      <span className="text-zinc-800 ml-4">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {tvcc.risoluzione_impianto.length > 0 && (
              <div className="w-full flex flex-col px-4 mt-10 space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
                <div className="form-control lg:w-1/2 lg:flex-row">
                  <div className="w-full">
                    <p className="text-xl text-black mb-4">Armadio Rack</p>

                    <select
                      name="armadio_rack"
                      onChange={handleChange}
                      className="select border-assoCol w-full max-w-sm dark:bg-white"
                    >
                      {unità.map((u, i) => (
                        <option
                          key={i}
                          className="bg-assoBg selection:bg-assoCol"
                          value={u}
                        >
                          {u}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="lg:w-1/2 mt-10">
                  <p className="text-xl text-bold pb-4 lg:pb-0">Switch</p>
                  <div className="w-full h-full flex items-center space-x-12">
                    <div className="form-control ">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          name="switch"
                          value="si"
                          onChange={handleChange}
                          className="w-4 h-4 ring-opacity-0"
                        />
                        <span className="text-zinc-800 ml-4">Si</span>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="cursor-pointer">
                        <input
                          type="radio"
                          name="switch"
                          value="no"
                          onChange={handleChange}
                          className="w-4 h-4"
                        />
                        <span className="text-zinc-800 ml-4">No</span>
                      </label>
                    </div>
                    {tvcc.switch === "si" && (
                      <div className="w-2/3 h-full flex flex-col">
                        <textarea
                          name="switch_text"
                          className="textarea border-assoCol"
                          placeholder="Scrivi che tipo di Switch ti
                                                servono e quanti te ne servono"
                          onChange={handleChange}
                          Switch
                        ></textarea>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {tvcc.armadio_rack.length > 0 && (
              <Fragment>
                <div className="w-full flex flex-col px-4 mt-10 space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
                  <div className="lg:w-1/2">
                    <p className="text-xl text-black mb-2">
                      Pannello Patch (Patch Panel)
                    </p>

                    <select
                      name="pannello_patch"
                      onChange={handleChange}
                      className="select border-assoCol w-full max-w-sm dark:bg-white"
                    >
                      {patch.map((p, i) => (
                        <option
                          key={i}
                          className="bg-assoBg selection:bg-assoCol"
                          value={p}
                        >
                          {p}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="flex flex-col space-y-4 w-1/2 lg:flex-row lg:space-x-12 lg:space-y-0">
                    <div className="max-w-sm lg:w-1/4">
                      <p className="text-xl text-black">Mensole</p>
                      <label>
                        <input
                          name="mensole"
                          type="text"
                          onChange={handleChange}
                          placeholder={tvcc.mensole}
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        />
                      </label>
                    </div>

                    <div className="max-w-sm lg:w-1/4">
                      <p className="text-xl text-black">Presiera</p>
                      <label>
                        <input
                          name="presiera"
                          type="text"
                          onChange={handleChange}
                          placeholder={tvcc.presiera}
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        />
                      </label>
                    </div>
                    <div className="max-w-sm lg:w-1/4">
                      <p className="text-xl text-black">Guidacavi</p>
                      <label>
                        <input
                          name="guidacavi"
                          type="text"
                          onChange={handleChange}
                          placeholder={tvcc.guidacavi}
                          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-assoCol rounded-md focus:outline-none focus:ring"
                        />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col px-4 mt-10 space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
                  <div className="lg:w-1/2">
                    <p className="text-xl text-bold pb-2">Ventole</p>
                    <div className="w-full h-full flex items-center space-x-12">
                      <div className="form-control ">
                        <label className="cursor-pointer">
                          <input
                            type="radio"
                            name="ventole"
                            value="si"
                            onChange={handleChange}
                            className="w-4 h-4 ring-opacity-0"
                          />
                          <span className="text-zinc-800 ml-4">Si</span>
                        </label>
                      </div>
                      <div className="form-control">
                        <label className="cursor-pointer">
                          <input
                            type="radio"
                            name="ventole"
                            value="no"
                            onChange={handleChange}
                            className="w-4 h-4"
                          />
                          <span className="text-zinc-800 ml-4">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="pt-4 lg:pt-0 lg:w-1/2">
                    <p className="text-xl text-bold pb-2">Kit Dadi e Bulloni</p>
                    <div className="w-full h-full flex items-center space-x-12">
                      <div className="form-control ">
                        <label className="cursor-pointer">
                          <input
                            type="radio"
                            name="kit"
                            value="si"
                            onChange={handleChange}
                            className="w-4 h-4 ring-opacity-0"
                          />
                          <span className="text-zinc-800 ml-4">Si</span>
                        </label>
                      </div>
                      <div className="form-control">
                        <label className="cursor-pointer">
                          <input
                            type="radio"
                            name="kit"
                            value="no"
                            onChange={handleChange}
                            className="w-4 h-4"
                          />
                          <span className="text-zinc-800 ml-4">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="divider bg-zinc-200 h-1 my-16 "></div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Tvcc;

const impianto = ["Analogico (AHD-TVI-CVI)", "IP"];
const anal = ["Scegli", "XVR standard", "XVR POC (alimentazione su coassiale)"];
const ip = ["Scegli", " NVR standard", " NVR POE (porte PoE integrate)"];

const marchio = ["scegli", "Hikvision", "Dahua", "Bos (Borinato)", "Altro"];
const ris = ["2 Megapixel", "4 Megapixel", "5 Megapixel", "8 Megapixel (4K)"];

const ingressi_video = [
    "4 Canali",
    "8 Canali",
    "16 Canali",
    "32 Canali",
    "64 Canali",
    "Altro",
];

const hdd = ["1 Tb", "2 Tb", "4 TB", "6 Tb"];
const count = [
    "seleziona",
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
];
const cavi = [
    "Scegli",
    "Cat5 Rame (305Mt)",
    " UPT Cat5 Rame + Gel (305Mt)",
    " UPT Cat6 Rame (305Mt)",
    " Mini Coax (100Mt)",
    "Mini Coax + Alimentazione(100Mt)",
    "Mini Coax (200Mt)",
    " Mini Coax + Alimentazione(200Mt)",
];

const unità = [
    "seleziona",
    "6 Unità",
    "9 Unità",
    "12 Unità",
    "15 Unità",
    "18 Unità",
    "24 Unità",
];

const patch = ["seleziona", "24 porte Cat5", "24 porte Cat6"];
